.section-shop-01 {
    .image {
        display: inline-block;
        position: relative;

        .overlay {
            opacity: 0;
            text-align: center;
            .overlay(fade(@color-gray - #222222, 50));

            a.zoom {
                background-color: @color-common;
                border-radius: 50%;
                display: table;
                margin: 0 auto;
                .square(44px);
            }

            // cursor: pointer;
            h5 {
                display: none;
            }

            i {
                display: table-cell;
                font-size: 15px;
                vertical-align: middle;
                .color-light;

                &:before {
                    vertical-align: middle;
                }
            }
        }

        img {
            width: 100%;
        }
    }

    .info {
        padding-top: 25px;

        .price {
            font-weight: 600;

            // span {
            //     .color-main;
            // }
            padding-top: 20px;

            del {
                margin-right: 15px;
            }
        }

        a {
            float: right;
            line-height: 20px;
            position: relative;

            &:before {
                content: "\e064";
                display: block;
                font-size: 13px;
                position: absolute;
                right: 100%;
                .color-main;
                .icon-font-type-1();
            }

            i {
                font-size: 20px;
            }
        }

        p {
            color: @color-gray - #222;
            display: inline-block;
            font-weight: 600;
            margin: 0;
        }
    }

    .shop-product-01 {
        max-width: 370px;
        padding-bottom: 75px;

        &:hover {
            .info i {
                .color-main;
            }

            .overlay {
                opacity: 1;
            }
        }
    }

    .shop-product-wrapper:last-of-type {
        .image {
            .overlay {
                opacity: 1;
                .overlay(@bgc-gray);

                .title {
                    display: table;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;

                    h5 {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }

                .zoom {
                    display: none;
                }

                h5 {
                    display: block;

                    &:after {
                        content: "\e278";
                        display: block;
                        font-size: 20px;
                        .color-gray;
                        .icon-font-type-1();
                    }
                }
            }
        }

        .info {
            display: none;
        }
    }

    .shop-product-wrapper:nth-of-type(3n+1) {
        span {
            .color-main;
        }
    }
}

.product-listing-2 {
    float: left;

    &.caro {
        .shop-product-2-wrapper {
            &:nth-of-type(2n+1) {
                .shop-product-2 {
                    .image {
                        .bgc-light;
                    }
                    
                    .info {
                        .bgc-gray;
                    }
                }
            }
        }
    }
}

.shop-product-2 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;

    &:hover {

        .overlay {
            opacity: 1;
        }
    }


    .image {
        background-color: #EBEBEB;
        display: inline-block;
        width: 100%;
        position: relative;
    
        img {
            width: 100%;
        }
    
        .overlay {
            opacity: 0;
            text-align: center;
            .overlay(fade(@color-light, 50));
        }

        ul {
            .clearfix();
            display: block;
            position: absolute;
            height: 44px;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            width: 100%;
            
            li {
                display: inline-block;

                > a {
                    .color-light;
                    width: 44px;
                    height: 44px;
                    line-height: 44px;
                    display: inline-block;
                    position: relative;
                    border-radius: 50%;
                    margin-left: 8px;
                    margin-right: 8px;

                    i {

                        position: absolute;
                        .pos(0;0;0;0);
                        margin: auto;
                        display: block;
                        line-height: 1;
                    }
                }
            }
        }

        a.add-to-cart {
            .bgc-main;
            transform: translateY(-30px);
            
            i {
                width: 24px;
                height: 24px;
                font-size: 24px;
            }

            &:hover {
                .color-light;
                .bgc-dark;
            }
        }

        a.quick-view {
            .bgc-dark;
            transform: translateY(30px);

            i {
                width: 16px;
                height: 16px;
                font-size: 16px;
            }

            &:hover {
                .bgc-main;
            }
        }

        &:hover {
            .overlay {
                opacity: 1;
            }

            a.add-to-cart {
                transform: translateY(0);
            }

            a.quick-view {
                transform: translateY(0);
            }
        }
    }

    .info {
        padding: 30px 0;

        > a {
            color: @color-gray - #222;
            display: inline-block;
            margin: 0;

            &:hover {
                .color-main;
            }
        }
        
        .price {
            line-height: 1.6;

            del, span {
                .font-serif;
                font-size: 16px;
            }

            del {
                margin-right: 20px;
                .color-gray;
            }

            span {
                .color-main;
                .font-serif;
            }
        }

        .star-ratings[data-rating="0"] {
            visibility: hidden;
        }

        .star-ratings {
            span {
                font-size: 18px;
            }
        }

    }

    .saleoff {
        font-weight: 600;
        margin: 20px 15px 0 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 514;
        .color-main;
    }
}