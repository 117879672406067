// Shop Gallery Carousel (Multiple Items - Slider)
// ======

.shop-gallery-carousel {
    margin-bottom: 75px;
    
    .shop-item {
        .item-image {
            .layout-after {
                display: inline-block;

                
                &:after {
                    opacity: 0;
                }
                
                &:hover:after {
                    opacity: 1;
                }
            }
            
            .name {
                margin-top: 5px;
                
                a {
                    display: inline-block;
                    max-width: 170px;
                    
                    &:hover {
                        .color-main;
                    }
                }
            }
            
            img {
                margin-left: auto;
                // width: 100%;
                margin-right: auto;
            }
        }
    }
    
    .slick-next {
        right: -30px;
        
        &:before {
            content: "\e07e";
        }
    }
    
    .slick-prev {
        left: -30px;
        
        &:before {
            content: "\e031";
        }
    }
    
    .slick-prev,
    .slick-next {
        height: 25px;
        top: 85px;
        width: 25px;
        
        &:before {
            font-size: 25px;
            .color-gray;
            .icon-font-type-1();
        }
        
        &:hover:before {
            .color-dark;
        }
    }
    
    @media (max-width: 1300px) {
        .slick-next {
            right: 50%;
            transform: translateX(36px);
            
            &:before {
                content: "\e07f";
            }
        }
        
        .slick-prev {
            left: 50%;
            transform: translateX(-36px);
            
            &:before {
                content: "\e032";
            }
        }
        
        .slick-prev,
        .slick-next {
            bottom: -35px;
            margin-top: 0;
            top: auto;
        }
    }
}