.shop-detail-orther-link {
    text-align: center;

    br {
        display: none;
    }

    a.border-btn, a.normal-btn {
        width: 100%;
        max-width: 270px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    a.border-btn {
         margin-right: 30px;
    }

    @media @xs-max {
        br {
            display: initial;
        }

        a.border-btn {
            margin-right: 0px;
            margin-bottom: 30px;
        }
    }
}

.shop-detail-1-page {
    .product-image-slider-wrapper {
        padding-top: 85px;
    }

    .product-detail-1 {
        padding-top: 85px;
    }

    .shop-detail-orther-link {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .shop-detail-relate {
        padding-top: 40px;

        h5 {
            margin-bottom: 55px;
        }
    }
}