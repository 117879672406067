// One Page
// =========
body.onepage-page {
    .main-nav-wrapper.nav-wrapper-1-onepage {
        position: static;
        height: 0;

        nav {
            position: absolute;
        }
    }

    @media @sm-max {
        header.site-header {
            padding-top: 60px;
            height: 100vh;
            .home-fw-slider.style-1 {
                height: 100%;
            }
        }
    }

    @media (max-width: 500px) {
        header.site-header {
            max-height: 650px;
        }
    }
}

// Corporate
// =========
body.corporate-page {
    .main-nav-wrapper.nav-wrapper-1-1 {
        position: static;
        height: 0;

        nav {
            position: absolute;
        }
    }

    header.site-header {
        padding-top: 120px;
        height: 100vh;
        max-height: 900px;
        .home-fw-slider.style-3 {
            height: 100%;
        }
    }

    @media @sm-max {
        header.site-header {
            padding-top: 60px;
        }
    }

    @media (max-width: 500px) {
        header.site-header {
            max-height: 650px;
        }
    }
}

// Left Menu
// =========
body.left-menu {
    .main-nav-wrapper.nav-wrapper-1-3 {
        position: static;
        height: 0;

        nav {
            position: absolute;
        }
    }

    header.site-header {
        height: 100vh;
        .home-fw-slider.style-4 {
            height: 100%;
        }
    }
    
    @media (max-width: 1299px) {
        header.site-header {
            padding-top: 90px;
        }
    }

    @media @sm-max {
        header.site-header {
            padding-top: 60px;
        }
    }
    
    @media (max-width: 500px) {
        header.site-header {
            max-height: 650px;
        }
    }
}