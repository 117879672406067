@section-padding-top:75px;
@section-padding-bottom:75px;
@section-header-margin-bottom:60px;
@section-block-spacing:40px;

@p-mb: 25px;
@addition-to-large: 30px;

main{
    .clearfix();
}

section {
    position: relative;
    padding-top:@section-padding-top;
    padding-bottom : @section-padding-bottom - @section-block-spacing;
    .clearfix();

    .section-header{
        .clearfix;
        margin-bottom : @section-header-margin-bottom - @p-mb;
    }

    .section-block{
        .clearfix;
        margin-bottom : @section-block-spacing;
    }

    .section-block-p{
        .clearfix;
        margin-bottom :@section-block-spacing - @p-mb;
    }

    &.one-child{
        padding-bottom : @section-padding-bottom;
    }

    &.no-padding {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &.min-padding{
        padding-top : 50px!important;
        padding-bottom : 0!important;
    }

    &.no-padding-bottom{
        padding-bottom : 0!important;
        .section-block:last-of-type,.section-block-p:last-of-type{
            margin-bottom : 0!important;
        }
    }

    &.no-padding-top{
        padding-top : 0!important;
    }

    &.large-space{
        padding-top:@section-padding-top + @addition-to-large;
        padding-bottom : @section-padding-bottom - @section-block-spacing + @addition-to-large;
        .section-header{
            margin-bottom : @section-padding-top + @addition-to-large - @p-mb;
        }
        &.one-child{
            padding-bottom : @section-padding-bottom + @addition-to-large;
        }
    }
}

.header-type-1 {
    text-align: center;
    
    &.style-1 {
        h2 {
            margin-top: 0;
        }

        h5 {
            margin-bottom: 12px;
        }
    }
    
    &.style-2 {
        text-align: left;
        
        .header-caption {
            margin-top: 0;
        }
        
        h2 {
            margin-bottom: 0;
        }
        
        h5 {
            margin-bottom: 12px;
        }
    }
    
    &.style-3 {
        
        .header-caption {
            margin-top: 0;
        }
        
        .heading-icon-2 {
            .heading-icon-2("\e2d1";68px);
        }
        
        h2 {
            margin-bottom: 0;
            margin-top: 0;
        }
        
        h5 {
            margin-bottom: 12px;
        }
    }
    
    .header-caption {
        .font-serif;
        .fz-common-3;
    }
    
    h5 {
        color: @color-gray - #222;
        font-weight: 600;
        .font-type-3();
    }
}

.header-type-2 {
    .header-caption {
        display : block;
        .font-serif;
        font-size: @fz-common-3;
        margin-bottom : 45px;
    }

    &.style-1 .header-caption{
        max-width: 800px;
    }
    
    &.style-2 .header-caption{
        font-size : @fz-common-3 + 2px;
        color: lighten(@color-common, 15%);
    }
}
