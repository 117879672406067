.slip-section {
    .slip-wrapper {
        border-top: 1px solid @color-lightgray;
        padding: 20px;
        height: 100vh;
    }

    .slip-bg-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
    }

    .slip-full-height {
        height: 100%;
    }

    @media @md {
        .slip-wrapper {
            min-height: 760px;
        }
    }

    @media @sm-max {
        .slip-wrapper {
            &.slip-height-xs {
                height: auto;
            }
        }
    }
}

// Slip Control Nav
.slip-control-nav {
    #fp-nav ul,
    .fp-slidesNav ul {
        margin: 0;
        padding: 0;

        li {
            display: block;
            width: auto;
            height: auto;
            margin: 7px;
            line-height: 1;
            position: relative;

            a {
                display: inline-block;
                position: relative;
                z-index: 1;
                line-height: 16px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                text-align: center;
                border: 2px solid transparent;


                span {
                    .easing-animation();
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    display: inline-block;
                    z-index: 1;
                    height: 10px;
                    width: 10px;
                    line-height: 10px;
                    border: 1px solid @color-light;
                    background: transparent;
                }

                &.active {
                    border: 2px solid @color-main;

                    span {
                        width: 8px;
                        height: 8px;
                        margin: auto;
                        background: @color-main;
                        border: 1px solid transparent;
                    }
                }
            }

            &:hover {
                a {
                    border: 2px solid @color-main;

                    span {
                        width: 8px;
                        height: 8px;
                        margin: auto;
                        background: @color-main;
                        border: 1px solid transparent;
                    }
                }
            }
        }
    }

    #fp-nav {
        &.right {
            right: 47px;
        }
        
        ul {
            li {
                .fp-tooltip {
                    position: absolute;
                    top: -50%;
                    margin-top: 0px;
                    color: #fff;
                    font-size: 11px;
                    .font-type-1();
                    max-width: 220px;
                    display: inline-block;
                    opacity: 0;
                    .bgc-main;
                    padding: 10px 20px;
                    overflow: visible;
                    
                    &:after {
                        content: "";
                        border-style: solid;
                        border-color: transparent;
                        border-width: 6px 6px;
                        border-left-color: @color-main;
                        position: absolute;
                        right: -12px;
                        top: 50%;
                        margin-top: -6px;
                        display: block;
                        z-index: 6991;
                        width: 0;
                        height: 0;
                    }

                    &.right {
                        right: 40px;
                    }

                    &.left {

                    }
                }
                &:hover {
                    .fp-tooltip {
                        .easing-animation(opacity);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// Slip Home
.slip-home {
    .slip-home-item-wrapper {
        position: relative;

        .overlay {
            max-width: 470px;
            left: 0;
            right: 0;
            margin: auto;
        }

        .caption-wrapper {
            background-color: fade(@bgc-light,90%);
            padding: 52px 0;

            p {
                font-size: @fz-common-1 - 4;
                .color-dark;
                font-weight: 600;
                margin-bottom: 0;
            }

            .caption-icon {
                width: 66px;
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
    }

    @media @sm-max {
        min-height: 590px;
    }

    @media @xs-max {
        min-height: 350px;
        max-height: 770px;
    }

    @media (max-width: 560px) {
        .slip-home-item-wrapper {
            .overlay {
                width: 240px;
            }

            .caption-wrapper {
                padding: 20px 15px;

                p {
                    font-size: @fz-common-3;
                }
            }
        }
    }
}

// Slip About
.slip-about {
    .item-content-wrapper {
        height: 100%;
        background-color: @color-lightgray + #111;

        .item-content {
            margin-left: auto;
            margin-right: auto;

            h5 {
                .font-type-3();
                font-weight: 600;
                color: @color-gray - #222;
                margin-bottom: 10px;
            }

            h2 {
                margin-bottom: 45px;
            }

            p {
                .font-serif;
                .fz-common-3;
            }

            .paragraph {
                margin-bottom: 35px;
            }

            .name {
                .color-main;
                line-height: 1.6;
                margin-bottom: 0;
            }
        }
    }

    @media @md and @md-max {
        .item-content-wrapper {
            .item-content {
                width: 100%;
                padding-right: 25px;
                padding-left: 25px;

                h2 {
                    margin-bottom: 35px;
                }

                p {
                    font-size: @fz-common-3 - 1px;
                    line-height: 1.8;
                }
            }
        }
    }

    @media @lg {
        .item-content-wrapper {
            .item-content {
                width: 550px;
            }
        }
    }

    @media @sm-max {
        .item-content-wrapper {
            background: url("../images/slip-bg-3.jpg") no-repeat right top;
            background-size: cover;
            position: relative;
            z-index: 0;

            &:after {
                content: "";
                position: absolute;
                .pos(0;0);
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: fade(@color-lightgray + #111, 90%);
            }

            .item-content {
                position: relative;
                z-index: 2;
                padding: 90px 65px;
            }
        }
    }

    @media @xs-max {
        .item-content-wrapper {
            height: auto;

            .item-content {
                padding: 70px 25px;

                h5 {
                    font-size: @fz-heading-5 - 2px;
                }

                .heading-icon-1 {
                    &:before {
                        font-size: 60px;
                    }
                }

                h2 {
                    font-size: @fz-heading-2 - 9px;
                    margin-bottom: 25px;
                }

                p {
                    font-size: @fz-common-3 - 1px;
                }

                .paragraph {
                    margin-bottom: 25px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .item-content-wrapper {
            .item-content {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

// Slip Portfolio
.slip-portfolio {
    .item-content-wrapper {
        height: 100%;
        background-color: @color-lightgray + #111;

        .item-content {
            margin-left: auto;
            margin-right: auto;
            text-align: right;

            h5 {
                .font-type-3();
                font-weight: 600;
                color: @color-gray - #222;
                margin-bottom: 10px;
            }

            h2 {
                margin-bottom: 45px;
            }

            .paragraph {
                .font-serif;
                .fz-common-3;
                margin-bottom: 55px;
            }
        }
    }

    @media @md and @md-max {
        .item-content-wrapper {
            .item-content {
                width: 100%;
                padding-right: 25px;
                padding-left: 25px;

                h2 {
                    margin-bottom: 35px;
                }

                .paragraph {
                    font-size: @fz-common-3 - 1px;
                    line-height: 1.8;
                }
            }
        }
    }

    @media @lg {
        .item-content-wrapper {
            .item-content {
                width: 550px;
                margin-right: 30px;
            }
        }
    }

    @media (min-width: 1400px) {
        .item-content-wrapper {
            .item-content {
                width: 550px;
                margin-right: 115px;
            }
        }
    }

    @media @sm-max {
        .item-content-wrapper {
            background: url("../images/slip-bg-4.jpg") no-repeat right top;
            background-size: cover;
            position: relative;
            z-index: 0;

            &:after {
                content: "";
                position: absolute;
                .pos(0;0);
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: fade(@color-lightgray + #111, 90%);
            }

            .item-content {
                text-align: center;
                position: relative;
                z-index: 2;
                padding: 90px 65px;
            }
        }
    }

    @media @xs-max {
        .item-content-wrapper {
            height: auto;

            .item-content {
                padding: 60px 25px;

                h5 {
                    font-size: @fz-heading-5 - 2px;
                }

                .heading-icon-1 {
                    &:before {
                        font-size: 60px;
                    }
                }

                h2 {
                    font-size: @fz-heading-2 - 9px;
                    margin-bottom: 25px;
                }

                .paragraph {
                    font-size: @fz-common-3 - 1px;
                    margin-bottom: 25px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .item-content-wrapper {
            .item-content {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

// Slip Service
.slip-service {
    .item-content-wrapper {
        height: 100%;
        background-color: @color-lightgray + #111;

        .item-content {
            margin-left: auto;
            margin-right: auto;

            .content-header {
                h5 {
                    .font-type-3();
                    font-weight: 600;
                    color: @color-gray - #222;
                    margin-bottom: 10px;
                }

                h2 {
                    margin-bottom: 45px;
                }
            }

            .accordion-1 {
                .panel-group {
                    .panel {
                        .panel-heading {
                            border: 1px solid (@color-lightgray - #111);
                            .color-common;

                            .panel-title {
                                h5 {
                                    i {
                                        .color-dark;
                                    }

                                    &:after {
                                        color: @color-gray - #222;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media @md and @md-max {
        .item-content-wrapper {
            .item-content {
                width: 100%;
                padding-right: 25px;
                padding-left: 25px;

                .content-header {
                    h2 {
                        margin-bottom: 35px;
                    }
                }
            }
        }
    }

    @media @lg {
        .item-content-wrapper {
            .item-content {
                max-width: 550px;
                margin-left: 30px;
            }
        }
    }

    @media (min-width: 1400px) {
        .item-content-wrapper {
            .item-content {
                margin-left: 115px;
            }
        }
    }

    @media @sm-max {
        .item-content-wrapper {
            background: url("../images/slip-bg-4.jpg") no-repeat right top;
            background-size: cover;
            position: relative;
            z-index: 0;

            &:after {
                content: "";
                position: absolute;
                .pos(0;0);
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: fade(@color-lightgray + #111, 90%);
            }

            .item-content {
                position: relative;
                z-index: 2;
                padding: 90px 65px;

                .content-header {
                    text-align: center;
                }
            }
        }
    }

    @media @xs-max {
        .item-content-wrapper {
            height: auto;

            .item-content {
                padding: 60px 25px;

                .content-header {
                    h5 {
                        font-size: @fz-heading-5 - 2px;
                    }

                    .heading-icon-1 {
                        &:before {
                            font-size: 60px;
                        }
                    }

                    h2 {
                        font-size: @fz-heading-2 - 9px;
                        margin-bottom: 25px;
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        .item-content-wrapper {
            .item-content {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

// Slip Blog
.slip-blog {
    .item-content-wrapper {
        height: 100%;
        background-color: @color-lightgray + #111;

        .item-content {
            margin-left: auto;
            margin-right: auto;
            text-align: right;

            h5 {
                .font-type-3();
                font-weight: 600;
                color: @color-gray - #222;
                margin-bottom: 10px;
            }

            h2 {
                margin-bottom: 45px;
            }

            .paragraph {
                .font-serif;
                .fz-common-3;
                margin-bottom: 55px;
            }
        }
    }

    @media @md and @md-max {
        .item-content-wrapper {
            .item-content {
                width: 100%;
                padding-right: 25px;
                padding-left: 25px;

                h2 {
                    margin-bottom: 35px;
                }

                .paragraph {
                    font-size: @fz-common-3 - 1px;
                    line-height: 1.8;
                }
            }
        }
    }

    @media @lg {
        .item-content-wrapper {
            .item-content {
                width: 550px;
                margin-right: 30px;
            }
        }
    }

    @media (min-width: 1400px) {
        .item-content-wrapper {
            .item-content {
                width: 550px;
                margin-right: 115px;
            }
        }
    }

    @media @sm-max {
        .item-content-wrapper {
            background: url("../images/slip-bg-6.jpg") no-repeat right top;
            background-size: cover;
            position: relative;
            z-index: 0;

            &:after {
                content: "";
                position: absolute;
                .pos(0;0);
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: fade(@color-lightgray + #111, 90%);
            }

            .item-content {
                text-align: center;
                position: relative;
                z-index: 2;
                padding: 90px 65px;
            }
        }
    }

    @media @xs-max {
        .item-content-wrapper {
            height: auto;

            .item-content {
                padding: 70px 25px;

                h5 {
                    font-size: @fz-heading-5 - 2px;
                }

                .heading-icon-1 {
                    &:before {
                        font-size: 60px;
                    }
                }

                h2 {
                    font-size: @fz-heading-2 - 9px;
                    margin-bottom: 25px;
                }

                .paragraph {
                    font-size: @fz-common-3 - 1px;
                    margin-bottom: 25px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .item-content-wrapper {
            .item-content {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

// Slip Contact
.slip-contact {
    .slip-contact-item-wrapper {
        position: relative;

        .overlay {
            transition: all 0s;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        .caption-wrapper {
            .caption-logo {
                width: 170px;
                margin-bottom: 45px;
            }

            .contact-info {
                margin-bottom: 38px;

                p {
                    .fz-common-3;
                    .font-serif;
                    .color-dark;
                    margin-bottom: 0;

                    span {
                        margin-left: 20px;
                        color: @color-gray - #222;
                    }
                }
            }

            .social-04 {
                > a {
                    margin-left: 12px;
                    margin-right: 12px;

                    &:hover {
                        .color-gray;
                    }
                }
            }
        }
    }

    @media (min-width: 561px) {
        .slip-contact-item-wrapper {
            .overlay {
                max-width: 470px;
                max-height: 470px;
                background-color: fade(@bgc-light,90%);
                border-radius: 50%;

                .caption-wrapper {
                    .contact-info {
                        p {
                            padding: 0 25px;
                        }
                    }

                    .social-04 {
                        width: 290px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    @media @sm-max {
        min-height: 590px;
    }

    @media @xs-max {
        min-height: 550px;
        max-height: 770px;
    }

    @media (max-width: 560px) {
        .slip-contact-item-wrapper {
            .caption-wrapper {
                background-color: fade(@bgc-light,90%);
                padding: 40px 15px;
            }
        }
    }
}