/* TABLE CONTENT */
/* 1.Component */
/* ____Typography */
/* ____Color */
/* ____Utilities */
/* ____Boostrap Extent */
/* ____Section */
/* ____Input */
/* ____Button */
/* ____Social */
/* ____Separator */
/* 2.Custom Plugin */
/* ____Flexslider Custom */
/* ____Slick Theme Custom */
/* ____Magnific Popup Custom */
/* 3.Site Module */
/* ____Navigator */
/* ____Sticky Navigator */
/* ____Header */
/* ____Home */
/* ____Slip Page */
/* ____Services */
/* ____Cover Box */
/* ____Widget */
/* ____Team */
/* ____Blog */
/* ____Testimonials */
/* ____Call To Action */
/* ____Accordion */
/* ____Portfolio */
/* ____Blog Homepage */
/* ____Post Detail*/
/* ____Portfolio Special */
/* ____Tab */
/* ____Count */
/* ____Page */
/* ____Footer */
/* ____Shop Slider */
/* ____Shop */
/* ____Quote */
/* ____Product */
/* ____Brand Slider */
/* ____Portfolio Slider */
/* ____Highlights */
/* ____Message Box */
/* ____Pricing Table */
/* ____Block Quotes */
/* ____Dropcaps*/
/* ____Shortcode Button */
/* ____Shortcode Parallax */
/* ____Index Demo Page */
/* =============== component ===============*/
/* Typography */
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.italic {
  font-style: italic;
}
body {
  -webkit-font-smoothing: antialiased;
  color: #555555;
  font-size: 13px;
  font-weight: 400;
  line-height: 2.2;
  text-rendering: optimizeLegibility;
  font-family: 'Raleway', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.6;
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 21px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 11px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 25px;
  margin-top: 0;
}
h1.smb,
h2.smb,
h3.smb,
h4.smb,
h5.smb,
h6.smb,
p.smb {
  margin-bottom: 10px;
}
h1.nmb,
h2.nmb,
h3.nmb,
h4.nmb,
h5.nmb,
h6.nmb,
p.nmb {
  margin-bottom: 0;
}
::selection {
  background-color: #9c5b57;
  color: #ffffff;
}
*:focus {
  outline: none;
}
.heading-icon-1 {
  transition: all 0.4s ease-in-out;
}
.heading-icon-1:before {
  color: #9c5b57;
  content: "\e2d0";
  display: block;
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.heading-icon-2 {
  transition: all 0.4s ease-in-out;
}
.heading-icon-2:after {
  color: #9c5b57;
  content: "\e2cf";
  display: block;
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.heading-bgc-gray {
  padding: 22px 0;
  background-color: #f4f4f4;
}
.heading-hr-1:after {
  border-bottom: 2px solid #9c5b57;
  content: "";
  display: block;
  position: relative;
  margin-top: 20px;
  width: 40px;
}
.heading-hr-1.center {
  text-align: center;
}
.heading-hr-1.center:after {
  margin-left: auto;
  margin-right: auto;
}
.heading-hr-1.right {
  text-align: right;
}
.heading-hr-1.right:after {
  margin-left: auto;
  margin-right: 0;
}
.heading-hr-2 {
  text-align: center;
}
.heading-hr-2:after {
  border-bottom: 1px solid #555555;
  content: "";
  display: block;
  position: relative;
  margin-top: 5px;
  width: 40px;
}
.heading-hr-2:after {
  margin-left: auto;
  margin-right: auto;
}
.heading-border-1 {
  position: relative;
  text-align: center;
}
.heading-border-1:after {
  border-top: 1px solid #dddddd;
  content: "";
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 3;
}
.heading-border-1 span {
  border: 1px solid #dddddd;
  padding: 15px 55px;
  position: relative;
  z-index: 5;
  background-color: #ffffff;
}
.font-heading {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
.font-serif {
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.font-content {
  font-family: 'Raleway', sans-serif;
}
.fz-common-1 {
  font-size: 28px;
}
.fz-common-2 {
  font-size: 20px;
}
.fz-common-3 {
  font-size: 16px;
}
.fz-common-4 {
  font-size: 13px;
}
.fz-common-5 {
  font-size: 11px;
}
.fz-common-6 {
  font-size: 10px;
}
/* Color */
.color-main {
  color: #9c5b57;
}
.color-main-2 {
  color: #518f37;
}
.color-light {
  color: #ffffff;
}
.color-gray {
  color: #999999;
}
.color-lightgray {
  color: #dddddd;
}
.color-dark {
  color: #333333;
}
.color-common {
  color: #555555;
}
.bgc-main {
  background-color: #9c5b57;
}
.bgc-dark {
  background-color: #333333;
}
.bgc-light {
  background-color: #ffffff;
}
.bgc-gray {
  background-color: #f4f4f4;
}
.border-bottom {
  border-bottom: solid 1px #eeeeee;
}
.border-top {
  border-top: solid 1px #eeeeee;
}
/* Utilities */
.clearfix:after {
  clear: both;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
.layout-after {
  position: relative;
}
.layout-after:after {
  background-color: transparent;
  content: "";
  display: block;
  position: absolute;
  z-index: 512;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.overlay {
  background-color: transparent;
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.middle-align {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.cell-vertical-wrapper {
  display: table;
  height: 100%;
  width: 100%;
}
.cell-vertical-wrapper .cell-middle {
  display: table-cell;
  vertical-align: middle;
}
.cell-vertical-wrapper .cell-bottom {
  display: table-cell;
  vertical-align: bottom;
}
.star-ratings span {
  font-size: 20px;
  line-height: 1;
  margin: 2px;
}
.star-ratings span.rated {
  color: #9c5b57;
}
.star-ratings span:not(.rated) {
  color: #999999;
}
a,
a:hover,
a:focus,
a:active,
a.active {
  color: inherit;
  outline: none;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  overflow-x: hidden;
}
html {
  overflow-x: hidden;
}
.video-container {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}
.video-container iframe,
.video-container object,
.video-container embed {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  border: 0;
}
.audio-container {
  position: relative;
}
.audio-container iframe,
.audio-container object,
.audio-container embed {
  display: block;
  height: 100%;
  width: 100%;
  border: 0;
}
.parallax-bg {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.parallax-bg:after {
  background-color: transparent;
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.page-common-background {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.page-common-background:after {
  background-color: rgba(51, 51, 51, 0.6);
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.parallax-bg.background-default {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.parallax-bg.background-default:after {
  background-color: rgba(51, 51, 51, 0.7);
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.fixed-ratio-1-1 {
  position: relative;
  display: block;
  height: 0;
  padding-top: 100%;
}
.fixed-ratio-1-1 > *:only-child {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-height: 100%;
  overflow: auto;
}
.fixed-ratio-1-2 {
  position: relative;
  display: block;
  height: 0;
  padding-top: 200%;
}
.fixed-ratio-1-2 > *:only-child {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-height: 100%;
  overflow: auto;
}
.fixed-ratio-2-1 {
  position: relative;
  display: block;
  height: 0;
  padding-top: 50%;
}
.fixed-ratio-2-1 > *:only-child {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-height: 100%;
  overflow: auto;
}
.page-breadcumb {
  padding-top: 18px ;
  padding-bottom: 18px ;
  font-weight: 600;
}
.page-breadcumb a {
  display: inline-block;
}
.page-breadcumb a:last-child {
  color: #9c5b57;
}
.page-breadcumb a:not(:first-child):before {
  content: " / ";
  display: inline-block;
  padding: 0 8px;
}
.page-breadcumb.no-content * {
  visibility: hidden;
}
.pagination {
  float: right;
  padding: 0 10px;
  margin-bottom: 60px;
  background-color: #f4f4f4;
  color: #999999;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
.bgc-gray .pagination {
  background-color: #ffffff;
}
.pagination a {
  display: inline-block;
  margin: 3px 10px;
  font-size: 14px;
}
.pagination a:hover,
.pagination a.active {
  color: #9c5b57;
}
.pagination a i.icon-svg-icon-18 {
  font-size: 10px;
  display: inline-block;
}
.pagination.left {
  float: left;
}
.pagination-2 {
  text-align: center;
  line-height: 0;
}
.pagination-2 ul {
  padding: 0;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
.pagination-2 ul:after {
  clear: both;
}
.pagination-2 ul:before,
.pagination-2 ul:after {
  content: " ";
  display: table;
}
.pagination-2 ul:after {
  clear: both;
}
.pagination-2 ul:before,
.pagination-2 ul:after {
  content: " ";
  display: table;
}
.pagination-2 li {
  transition: all 0.4s ease-in-out;
  display: inline-block;
  text-align: center;
  float: left;
}
.pagination-2 li:not(:last-of-type) {
  margin-right: 15px;
}
.pagination-2 li a {
  border: 1px solid #dddddd;
  width: 44px;
  height: 44px;
  color: #9c5b57;
  display: inline-block;
  line-height: 42px;
  font-size: 14px;
}
.pagination-2 li a i {
  transition: all 0.4s ease-in-out;
  color: #777;
  font-size: 10px;
  line-height: 42px;
  font-weight: bold;
  vertical-align: middle;
  display: inline-block;
}
.pagination-2 li a i:before {
  line-height: 1;
}
.pagination-2 li:hover a,
.pagination-2 li.active a {
  border-color: #9c5b57;
  background-color: #9c5b57;
  color: #ffffff;
}
.pagination-2 li:hover a i,
.pagination-2 li.active a i {
  color: #ffffff;
}
.pagination-2.right {
  text-align: right;
}
.pagination-2.left {
  text-align: left;
}
/* Bootsrap Extent */
@media (min-width: 1400px) {
  .ex-layout .container {
    width: 1320px;
  }
  .ex-layout .container:not(.remove-col-padding),
  .ex-layout .container-fluid:not(.remove-col-padding) [class*='col-'] {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ex-layout .row {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.remove-col-padding [class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}
.remove-col-padding-2 {
  padding-left: 0;
  padding-right: 0;
}
.max-w {
  max-width: 1920px;
}
/* Section */
main:after {
  clear: both;
}
main:before,
main:after {
  content: " ";
  display: table;
}
main:after {
  clear: both;
}
main:before,
main:after {
  content: " ";
  display: table;
}
section {
  position: relative;
  padding-top: 75px;
  padding-bottom: 35px;
}
section:after {
  clear: both;
}
section:before,
section:after {
  content: " ";
  display: table;
}
section:after {
  clear: both;
}
section:before,
section:after {
  content: " ";
  display: table;
}
section .section-header {
  margin-bottom: 35px;
}
section .section-header:after {
  clear: both;
}
section .section-header:before,
section .section-header:after {
  content: " ";
  display: table;
}
section .section-header:after {
  clear: both;
}
section .section-header:before,
section .section-header:after {
  content: " ";
  display: table;
}
section .section-block {
  margin-bottom: 40px;
}
section .section-block:after {
  clear: both;
}
section .section-block:before,
section .section-block:after {
  content: " ";
  display: table;
}
section .section-block:after {
  clear: both;
}
section .section-block:before,
section .section-block:after {
  content: " ";
  display: table;
}
section .section-block-p {
  margin-bottom: 15px;
}
section .section-block-p:after {
  clear: both;
}
section .section-block-p:before,
section .section-block-p:after {
  content: " ";
  display: table;
}
section .section-block-p:after {
  clear: both;
}
section .section-block-p:before,
section .section-block-p:after {
  content: " ";
  display: table;
}
section.one-child {
  padding-bottom: 75px;
}
section.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
section.min-padding {
  padding-top: 50px!important;
  padding-bottom: 0!important;
}
section.no-padding-bottom {
  padding-bottom: 0!important;
}
section.no-padding-bottom .section-block:last-of-type,
section.no-padding-bottom .section-block-p:last-of-type {
  margin-bottom: 0!important;
}
section.no-padding-top {
  padding-top: 0!important;
}
section.large-space {
  padding-top: 105px;
  padding-bottom: 65px;
}
section.large-space .section-header {
  margin-bottom: 80px;
}
section.large-space.one-child {
  padding-bottom: 105px;
}
.header-type-1 {
  text-align: center;
}
.header-type-1.style-1 h2 {
  margin-top: 0;
}
.header-type-1.style-1 h5 {
  margin-bottom: 12px;
}
.header-type-1.style-2 {
  text-align: left;
}
.header-type-1.style-2 .header-caption {
  margin-top: 0;
}
.header-type-1.style-2 h2 {
  margin-bottom: 0;
}
.header-type-1.style-2 h5 {
  margin-bottom: 12px;
}
.header-type-1.style-3 .header-caption {
  margin-top: 0;
}
.header-type-1.style-3 .heading-icon-2 {
  transition: all 0.4s ease-in-out;
}
.header-type-1.style-3 .heading-icon-2:after {
  color: #9c5b57;
  content: "\e2d1";
  display: block;
  font-size: 68px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.header-type-1.style-3 h2 {
  margin-bottom: 0;
  margin-top: 0;
}
.header-type-1.style-3 h5 {
  margin-bottom: 12px;
}
.header-type-1 .header-caption {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
}
.header-type-1 h5 {
  color: #777777;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
}
.header-type-2 .header-caption {
  display: block;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  margin-bottom: 45px;
}
.header-type-2.style-1 .header-caption {
  max-width: 800px;
}
.header-type-2.style-2 .header-caption {
  font-size: 18px;
  color: #7b7b7b;
}
/* Input */
input:not([type="submit"]):not([type="checkbox"]),
textarea {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  padding: 13px;
  transition: all 0.4s ease-in-out;
  font-family: 'Raleway', sans-serif;
}
input:not([type="submit"]):not([type="checkbox"]):focus,
textarea:focus {
  border-color: #9c5b57;
}
input:-webkit-autofill {
  background-color: red !important;
}
/* Button */
.border-btn {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  font-size: 10px;
  letter-spacing: 2px;
  line-height: 1.6;
  outline: none;
  transition: all 0.4s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
.border-btn.border-btn-light {
  border-color: #ffffff;
  color: #ffffff;
}
.border-btn.border-btn-light:hover,
.border-btn.border-btn-light:focus {
  background-color: #ffffff;
  color: #333333;
}
.border-btn.border-btn-gray {
  border-color: #999999;
  color: #555555;
}
.border-btn.border-btn-gray:hover,
.border-btn.border-btn-gray:focus {
  background-color: #999999;
  color: #ffffff;
}
.border-btn.border-btn-lightgray {
  border-color: #dddddd;
  color: #777777;
}
.border-btn.border-btn-lightgray:hover,
.border-btn.border-btn-lightgray:focus {
  background-color: #dddddd;
  color: #ffffff;
}
.border-btn.border-btn-main {
  border-color: #9c5b57;
  color: #9c5b57;
}
.border-btn.border-btn-main.color-common {
  color: #555555;
}
.border-btn.border-btn-main:hover,
.border-btn.border-btn-main:focus {
  background-color: #9c5b57;
  color: #ffffff;
}
.border-btn.btn-size-1 {
  padding: 16px 139px;
}
.border-btn.btn-size-2 {
  padding: 16px 107px;
}
.border-btn.btn-size-3 {
  padding: 13px 87px;
}
.border-btn.btn-size-4 {
  padding: 13px 57px;
}
.border-btn.btn-size-5 {
  padding: 13px 37px;
}
.border-btn.btn-size-6 {
  padding: 9px 17px;
}
.normal-btn {
  border: 0;
  display: inline-block;
  font-size: 10px;
  letter-spacing: 2px;
  line-height: 1.6;
  outline: none;
  transition: all 0.4s ease-in-out;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  color: #ffffff;
}
.normal-btn.btn-size-1 {
  padding: 17px 140px;
}
.normal-btn.btn-size-2 {
  padding: 17px 108px;
}
.normal-btn.btn-size-3 {
  padding: 14px 88px;
}
.normal-btn.btn-size-4 {
  padding: 14px 58px;
}
.normal-btn.btn-size-5 {
  padding: 14px 38px;
}
.normal-btn.btn-size-6 {
  padding: 10px 18px;
}
.normal-btn.normal-btn-dark {
  background-color: #333333;
}
.normal-btn.normal-btn-dark:hover,
.normal-btn.normal-btn-dark:focus {
  background-color: #9c5b57;
  color: #ffffff;
}
.normal-btn.normal-btn-darkgray {
  background-color: #555555;
}
.normal-btn.normal-btn-darkgray:hover,
.normal-btn.normal-btn-darkgray:focus {
  background-color: #333333;
  color: #ffffff;
}
.normal-btn.normal-btn-gray {
  background-color: #999999;
}
.normal-btn.normal-btn-gray:hover,
.normal-btn.normal-btn-gray:focus {
  background-color: #333333;
  color: #ffffff;
}
.normal-btn.normal-btn-main {
  background-color: #9c5b57;
}
.normal-btn.normal-btn-main:hover,
.normal-btn.normal-btn-main:focus {
  background-color: #333333;
  color: #ffffff;
}
/* Social */
.social-01 {
  line-height: 1;
}
.social-01 li {
  display: inline-block;
}
.social-01 li a {
  display: inline-block;
  color: #aaaaaa;
}
.social-01 li a i:before {
  font-size: 13px;
  margin-right: 0;
  vertical-align: middle;
  font-family: FontAwesome;
}
.social-02 {
  line-height: 1;
}
.social-02 li {
  display: inline-block;
}
.social-02 li a {
  display: inline-block;
  color: #aaaaaa;
}
.social-02 li a i:before {
  font-size: 13px;
  margin-right: 0;
  vertical-align: middle;
  font-family: FontAwesome;
}
.social-03 {
  line-height: 1;
}
.social-03 li {
  display: inline-block;
}
.social-03 li a {
  display: inline-block;
  color: #9c5b57;
}
.social-03 li a i:before {
  font-size: 13px;
  margin-right: 10px;
  vertical-align: middle;
  font-family: FontAwesome;
}
.social-03 li a i {
  color: #9c5b57;
  font-size: 13px;
}
.social-03 i {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-size: 12px !important;
}
.social-04 a {
  color: #9c5b57;
  display: inline-block;
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
/* Separator */
.separator-1 {
  width: 100%;
  height: 12px;
  background-color: #444444;
}
.separator-2 {
  width: 100%;
  height: 8px;
  background-color: #dddddd;
}
.separator-3 {
  width: 100%;
  height: 4px;
  background-color: #dddddd;
}
.separator-4 {
  width: 100%;
  height: 2px;
  background-color: #dddddd;
}
.separator-5 {
  width: 100%;
  height: 1px;
  background-color: #dddddd;
}
.separator-6 {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
}
/* Flexslider Custom */
/*
 * jQuery FlexSlider v2.5.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 * 
 */
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover,
.flex-container a:focus,
.flex-slider a:focus {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider-custom .slides:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
.flexslider-custom .slides > li {
  -webkit-backface-visibility: hidden;
  display: none;
}
.flexslider-custom .slides img {
  display: block;
  width: 100%;
}
html[xmlns] .flexslider-custom .slides {
  display: block;
}
* html .flexslider-custom .slides {
  height: 1%;
}
.no-js .flexslider-custom .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider-custom {
  position: relative;
  zoom: 1;
}
.flexslider-custom .slides {
  zoom: 1;
}
.flexslider-custom .slides img {
  height: auto;
}
.flex-viewport {
  max-height: 2000px;
  transition: all 0.4s ease-in-out;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  *height: 0;
}
.flex-direction-nav .flex-disabled {
  cursor: default;
  filter: alpha(opacity=0);
  opacity: 0!important;
}
.flex-direction-nav a {
  cursor: pointer;
  display: block;
  height: 40px;
  margin: -20px 0 0;
  overflow: hidden;
  position: absolute;
  width: 40px;
  z-index: 10;
}
.flex-direction-nav a:before {
  content: "";
  display: inline-block;
  transition: all 0.4s ease-in-out;
}
.flex-pauseplay a {
  bottom: 5px;
  color: #000;
  cursor: pointer;
  display: block;
  height: 20px;
  left: 10px;
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  width: 20px;
  z-index: 10;
}
.flex-pauseplay a:before {
  content: '';
  display: inline-block;
  font-size: 20px;
}
.flex-control-nav {
  bottom: -40px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.flex-control-nav li {
  *display: inline;
  display: inline-block;
  margin: 0 6px;
  zoom: 1;
}
.flex-control-paging li a {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: block;
  height: 11px;
  text-indent: -9999px;
  width: 11px;
}
.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}
.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}
.flex-control-thumbs {
  margin: 5px 0 0;
  overflow: hidden;
  position: static;
}
.flex-control-thumbs .flex-active {
  cursor: default;
  opacity: 1;
}
.flex-control-thumbs img {
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  cursor: pointer;
  display: block;
  height: auto;
  opacity: 0.7;
  transition: all 1s ease;
  width: 100%;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs li {
  float: left;
  margin: 0;
  width: 25%;
}
/* Slick Theme Custom */
/* Slider */
.slick-loading .slick-list {
  background: #ffffff url("../images/ajax-loader.gif") center center no-repeat;
}
/* Arrows */
.slick-prev,
.slick-next {
  background: transparent;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  line-height: 0;
  padding: 0;
  position: absolute;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  background: transparent;
  color: transparent;
}
.slick-prev:before,
.slick-next:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  transition: all 0.4s ease-in-out;
}
.slick-prev:before {
  content: "";
}
.slick-next:before {
  content: "";
}
/* Dots */
.slick-dots {
  display: block;
  list-style: none;
  padding: 0;
  position: absolute;
  text-align: center;
}
.slick-dots li {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  position: relative;
}
.slick-dots li button {
  background: transparent;
  border: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  line-height: 0;
}
.slick-dots li button:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
}
/* Magnific Popup Custom */
/* Magnific Popup CSS */
.mfp-bg {
  background: #0b0b0b;
  height: 100%;
  left: 0;
  opacity: 0.8;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11002;
}
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
.mfp-wrap {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11003;
}
.mfp-container {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  padding: 0 8p;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
  display: none;
}
.mfp-content {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  z-index: 11005;
}
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  cursor: auto;
  width: 100%;
}
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
  cursor: auto;
}
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.mfp-loading.mfp-figure {
  display: none;
}
.mfp-preloader {
  color: #cccccc;
  left: 8px;
  margin-top: -0.8em;
  position: absolute;
  right: 8px;
  text-align: center;
  top: 50%;
  width: auto;
  z-index: 11004;
}
.mfp-preloader a {
  color: #cccccc;
}
.mfp-preloader a:hover {
  color: #ffffff;
}
.mfp-s-ready .mfp-preloader {
  display: none;
}
.mfp-s-error .mfp-content {
  display: none;
}
button.mfp-close,
button.mfp-arrow {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  outline: none;
  overflow: visible;
  padding: 0;
  z-index: 11006;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.mfp-close {
  color: #ffffff;
  font-family: Arial, Baskerville, monospace;
  font-size: 0;
  font-style: normal;
  height: 44px;
  line-height: 44px;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  transition: opacity 0.4s;
  width: 44px;
}
.mfp-close:active {
  top: 1px;
}
.mfp-close:before {
  background-color: rgba(0, 0, 0, 0.6);
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 2px 2px 3px #000;
  content: "\f00d";
  cursor: pointer;
  font-size: 14px;
  height: 24px;
  line-height: 20px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 5px;
  width: 24px;
  font-family: FontAwesome;
}
.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}
.mfp-close-btn-in .mfp-close {
  color: #333333;
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #ffffff;
  padding-right: 6px;
  right: -6px;
  text-align: right;
  width: 100%;
}
.mfp-counter {
  color: #cccccc;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
}
.mfp-arrow {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 110px;
  margin: 0;
  margin-top: -55px;
  opacity: 0.65;
  padding: 0;
  position: absolute;
  top: 50%;
  transition: opacity 0.4s;
  width: 90px;
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:after,
.mfp-arrow .mfp-a {
  border-bottom-width: 13px;
  border-top-width: 13px;
  display: none;
  top: 8px;
}
.mfp-arrow:before {
  background-color: rgba(0, 0, 0, 0.6);
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 2px 2px 3px #000;
  font-size: 20px;
  height: 36px;
  line-height: 32px;
  width: 36px;
  color: #ffffff;
  font-family: "wolverine";
}
.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
  content: '';
  display: block;
  margin-top: 35px;
  position: absolute;
  top: 0;
}
.mfp-arrow:hover {
  opacity: 1;
}
.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:before {
  content: "\e02d";
  left: 0;
  margin-left: 30px;
  padding-right: 2px;
}
.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:before {
  content: "\e07a";
  margin-right: 30px;
  padding-left: 2px;
  right: 0;
}
.mfp-iframe-holder {
  padding-bottom: 40px;
  padding-top: 40px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  max-width: 900px;
  width: 100%;
}
.mfp-iframe-scaler {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  width: 100%;
}
.mfp-iframe-scaler iframe {
  background: #000000;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
/* Main image in popup */
img.mfp-img {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  height: auto;
  line-height: 0;
  margin: 0 auto;
  max-width: 100%;
  padding: 40px 0 40px;
  width: auto;
}
/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  background: #444444;
  bottom: 40px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  content: '';
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 40px;
  width: auto;
  z-index: -1;
}
.mfp-figure figure {
  margin: 0;
}
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-bottom-bar {
  cursor: auto;
  left: 0;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  width: 100%;
}
.mfp-title {
  color: #f3f3f3;
  line-height: 18px;
  padding-right: 36px;
  text-align: left;
  word-wrap: break-word;
}
.mfp-image-holder .mfp-content {
  max-width: 100%;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   * Remove all paddings around the image on small screen
   */
  .mfp-img-mobile .mfp-bottom-bar {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-close {
    background: rgba(0, 0, 0, 0.6);
    height: 35px;
    line-height: 35px;
    padding: 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    width: 35px;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-figure:after {
    bottom: 0;
    top: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow:before {
    font-size: 16px;
    height: 28px;
    line-height: 24px;
    width: 28px;
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-left:before {
    margin-left: 15px;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-arrow-right:before {
    margin-right: 15px;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/* =============== module ===============*/
/* Navigator */
nav:after {
  clear: both;
}
nav:before,
nav:after {
  content: " ";
  display: table;
}
nav:after {
  clear: both;
}
nav:before,
nav:after {
  content: " ";
  display: table;
}
.nav-main-menu:after {
  clear: both;
}
.nav-main-menu:before,
.nav-main-menu:after {
  content: " ";
  display: table;
}
.nav-main-menu:after {
  clear: both;
}
.nav-main-menu:before,
.nav-main-menu:after {
  content: " ";
  display: table;
}
.nav-main-menu a {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  cursor: pointer;
}
.nav-main-menu:not(.left-menu) a {
  font-size: 11px;
}
.nav-main-menu a._pending {
  font-style: italic;
  line-height: 24px;
  font-family: 'Raleway', sans-serif;
  font-size: 13px;
}
@media only screen and (min-width: 992px ) {
  .nav-main-menu.small-screen {
    display: none;
  }
  .nav-main-menu:not(.left-menu) {
    height: 100%;
  }
  .nav-main-menu:not(.left-menu) > li {
    height: 100%;
    float: left;
  }
  .nav-main-menu:not(.left-menu) > li > a {
    padding-right: 30px;
    padding-left: 30px;
    display: inline-block;
  }
  .nav-main-menu:not(.left-menu) > li.division {
    position: relative;
    width: 1px;
  }
  .nav-main-menu:not(.left-menu) > li.division span {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu {
    position: relative;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu,
  .nav-main-menu:not(.left-menu) > .sub-menu.menu-tabs {
    position: static;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu > a:after {
    font-family: "wolverine";
    content: ":";
    position: absolute;
    font-size: 15px;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu ul {
    background-color: #000000;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu ul > li > a {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: block;
    color: #cccccc;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu ul > li:not(.menu-title):hover > a {
    background-color: #1B1B1B;
    color: #9c5b57;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu > ul {
    display: none;
    position: absolute;
    top: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    min-width: 220px;
    border-bottom: 2px solid #9c5b57;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu:not(.mega-menu) li.current-menu-item > a {
    color: #9c5b57;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu .sub-menu {
    position: relative;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu .sub-menu > a {
    position: relative;
    padding-right: 25px;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu .sub-menu > a:after {
    position: absolute;
    font-family: "wolverine";
    content: "\e07f";
    top: 0;
    right: 10px;
    bottom: 0;
    font-size: 15px;
    height: 15px;
    width: 15px;
    line-height: 1;
    margin-top: auto;
    margin-bottom: auto;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu .sub-menu > ul {
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    left: 100%;
    top: -10px;
    min-width: 220px;
    border: 1px solid #111;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu .sub-menu:hover > ul {
    display: block;
    animation: fadeIn 0.7s;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu:hover > ul {
    display: block;
    animation: fadeIn 0.7s;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu .mega-menu-content {
    width: 100%;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu .mega-menu-content > li {
    float: left;
    min-width: 180px;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu .mega-menu-content > li > a {
    display: none;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu .mega-menu-content > li.menu-title > a {
    display: block;
    font-size: 14px;
    padding-bottom: 0;
    margin-bottom: 15px;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu .mega-menu-content > li.menu-title > a:after {
    border-bottom: 2px solid transparent;
    content: "";
    display: block;
    position: relative;
    margin-top: 5px;
    width: 40px;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu .mega-menu-content > li.menu-title > a:after {
    transition: all 0.4s ease-in-out;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu .mega-menu-content > li.menu-title:hover > a {
    color: #eeeeee;
    background-color: transparent;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu .mega-menu-content > li.menu-title:hover > a:after {
    border-color: #9c5b57;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu.menu-4-col .mega-menu-content > li {
    width: 25%;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu.menu-5-col .mega-menu-content > li {
    width: 20%;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu li.current-menu-item.menu-title > a:after {
    border-top-color: #9c5b57;
  }
  .nav-main-menu:not(.left-menu) > .sub-menu.mega-menu li.current-menu-item:not(.menu-title) > a {
    color: #9c5b57;
  }
  .nav-main-menu:not(.left-menu).style-2 > .sub-menu ul {
    background-color: #ffffff;
  }
  .nav-main-menu:not(.left-menu).style-2 > .sub-menu ul > li > a {
    color: #333333;
  }
  .nav-main-menu:not(.left-menu).style-2 > .sub-menu ul > li:not(.menu-title) > a {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  .nav-main-menu:not(.left-menu).style-2 > .sub-menu ul > li:not(.menu-title):hover > a {
    background-color: #F0F0F0;
    color: #9c5b57;
    border-top: 1px solid #BBB;
    border-bottom: 1px solid #BBB;
  }
  .nav-main-menu:not(.left-menu).style-2 > .sub-menu > ul {
    border: 1px solid #999;
    border-bottom: 3px solid #9c5b57;
  }
  .nav-main-menu:not(.left-menu).style-2 > .sub-menu .sub-menu > ul {
    border: 1px solid #999;
    border-bottom: 3px solid #9c5b57;
  }
  .nav-main-menu:not(.left-menu).style-2 > .sub-menu.mega-menu .mega-menu-content > li > ul > li > a {
    border: 1px solid transparent;
  }
  .nav-main-menu:not(.left-menu).style-2 > .sub-menu.mega-menu .mega-menu-content > li.menu-title:hover > a {
    color: #333333;
  }
}
@media (max-width: 991px ) {
  .nav-main-menu:not(.small-screen) {
    display: none;
  }
  .nav-main-menu.small-screen {
    transition: all 0.4s ease-in-out;
    position: absolute;
    top: 100%;
    width: 250px;
    right: 0;
    overflow: auto;
    background-color: #222222;
  }
  .nav-main-menu.small-screen a {
    color: #eeeeee;
    display: block;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
  }
  .nav-main-menu.small-screen a:hover {
    color: #9c5b57;
  }
  .nav-main-menu.small-screen li {
    display: block;
  }
  .nav-main-menu.small-screen li.current-menu-item > a {
    color: #9c5b57;
  }
  .nav-main-menu.small-screen > li {
    border-bottom: 1px solid #0F0F0F;
  }
  .nav-main-menu.small-screen > li > a:not(.menu-trigger):hover,
  .nav-main-menu.small-screen > li > a.menu-trigger {
    background-color: #1B1B1B;
  }
  .nav-main-menu.small-screen > li.menu-dropdown > ul {
    background-color: #333333;
  }
  .nav-main-menu.small-screen > li.menu-dropdown > ul:before {
    border-top-color: #1B1B1B;
  }
  .nav-main-menu.small-screen > li.menu-dropdown > ul > li:not(:last-of-type) {
    border-bottom: 1px solid #0F0F0F;
  }
  .nav-main-menu.small-screen > li.menu-dropdown > ul > li > a:not(.menu-trigger):hover,
  .nav-main-menu.small-screen > li.menu-dropdown > ul > li > a.menu-trigger {
    background-color: #292929;
  }
  .nav-main-menu.small-screen > li.menu-dropdown > ul > li.menu-dropdown > ul {
    background-color: #444444;
  }
  .nav-main-menu.small-screen > li.menu-dropdown > ul > li.menu-dropdown > ul:before {
    border-top-color: #292929;
  }
  .nav-main-menu.small-screen > li.menu-dropdown > ul > li.menu-dropdown > ul > li:not(:last-of-type) {
    border-bottom: 1px solid #0F0F0F;
  }
  .nav-main-menu.small-screen > li.menu-dropdown > ul > li.menu-dropdown > ul > li > a:not(.menu-trigger):hover {
    background-color: #383838;
  }
  .nav-main-menu.small-screen .menu-dropdown > a {
    position: relative;
    padding-right: 25px;
  }
  .nav-main-menu.small-screen .menu-dropdown > a:after {
    transition: transform 0.4s ease-in-out;
    position: absolute;
    font-family: "wolverine";
    content: "\e07f";
    top: 0;
    right: 10px;
    bottom: 0;
    font-size: 15px;
    height: 15px;
    width: 15px;
    line-height: 1;
    margin-top: auto;
    margin-bottom: auto;
    transform: rotate(0deg);
  }
  .nav-main-menu.small-screen .menu-dropdown > a.menu-trigger:after {
    transform: rotate(90deg);
  }
  .nav-main-menu.small-screen .menu-dropdown > a:hover {
    color: #9c5b57;
  }
  .nav-main-menu.small-screen .menu-dropdown > ul {
    position: relative;
  }
  .nav-main-menu.small-screen .menu-dropdown > ul:before {
    content: "";
    display: block;
    border-left: solid 6px transparent;
    border-right: solid 6px transparent;
    border-top: solid 6px;
    position: absolute;
    top: 0;
    left: 20px;
    z-index: 1;
  }
  .nav-main-menu.small-screen.style-2 {
    transition: all 0.4s ease-in-out;
    background-color: #ffffff;
  }
  .nav-main-menu.small-screen.style-2 a {
    color: #333333;
  }
  .nav-main-menu.small-screen.style-2 a:hover {
    color: #9c5b57;
  }
  .nav-main-menu.small-screen.style-2 > li {
    border-bottom: 1px solid #BBB;
  }
  .nav-main-menu.small-screen.style-2 > li > a:not(.menu-trigger):hover,
  .nav-main-menu.small-screen.style-2 > li > a.menu-trigger {
    background-color: #F1F1F1;
  }
  .nav-main-menu.small-screen.style-2 > li.menu-dropdown > ul {
    background-color: #E5E5E5;
  }
  .nav-main-menu.small-screen.style-2 > li.menu-dropdown > ul:before {
    border-top-color: #F1F1F1;
  }
  .nav-main-menu.small-screen.style-2 > li.menu-dropdown > ul > li:not(:last-of-type) {
    border-bottom: 1px solid #BBB;
  }
  .nav-main-menu.small-screen.style-2 > li.menu-dropdown > ul > li > a:not(.menu-trigger):hover,
  .nav-main-menu.small-screen.style-2 > li.menu-dropdown > ul > li > a.menu-trigger {
    background-color: #DDD;
  }
  .nav-main-menu.small-screen.style-2 > li.menu-dropdown > ul > li.menu-dropdown > ul {
    background-color: #D2D2D2;
  }
  .nav-main-menu.small-screen.style-2 > li.menu-dropdown > ul > li.menu-dropdown > ul:before {
    border-top-color: #DDD;
  }
  .nav-main-menu.small-screen.style-2 > li.menu-dropdown > ul > li.menu-dropdown > ul > li:not(:last-of-type) {
    border-bottom: 1px solid #BBB;
  }
  .nav-main-menu.small-screen.style-2 > li.menu-dropdown > ul > li.menu-dropdown > ul > li > a:not(.menu-trigger):hover {
    background-color: #CCC;
  }
  .nav-main-menu.small-screen.style-2 .menu-dropdown > a:hover {
    color: #9c5b57;
  }
}
.main-nav-wrapper {
  transition: height 0.4s ease-in-out;
  position: relative;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
}
.main-nav-wrapper:after {
  clear: both;
}
.main-nav-wrapper:before,
.main-nav-wrapper:after {
  content: " ";
  display: table;
}
.main-nav-wrapper:after {
  clear: both;
}
.main-nav-wrapper:before,
.main-nav-wrapper:after {
  content: " ";
  display: table;
}
.main-nav-wrapper nav {
  top: 0;
  left: 0;
  z-index: 1500;
  width: 100%;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 991px ) {
  .main-nav-wrapper {
    height: 60px;
  }
}
.main-nav {
  transition: all 0.4s ease-in-out;
  position: relative;
}
.main-nav .container {
  height: 100%;
}
.main-nav .nav-logo {
  transition: all 0.4s ease-in-out;
  float: left;
  height: 100%;
  position: relative;
}
.main-nav .nav-logo img {
  transition: all 0.4s ease-in-out;
  position: absolute;
  width: 100%;
  margin: auto;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.main-nav .nav-top-search {
  height: 100%;
}
.main-nav .nav-top-search .search-trigger-show {
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: inline-block;
  opacity: 0.7;
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
}
.main-nav .nav-top-search .search-trigger-show:before {
  content: "\e0d9";
  font-family: "wolverine";
}
.main-nav .nav-top-search .search-trigger-show:hover {
  opacity: 1;
}
.main-nav .nav-search-form {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #333333;
  top: 0;
  right: 0;
  transition: visibility 0s ease-in-out 0.4s, opacity 0.4s ease-in-out;
  visibility: hidden;
  opacity: 0;
}
.main-nav .nav-search-form .content-wrapper {
  max-width: 750px;
  position: absolute;
  width: 100%;
  height: 60px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 75px;
  padding-right: 75px;
}
.main-nav .nav-search-form .content-wrapper img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  width: 50px;
}
.main-nav .nav-search-form .content-wrapper img.logo-dark {
  display: none;
}
.main-nav .nav-search-form .content-wrapper .content {
  padding-right: 75px;
  position: relative;
}
.main-nav .nav-search-form .content-wrapper .content:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  height: 0;
  border-bottom: 1px solid #999999;
}
.main-nav .nav-search-form .content-wrapper .content input {
  width: 100%;
  height: 60px;
  line-height: 2.2;
  background-color: transparent;
  border: 0;
  font-size: 16px;
  color: #ffffff;
}
.main-nav .nav-search-form .content-wrapper .content button {
  transition: all 0.4s ease-in-out;
  position: absolute;
  top: 10px;
  right: 0;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  padding: 0;
  height: 40px;
  font-size: 16px;
  background-color: transparent;
  color: #ffffff;
  opacity: 0.7;
  border: 0;
}
.main-nav .nav-search-form .content-wrapper .content button:hover,
.main-nav .nav-search-form .content-wrapper .content button:focus {
  opacity: 1;
}
.main-nav .nav-search-form .content-wrapper a.search-trigger-hide {
  height: 28px;
  width: 28px;
  line-height: 24px;
  display: block;
  position: absolute;
  z-index: 2;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  border: 2px solid #ffffff;
  opacity: 0.7;
  top: 0;
  bottom: 0;
  right: 13px;
  margin-top: auto;
  margin-bottom: auto;
}
.main-nav .nav-search-form .content-wrapper a.search-trigger-hide:before {
  content: "\f00d";
  font-family: FontAwesome;
}
.main-nav .nav-search-form .content-wrapper a.search-trigger-hide:hover {
  opacity: 1;
}
.main-nav.nav-search-opened .nav-search-form {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.main-nav .nav-hamburger-special-wrapper,
.main-nav .nav-hamburger-wrapper {
  height: 100%;
}
.main-nav .nav-hamburger,
.main-nav .nav-hamburger-special {
  transition: all 0.4s ease-in-out;
  display: block;
  position: relative;
  background: transparent;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  width: 22px;
  height: 14px;
}
.main-nav .nav-hamburger::before,
.main-nav .nav-hamburger-special::before,
.main-nav .nav-hamburger::after,
.main-nav .nav-hamburger-special::after,
.main-nav .nav-hamburger span,
.main-nav .nav-hamburger-special span {
  transition: all 0.4s ease-in-out;
  background-color: #333333;
}
.main-nav .nav-hamburger::before,
.main-nav .nav-hamburger-special::before,
.main-nav .nav-hamburger::after,
.main-nav .nav-hamburger-special::after {
  content: '';
  position: absolute;
  top: 0;
  height: 2px;
  width: 100%;
  left: 0;
  top: 50%;
  transform-origin: 50% 50%;
}
.main-nav .nav-hamburger span,
.main-nav .nav-hamburger-special span {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: 50%;
  overflow: hidden;
  text-indent: 200%;
}
.main-nav .nav-hamburger::before,
.main-nav .nav-hamburger-special::before {
  transform: translate3d(0, -7px, 0);
}
.main-nav .nav-hamburger::after,
.main-nav .nav-hamburger-special::after {
  transform: translate3d(0, 7px, 0);
}
.main-nav .nav-hamburger-special {
  width: 26px;
  height: 16px;
}
.main-nav .division {
  height: 100%;
  position: relative;
  width: 1px;
}
.main-nav .division span {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.main-nav .nav-top-cart-wrapper {
  height: 100%;
}
.main-nav .nav-top-cart-wrapper .nav-top-cart {
  display: inline-block;
  position: relative;
  top: -4px;
  color: #999;
  line-height: 28px;
  vertical-align: middle;
  height: 30px;
}
.main-nav .nav-top-cart-wrapper .nav-top-cart:before {
  width: 22px;
  height: 18px;
  text-align: center;
  position: absolute;
  display: block;
  line-height: 18px;
  top: 0px;
  left: 0px;
  content: attr(data-content);
  font-size: 11px;
  color: #9c5b57;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
.main-nav .nav-top-cart-wrapper i {
  font-size: 18px;
  display: inline-block;
  line-height: 18px;
  height: 18px;
  vertical-align: bottom;
}
@media only screen and (min-width: 992px ) {
  .main-nav .nav-search-form .content-wrapper {
    max-width: 960px;
  }
  .main-nav .nav-hamburger-wrapper {
    display: none;
  }
}
@media only screen and (min-width: 1230px ) {
  .main-nav .nav-search-form .content-wrapper {
    max-width: 1200px;
  }
}
@media (max-width: 991px ) {
  .main-nav {
    height: 60px;
  }
  .main-nav .nav-top-search {
    line-height: 60px;
  }
  .main-nav .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
  .main-nav .nav-hamburger-special-wrapper {
    display: none;
  }
  .main-nav .nav-main-menu.small-screen {
    right: -250px;
  }
  .main-nav.main-menu-opened .nav-main-menu.small-screen {
    right: 0;
  }
  .main-nav.main-menu-opened .nav-hamburger span {
    opacity: 0;
  }
  .main-nav.main-menu-opened .nav-hamburger::before {
    transform: rotate3d(0, 0, 1, 45deg);
  }
  .main-nav.main-menu-opened .nav-hamburger::after {
    transform: rotate3d(0, 0, 1, -45deg);
  }
}
@media (max-width: 767px ) {
  .main-nav .nav-search-form .content-wrapper {
    padding-left: 75px;
    padding-right: 75px;
  }
  .main-nav .nav-search-form .content-wrapper img {
    width: 46px;
  }
  .main-nav .nav-search-form .content-wrapper .content {
    padding-right: 70px;
  }
  .main-nav .nav-search-form .content-wrapper .content input {
    font-size: 14px;
  }
  .main-nav .nav-search-form .content-wrapper .content button {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .main-nav .nav-top-cart-wrapper .nav-top-cart {
    top: -2px;
  }
  .main-nav .nav-search-form .content-wrapper {
    padding-left: 15px;
    padding-right: 60px;
  }
  .main-nav .nav-search-form .content-wrapper img {
    display: none;
  }
  .main-nav .nav-search-form .content-wrapper .content {
    padding-right: 0px;
  }
  .main-nav .nav-search-form .content-wrapper .content input {
    font-size: 14px;
  }
  .main-nav .nav-search-form .content-wrapper .content button {
    font-size: 12px;
    display: none;
  }
}
.main-nav-1 .nav-main-menu,
.main-nav-1 .nav-top-search,
.main-nav-1 .nav-top-cart,
.main-nav-1 .nav-hamburger-wrapper {
  float: right;
}
.main-nav-1 .nav-top-search .search-trigger-show {
  margin-left: 25px;
}
@media only screen and (min-width: 992px ) {
  .main-nav-1 .container {
    position: relative;
  }
  .main-nav-1 .nav-logo.logo-2 {
    display: none;
  }
  .main-nav-1 .nav-top-search {
    transition: all 0.4s ease-in-out;
  }
  .main-nav-1 .nav-main-menu > .sub-menu > ul {
    left: -15px;
  }
  .main-nav-1 .nav-main-menu > .sub-menu.mega-menu > ul,
  .main-nav-1 .nav-main-menu > .sub-menu.menu-tabs > ul {
    left: auto;
    right: 0;
  }
}
@media (max-width: 991px ) {
  .main-nav-1 .nav-logo {
    width: 100px;
    line-height: 60px;
  }
  .main-nav-1:not(.type-3) .nav-logo:not(.logo-2) {
    display: none;
  }
  .main-nav-1 .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-1-1 {
    height: 120px;
  }
}
.main-nav-1.type-1 {
  background-color: #ffffff;
}
.main-nav-1.type-1 .nav-top-search .search-trigger-show {
  margin-left: 25px;
}
.main-nav-1.type-1.style-2 {
  background-color: #eeeeee;
}
@media only screen and (min-width: 992px ) {
  .main-nav-1.type-1 {
    height: 120px;
  }
  .main-nav-1.type-1 .nav-logo {
    line-height: 120px;
    width: 170px;
  }
  .main-nav-1.type-1 .nav-logo img {
    max-height: 90px;
  }
  .main-nav-1.type-1 .nav-top-search {
    line-height: 120px;
  }
  .main-nav-1.type-1 .nav-main-menu > li > a {
    color: #333333;
    line-height: 120px;
  }
  .main-nav-1.type-1 .nav-main-menu > li:hover > a,
  .main-nav-1.type-1 .nav-main-menu > li.current-menu-item > a {
    color: #9c5b57;
  }
}
@media (max-width: 991px ) {
  .main-nav-1.type-1 .nav-logo {
    width: 100px;
  }
  .main-nav-1.type-1 .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-1-2 {
    height: 90px;
  }
}
.main-nav-1.type-2 {
  background-color: #EEE;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}
.main-nav-1.type-2 .nav-top-search .search-trigger-show {
  margin-left: 25px;
}
.main-nav-1.type-2.style-2 {
  background-color: transparent;
  border-color: transparent;
}
@media only screen and (min-width: 992px ) {
  .main-nav-1.type-2 {
    height: 90px;
  }
  .main-nav-1.type-2 .nav-logo {
    line-height: 88px;
    width: 180px;
  }
  .main-nav-1.type-2 .nav-logo img {
    max-height: 80px;
  }
  .main-nav-1.type-2 .nav-top-search {
    line-height: 88px;
  }
  .main-nav-1.type-2 .nav-main-menu > li > a {
    line-height: 88px;
    text-shadow: 0px 0 transparent;
  }
  .main-nav-1.type-2 .nav-main-menu > li:hover > a,
  .main-nav-1.type-2 .nav-main-menu > li.current-menu-item > a {
    text-shadow: 0px 0 #333333;
    color: #333333;
  }
}
@media (max-width: 991px ) {
  .main-nav-1.type-2 .nav-logo {
    width: 100px;
  }
  .main-nav-1.type-2 .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-1-3 {
    height: 90px;
  }
}
.main-nav-1.type-3 {
  background-color: #ffffff;
}
.main-nav-1.type-3 .nav-top-search .search-trigger-show {
  margin-left: 25px;
}
@media only screen and (min-width: 992px ) {
  .main-nav-1.type-3 {
    height: 90px;
  }
  .main-nav-1.type-3 .nav-logo {
    line-height: 90px;
    width: 120px;
  }
  .main-nav-1.type-3 .nav-logo img {
    max-height: 70px;
  }
  .main-nav-1.type-3 .nav-top-search {
    line-height: 90px;
  }
  .main-nav-1.type-3 .nav-main-menu > li > a {
    color: #333333;
    line-height: 90px;
  }
  .main-nav-1.type-3 .nav-main-menu > li:hover > a,
  .main-nav-1.type-3 .nav-main-menu > li.current-menu-item > a {
    color: #9c5b57;
  }
}
@media (max-width: 991px ) {
  .main-nav-1.type-3 .nav-logo {
    width: 100px;
  }
  .main-nav-1.type-3 .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-1-creative {
    height: 70px;
  }
}
.main-nav-1.creative-page {
  background-color: #EEE;
}
.main-nav-1.creative-page .nav-top-search .search-trigger-show {
  margin-left: 25px;
}
.main-nav-1.creative-page .nav-hamburger-special-wrapper {
  float: right;
}
@media only screen and (min-width: 992px ) {
  .main-nav-1.creative-page {
    height: 70px;
  }
  .main-nav-1.creative-page .nav-logo {
    line-height: 70px;
    width: 120px;
  }
  .main-nav-1.creative-page .nav-logo img {
    max-height: 60px;
  }
  .main-nav-1.creative-page .nav-hamburger-special-wrapper {
    margin-left: 50px;
  }
  .main-nav-1.creative-page .nav-content-item {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }
  .main-nav-1.creative-page.nav-content-toggle .nav-hamburger-special span {
    opacity: 0;
  }
  .main-nav-1.creative-page.nav-content-toggle .nav-hamburger-special::before {
    transform: rotate3d(0, 0, 1, 45deg);
  }
  .main-nav-1.creative-page.nav-content-toggle .nav-hamburger-special::after {
    transform: rotate3d(0, 0, 1, -45deg);
  }
  .main-nav-1.creative-page.nav-content-toggle .nav-content-item {
    visibility: visible;
    opacity: 1;
  }
  .main-nav-1.creative-page .nav-top-search {
    line-height: 70px;
  }
  .main-nav-1.creative-page .nav-main-menu > li > a {
    line-height: 70px;
    color: #333333;
  }
  .main-nav-1.creative-page .nav-main-menu > li:hover > a,
  .main-nav-1.creative-page .nav-main-menu > li.current-menu-item > a {
    color: #9c5b57;
  }
}
@media (min-width: 1400px) {
  .main-nav-1.creative-page {
    padding-left: 85px;
    padding-right: 85px;
  }
  .main-nav-1.creative-page .container {
    width: 100%;
  }
  .main-nav-1.creative-page .nav-search-form .content-wrapper {
    max-width: none;
    padding-left: 160px;
    padding-right: 160px;
  }
  .main-nav-1.creative-page .nav-search-form .content-wrapper img {
    left: 100px;
  }
  .main-nav-1.creative-page .nav-search-form .content-wrapper a.search-trigger-hide {
    right: 98px;
  }
}
@media (max-width: 991px ) {
  .main-nav-1.creative-page .nav-logo {
    width: 100px;
  }
  .main-nav-1.creative-page .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-1-onepage {
    height: 100px;
  }
}
.main-nav-1.onepage-page {
  background-color: transparent;
}
.main-nav-1.onepage-page .nav-top-search .search-trigger-show {
  margin-left: 25px;
}
@media only screen and (min-width: 992px ) {
  .main-nav-1.onepage-page {
    height: 100px;
  }
  .main-nav-1.onepage-page .nav-logo {
    line-height: 100px;
    width: 110px;
  }
  .main-nav-1.onepage-page .nav-logo img {
    max-height: 80px;
  }
  .main-nav-1.onepage-page .nav-top-search {
    line-height: 100px;
    color: #ffffff;
  }
  .main-nav-1.onepage-page .nav-main-menu > li > a {
    line-height: 100px;
    text-shadow: 0px 0 transparent;
    color: #DDDDDD;
  }
  .main-nav-1.onepage-page .nav-main-menu > li:hover > a,
  .main-nav-1.onepage-page .nav-main-menu > li.active > a {
    text-shadow: 0px 0 #ffffff;
    color: #ffffff;
  }
  .main-nav-1.onepage-page .nav-main-menu > li.division span {
    height: 16px;
    background-color: #777;
  }
}
@media (max-width: 991px ) {
  .main-nav-1.onepage-page {
    background-color: #EEE;
  }
  .main-nav-1.onepage-page .nav-logo {
    width: 100px;
  }
  .main-nav-1.onepage-page .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
  .main-nav-1.onepage-page .nav-main-menu > li.division {
    display: none;
  }
}
.main-nav-wrapper.nav-wrapper-1-landingpage {
  position: static;
  height: 0;
}
.main-nav-wrapper.nav-wrapper-1-landingpage nav {
  position: absolute;
}
.main-nav.main-nav-1.landing-page {
  background-color: transparent;
}
.main-nav.main-nav-1.landing-page .nav-top-search .search-trigger-show {
  margin-left: 25px;
}
@media only screen and (min-width: 992px ) {
  .main-nav.main-nav-1.landing-page {
    height: 100px;
  }
  .main-nav.main-nav-1.landing-page .nav-logo {
    line-height: 100px;
    width: 110px;
  }
  .main-nav.main-nav-1.landing-page .nav-logo img {
    max-height: 80px;
  }
  .main-nav.main-nav-1.landing-page .nav-top-search {
    line-height: 100px;
    color: #ffffff;
  }
  .main-nav.main-nav-1.landing-page .nav-main-menu > li > a {
    line-height: 100px;
    text-shadow: 0px 0 transparent;
    color: #ffffff;
  }
  .main-nav.main-nav-1.landing-page .nav-main-menu > li:hover > a,
  .main-nav.main-nav-1.landing-page .nav-main-menu > li.active > a {
    text-shadow: 0px 0 #ffffff;
    color: #ffffff;
  }
}
@media (max-width: 991px ) {
  .main-nav.main-nav-1.landing-page {
    background-color: #EEE;
  }
  .main-nav.main-nav-1.landing-page .nav-logo {
    width: 100px;
  }
  .main-nav.main-nav-1.landing-page .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px ) {
  body.slip-control-nav:not(.fp-viewing-sectionHome) .main-nav-wrapper.nav-wrapper-slip {
    display: none;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-slip {
    position: absolute;
    top: 35px;
    height: 100px;
  }
}
.main-nav-1.slip-page {
  background-color: transparent;
}
.main-nav-1.slip-page .container {
  width: 100%;
}
@media only screen and (min-width: 992px ) {
  .main-nav-1.slip-page {
    padding-left: 85px;
    padding-right: 55px;
    height: 100px;
  }
  .main-nav-1.slip-page .nav-logo {
    line-height: 100px;
    width: 150px;
  }
  .main-nav-1.slip-page .nav-main-menu > li > a {
    line-height: 100px;
    color: #333333;
  }
  .main-nav-1.slip-page .nav-main-menu > li:hover > a,
  .main-nav-1.slip-page .nav-main-menu > li.current-menu-item > a {
    color: #9c5b57;
  }
  .main-nav-1.slip-page .nav-main-menu > li.active > a {
    color: #9c5b57;
  }
}
@media (max-width: 991px ) {
  .main-nav-1.slip-page {
    padding-left: 15px;
    padding-right: 15px;
    background-color: #EEE;
  }
  .main-nav-1.slip-page .nav-logo {
    width: 100px;
  }
  .main-nav-1.slip-page .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
  .main-nav-1.slip-page .nav-main-menu > li.division {
    display: none;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-2 {
    height: 60px;
  }
}
.main-nav-2 {
  background-color: #EEE;
}
.main-nav-2 .nav-logo {
  width: 100px;
}
.main-nav-2 .nav-main-menu {
  float: left;
}
.main-nav-2 .nav-top-search,
.main-nav-2 .nav-search-box,
.main-nav-2 .division,
.main-nav-2 .nav-social,
.main-nav-2 .nav-top-cart-wrapper,
.main-nav-2 .nav-hamburger-wrapper {
  float: right;
}
.main-nav-2 .nav-top-search .search-trigger-show {
  margin-left: 25px;
}
.main-nav-2 .division span {
  height: 16px;
  background-color: #DDD;
}
.main-nav-2 .nav-top-cart-wrapper {
  line-height: 60px;
}
.main-nav-2 .nav-search-box {
  line-height: 60px;
}
.main-nav-2 .nav-search-box form {
  width: 200px;
  height: 30px;
  display: inline-block;
}
.main-nav-2 .nav-search-box form *:not(button) {
  line-height: 30px;
}
.main-nav-2 .nav-search-box form .content {
  background-color: #ffffff;
  position: relative;
  height: 100%;
  padding-left: 10px;
  padding-right: 30px;
  border-radius: 15px;
}
.main-nav-2 .nav-search-box form .content input {
  height: 100%;
  padding: 5px;
  width: 100%;
  border: 0;
  background-color: transparent;
}
.main-nav-2 .nav-search-box form .content button {
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  position: absolute;
  height: 28px;
  width: 28px;
  line-height: 28px;
  display: block;
  z-index: 2;
  font-size: 15px;
  text-align: center;
  color: #555555;
  top: 0;
  border: 0;
  border-radius: 50%;
  bottom: 0;
  padding: 0;
  right: 5px;
  margin-top: auto;
  margin-bottom: auto;
}
.main-nav-2 .nav-search-box form .content button:before {
  content: "\e0d9";
  font-family: "wolverine";
}
.main-nav-2 .nav-search-box form .content button:hover {
  color: #9c5b57;
}
.main-nav-2 .nav-social {
  line-height: 60px;
}
.main-nav-2 .nav-social .social-01 {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.main-nav-2 .nav-social .social-01 li {
  display: inline-block;
}
.main-nav-2 .nav-social .social-01 li a {
  display: inline-block;
  color: #777777;
}
.main-nav-2 .nav-social .social-01 li a i:before {
  font-size: 14px;
  margin-right: 0;
  vertical-align: middle;
  font-family: FontAwesome;
}
.main-nav-2 .nav-social .social-01 li:not(:last-of-type) {
  margin-right: 15px;
}
.main-nav-2 .nav-social .social-01 li a:hover {
  color: #9c5b57;
}
@media only screen and (min-width: 992px ) {
  .main-nav-2 {
    height: 60px;
  }
  .main-nav-2 .container {
    position: relative;
  }
  .main-nav-2 .nav-logo,
  .main-nav-2 .nav-top-search {
    display: none;
  }
  .main-nav-2 .nav-main-menu > li > a {
    line-height: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-nav-2 .nav-main-menu > li:first-of-type > a {
    padding-left: 0;
  }
  .main-nav-2 .nav-main-menu > .sub-menu > ul {
    left: -15px;
  }
  .main-nav-2 .nav-main-menu > .sub-menu.mega-menu > ul,
  .main-nav-2 .nav-main-menu > .sub-menu.menu-tabs > ul {
    left: auto;
    right: 0;
  }
  .main-nav-2 .nav-main-menu > .sub-menu._pending {
    position: relative;
  }
  .main-nav-2 .nav-main-menu > .sub-menu._pending > ul {
    left: -15px;
    right: auto;
  }
  .main-nav-2 .nav-search-box {
    margin-left: 15px;
  }
  .main-nav-2 .nav-top-cart-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-nav-2 .nav-social {
    margin-right: 15px;
  }
}
@media only screen and (min-width: 1230px ) {
  .main-nav-2 .nav-search-box {
    margin-left: 20px;
  }
  .main-nav-2 .nav-top-cart-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-nav-2 .nav-social {
    margin-right: 20px;
  }
  .main-nav-2 .nav-main-menu > li > a {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 991px ) {
  .main-nav-2 .nav-logo {
    float: left;
    width: 100px;
    line-height: 60px;
  }
  .main-nav-2 .nav-search-box,
  .main-nav-2 .nav-social,
  .main-nav-2 .division {
    display: none;
  }
  .main-nav-2 .nav-top-cart-wrapper {
    margin-right: 25px;
  }
  .main-nav-2 .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-2.type-1 .nav-main-menu > li > a {
    color: #333333;
  }
  .main-nav-2.type-1 .nav-main-menu > li:hover > a,
  .main-nav-2.type-1 .nav-main-menu > li.current-menu-item > a {
    color: #9c5b57;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-2.type-2 .nav-main-menu > li > a {
    text-shadow: 0px 0 transparent;
    color: #555555;
  }
  .main-nav-2.type-2 .nav-main-menu > li:hover > a,
  .main-nav-2.type-2 .nav-main-menu > li.current-menu-item > a {
    text-shadow: 0px 0 #333333;
    color: #333333;
  }
}
.main-nav-2.type-3 {
  background-color: #ffffff;
  border-bottom: solid 1px #eeeeee;
}
.main-nav-2.type-3 .nav-search-box form .content {
  background-color: #f4f4f4;
}
@media only screen and (min-width: 992px ) {
  .main-nav-2.type-3 .nav-main-menu > li > a {
    color: #333333;
  }
  .main-nav-2.type-3 .nav-main-menu > li:hover > a,
  .main-nav-2.type-3 .nav-main-menu > li.current-menu-item > a {
    color: #9c5b57;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-3 {
    height: 60px;
  }
}
.main-nav-3 .nav-logo {
  width: 100px;
}
.main-nav-3 .nav-content-wrapper {
  transition: all 0.4s ease-in-out;
  height: 100%;
}
.main-nav-3 .nav-content-wrapper .nav-content {
  height: 100%;
}
.main-nav-3 .nav-top-search,
.main-nav-3 .nav-main-menu,
.main-nav-3 .nav-hamburger-wrapper {
  float: right;
}
.main-nav-3 .nav-top-search .search-trigger-show {
  margin-left: 25px;
}
@media only screen and (min-width: 992px ) {
  .main-nav-3 {
    height: 60px;
  }
  .main-nav-3 .nav-logo {
    display: none;
  }
  .main-nav-3 .nav-search-form {
    top: 0;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 940px;
  }
  .main-nav-3 .nav-search-form .content-wrapper {
    max-width: 720px;
  }
  .main-nav-3 .nav-content-wrapper {
    position: relative;
    text-align: center;
  }
  .main-nav-3 .nav-content-wrapper .nav-content {
    display: inline-block;
  }
  .main-nav-3 .nav-top-search {
    line-height: 60px;
  }
  .main-nav-3 .nav-main-menu {
    text-align: left;
  }
  .main-nav-3 .nav-main-menu > li > a {
    line-height: 60px;
    padding-left: 30px;
    padding-right: 30px;
    color: #333333;
  }
  .main-nav-3 .nav-main-menu > li:first-of-type > a {
    padding-left: 0;
  }
  .main-nav-3 .nav-main-menu > li:hover > a,
  .main-nav-3 .nav-main-menu > li.current-menu-item > a {
    color: #9c5b57;
  }
  .main-nav-3 .nav-main-menu > .sub-menu > ul {
    left: -15px;
  }
  .main-nav-3 .nav-main-menu > .sub-menu.mega-menu > ul,
  .main-nav-3 .nav-main-menu > .sub-menu.menu-tabs > ul {
    left: auto;
    right: 0;
  }
  .main-nav-3 .nav-main-menu > .sub-menu._pending {
    position: relative;
  }
  .main-nav-3 .nav-main-menu > .sub-menu._pending > ul {
    left: -15px;
    right: auto;
  }
  .main-nav-3 .nav-main-menu > li.division span {
    background-color: #DDD;
    height: 16px;
  }
}
@media only screen and (min-width: 1230px ) {
  .main-nav-3 .nav-search-form {
    max-width: 1170px;
  }
}
@media (max-width: 991px ) {
  .main-nav-3 {
    background-color: #EEE;
  }
  .main-nav-3 .nav-logo {
    line-height: 60px;
  }
  .main-nav-3 .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-3.type-1 .nav-content-wrapper {
    background-color: #F4F4F4;
  }
}
.main-nav-left-wrapper {
  position: fixed;
  left: 0;
  min-width: 260px;
  overflow: auto;
  top: 0;
  height: 100vh;
  box-shadow: inset -5px 0px 10px -5px rgba(0, 0, 0, 0.3);
}
.nav-main-menu.left-menu {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}
.nav-main-menu.left-menu li {
  display: block;
}
.nav-main-menu.left-menu li a {
  color: #333333;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}
.nav-main-menu.left-menu li:hover > a,
.nav-main-menu.left-menu li.current-menu-item > a {
  color: #9c5b57;
}
.nav-main-menu.left-menu > li > a {
  font-size: 13px;
  font-weight: bold;
  padding-left: 20px;
}
.nav-main-menu.left-menu > li.menu-dropdown > ul > li > a {
  font-size: 12px;
  padding-left: 30px;
}
.nav-main-menu.left-menu > li.menu-dropdown > ul > li.menu-dropdown > ul > li > a {
  font-size: 11px;
  padding-left: 40px;
}
.nav-main-menu.left-menu .menu-dropdown > a {
  position: relative;
  padding-right: 25px;
}
.nav-main-menu.left-menu .menu-dropdown > a:after {
  transition: transform 0.4s ease-in-out;
  position: absolute;
  font-family: "wolverine";
  content: "\e07f";
  top: 0;
  right: 10px;
  bottom: 0;
  font-size: 15px;
  height: 15px;
  width: 15px;
  line-height: 1;
  margin-top: auto;
  margin-bottom: auto;
  transform: rotate(0deg);
}
.nav-main-menu.left-menu .menu-dropdown > a.menu-trigger:after {
  transform: rotate(90deg);
}
.nav-main-menu.left-menu .menu-dropdown > a:hover {
  color: #9c5b57;
}
.main-nav-left {
  padding-top: 90px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.main-nav-left:after {
  clear: both;
}
.main-nav-left:before,
.main-nav-left:after {
  content: " ";
  display: table;
}
.main-nav-left:after {
  clear: both;
}
.main-nav-left:before,
.main-nav-left:after {
  content: " ";
  display: table;
}
.main-nav-left .nav-logo {
  margin-bottom: 70px;
  width: 100%;
}
.main-nav-left .nav-logo img {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.main-nav-left .nav-main-menu {
  margin-bottom: 12px;
}
.main-nav-left .nav-search-box {
  margin-bottom: 40px;
  width: 100%;
}
.main-nav-left .nav-search-box form .content {
  width: 210px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-right: 30px;
  border-bottom: 1px solid #DDDDDD;
}
.main-nav-left .nav-search-box form .content input {
  padding: 10px;
  width: 100%;
  border: 0;
  background-color: transparent;
}
.main-nav-left .nav-search-box form .content button {
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  position: absolute;
  height: 28px;
  width: 28px;
  line-height: 28px;
  display: block;
  z-index: 2;
  font-size: 17px;
  text-align: center;
  color: #777777;
  top: 0;
  border: 0;
  border-radius: 50%;
  bottom: 0;
  padding: 0;
  right: 5px;
  margin-top: auto;
  margin-bottom: auto;
}
.main-nav-left .nav-search-box form .content button:before {
  content: "\e0d9";
  font-family: "wolverine";
}
.main-nav-left .nav-search-box form .content button:hover {
  color: #9c5b57;
}
.main-nav-left .nav-social {
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
}
.main-nav-left .nav-social .social-01 {
  display: inline-block;
  line-height: 1;
}
.main-nav-left .nav-social .social-01 li {
  display: inline-block;
}
.main-nav-left .nav-social .social-01 li a {
  display: inline-block;
  color: #ffffff;
}
.main-nav-left .nav-social .social-01 li a i:before {
  font-size: 14px;
  margin-right: 0;
  vertical-align: middle;
  font-family: FontAwesome;
}
.main-nav-left .nav-social .social-01 li:not(:last-of-type) {
  margin-right: 15px;
}
.main-nav-left .nav-social .social-01 li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #DDDDDD;
  text-align: center;
}
.main-nav-left .nav-social .social-01 li a:hover {
  background-color: #9c5b57;
}
@media only screen and (min-width: 992px ) {
  .main-nav-left .container {
    position: relative;
  }
}
@media (max-width: 991px ) {
  .main-nav-left {
    background-color: #EEE;
  }
  .main-nav-left .nav-logo {
    line-height: 60px;
  }
  .main-nav-left .nav-top-search .search-trigger-show {
    margin-left: 15px;
  }
}
body.left-menu .main-nav-wrapper {
  display: none;
}
@media (min-width: 1300px) {
  body.left-menu {
    padding-left: 270px;
  }
  body.left-menu .main-nav-left-wrapper {
    width: 270px;
  }
  body.left-menu .container {
    max-width: 1200px;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  body.left-menu {
    padding-left: 320px;
  }
  body.left-menu .main-nav-left-wrapper {
    width: 320px;
  }
}
@media (max-width: 1299px) {
  body.left-menu .main-nav-left-wrapper {
    display: none;
  }
  body.left-menu .main-nav-wrapper {
    display: block;
  }
}
@media (max-width: 767px ) {
  .before-main-nav,
  .after-main-nav {
    display: none;
  }
}
.before-main-nav-2-b1 {
  padding-top: 35px;
  padding-bottom: 40px;
}
.before-main-nav-2-b1 img {
  height: 36px;
}
.before-main-nav-3-b1,
.before-main-nav-3-b2 {
  padding-top: 35px;
  padding-bottom: 40px;
}
.before-main-nav-3-b1 img,
.before-main-nav-3-b2 img {
  height: 110px;
}
.after-main-nav-2-a1 {
  padding-top: 65px;
  padding-bottom: 80px;
}
.after-main-nav-2-a1 img {
  height: 110px;
  margin-bottom: 20px;
}
.after-main-nav-2-a1 .caption {
  font-size: 18px;
}
.after-main-nav-2-a1 .caption:before,
.after-main-nav-2-a1 .caption:after {
  background-color: #9c5b57;
  height: 6px;
  width: 6px;
  content: "";
  display: inline-block;
  border-radius: 3px;
  margin: 5px;
}
.after-main-nav-2-a2 {
  padding-top: 65px;
  padding-bottom: 65px;
}
.after-main-nav-2-a2 img {
  height: 168px;
}
.after-main-nav-2-a3 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.after-main-nav-2-a3 img {
  height: 20px;
}
/* Header */
header:after {
  clear: both;
}
header:before,
header:after {
  content: " ";
  display: table;
}
header:after {
  clear: both;
}
header:before,
header:after {
  content: " ";
  display: table;
}
header.site-header {
  position: relative;
}
.common-static-header,
.portfolio-page-header {
  position: relative;
  padding: 90px 0;
}
.common-static-header .parallax-bg,
.portfolio-page-header .parallax-bg {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: repeat-x;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: repeat-y;
}
.common-static-header .parallax-bg:after,
.portfolio-page-header .parallax-bg:after {
  background-color: rgba(51, 51, 51, 0.3);
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.common-static-header h1,
.portfolio-page-header h1 {
  color: #ffffff;
  margin-bottom: 0;
}
.portfolio-page-header {
  padding: 120px 0;
}
.homepage-blog-header {
  padding-top: 60px;
}
.homepage-classic-header {
  padding: 50px 0;
}
.homepage-classic-2-header {
  padding-bottom: 25px;
}
/* Home */
.caption-slider .caption-hide {
  opacity: 0;
  visibility: hidden;
}
.home-boxed-slider .slick-dots {
  bottom: 20px;
  width: 100%;
}
.home-boxed-slider .slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 8px;
  padding: 0;
  width: 8px;
}
.home-boxed-slider .slick-dots li:not(:last-of-type) {
  margin-right: 10px;
}
.home-boxed-slider .slick-dots li button:before {
  border-radius: 50%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.4s ease-in-out;
}
.home-boxed-slider .slick-next {
  right: 30px;
  text-align: right;
}
.home-boxed-slider .slick-next:before {
  content: "\e07e";
  margin-right: 7px;
}
.home-boxed-slider .slick-prev {
  left: 30px;
  text-align: left;
}
.home-boxed-slider .slick-prev:before {
  content: "\e031";
  margin-left: 7px;
}
.home-boxed-slider .slick-prev,
.home-boxed-slider .slick-next {
  border: 2px solid;
  border-radius: 50%;
  height: 40px;
  margin-top: -20px;
  opacity: 0.6;
  top: 50%;
  width: 40px;
  z-index: 10;
  transition: all 0.4s ease-in-out;
}
.home-boxed-slider .slick-prev:before,
.home-boxed-slider .slick-next:before {
  font-size: 19px;
  font-family: "wolverine";
}
.home-boxed-slider .slick-prev:hover,
.home-boxed-slider .slick-next:hover {
  opacity: 1;
}
.home-boxed-slider.style-1 .item-image {
  position: relative;
}
.home-boxed-slider.style-1 .item-image .caption-wrapper img {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}
.home-boxed-slider.style-1 .item-image .caption-wrapper p {
  margin-bottom: 0;
  margin-top: 28px;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
}
.home-boxed-slider.style-1 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.5);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-boxed-slider.style-1 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-boxed-slider.style-1 .slick-dots li button:before {
  background-color: #999999;
  opacity: 0.3;
}
.home-boxed-slider.style-1 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 600px) {
  .home-boxed-slider.style-1 .item-image .caption-wrapper img {
    width: 150px;
  }
}
@media (max-width: 500px) {
  .home-boxed-slider.style-1 .slick-dots {
    visibility: hidden;
  }
}
.home-boxed-slider.style-2 .item-image {
  position: relative;
}
.home-boxed-slider.style-2 .item-image .caption-wrapper {
  background-color: rgba(51, 51, 51, 0.7);
  margin-left: auto;
  margin-right: auto;
  padding: 60px 30px 65px;
  width: 470px;
}
.home-boxed-slider.style-2 .item-image .caption-wrapper .heading-icon-1 {
  transition: all 0.4s ease-in-out;
}
.home-boxed-slider.style-2 .item-image .caption-wrapper .heading-icon-1:before {
  color: #ffffff;
  content: "\e2d0";
  display: block;
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.home-boxed-slider.style-2 .item-image .caption-wrapper h4 {
  font-size: 16px;
  letter-spacing: 3px;
  margin-bottom: 0;
  color: #ffffff;
}
.home-boxed-slider.style-2 .item-image .caption-wrapper p {
  margin-bottom: 15px;
}
.home-boxed-slider.style-2 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.2);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-boxed-slider.style-2 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-boxed-slider.style-2 .slick-dots li button:before {
  background-color: #999999;
  opacity: 0.3;
}
.home-boxed-slider.style-2 .slick-dots li button:hover:before {
  opacity: 1;
}
.home-boxed-slider.style-2 .slick-prev,
.home-boxed-slider.style-2 .slick-next {
  border-color: #ffffff;
}
.home-boxed-slider.style-2 .slick-prev:before,
.home-boxed-slider.style-2 .slick-next:before {
  color: #ffffff;
}
@media (max-width: 767px ) {
  .home-boxed-slider.style-2 .item-image .caption-wrapper {
    background-color: transparent;
    padding: 0 60px;
    width: 100%;
  }
}
@media (max-width: 500px) {
  .home-boxed-slider.style-2 .item-image .caption-wrapper {
    padding: 0 20px;
  }
  .home-boxed-slider.style-2 .item-image .caption-wrapper p {
    display: none;
  }
  .home-boxed-slider.style-2 .slick-dots {
    visibility: hidden;
  }
  .home-boxed-slider.style-2 .slick-prev,
  .home-boxed-slider.style-2 .slick-next {
    visibility: hidden;
  }
}
.home-boxed-slider.style-3 .item-image {
  position: relative;
}
.home-boxed-slider.style-3 .item-image .caption-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  margin-left: auto;
  margin-right: auto;
  padding: 60px 30px 65px;
  width: 470px;
}
.home-boxed-slider.style-3 .item-image .caption-wrapper p {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.home-boxed-slider.style-3 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.2);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-boxed-slider.style-3 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-boxed-slider.style-3 .slick-dots li button:before {
  background-color: #999999;
  opacity: 0.3;
}
.home-boxed-slider.style-3 .slick-dots li button:hover:before {
  opacity: 1;
}
.home-boxed-slider.style-3 .slick-prev,
.home-boxed-slider.style-3 .slick-next {
  border-color: #ffffff;
}
.home-boxed-slider.style-3 .slick-prev:before,
.home-boxed-slider.style-3 .slick-next:before {
  color: #ffffff;
}
@media (max-width: 767px ) {
  .home-boxed-slider.style-3 .item-image .caption-wrapper {
    background-color: transparent;
    padding: 0 60px;
    width: 100%;
  }
  .home-boxed-slider.style-3 .item-image .caption-wrapper .heading-icon-1:before {
    color: #ffffff;
  }
  .home-boxed-slider.style-3 .item-image .caption-wrapper p {
    letter-spacing: 3px;
    color: #ffffff;
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .home-boxed-slider.style-3 .item-image .caption-wrapper {
    padding: 0 20px;
  }
  .home-boxed-slider.style-3 .item-image .caption-wrapper .heading-icon-1 {
    display: none;
  }
  .home-boxed-slider.style-3 .slick-dots {
    visibility: hidden;
  }
  .home-boxed-slider.style-3 .slick-prev,
  .home-boxed-slider.style-3 .slick-next {
    visibility: hidden;
  }
}
.home-boxed-slider.style-4 .item-image {
  position: relative;
}
.home-boxed-slider.style-4 .item-image .caption-wrapper h4 {
  letter-spacing: 5px;
  margin-bottom: 0;
  margin-top: 45px;
  color: #ffffff;
}
.home-boxed-slider.style-4 .item-image .caption-wrapper img {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}
.home-boxed-slider.style-4 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.5);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-boxed-slider.style-4 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-boxed-slider.style-4 .slick-dots li button:before {
  background-color: #999999;
  opacity: 0.3;
}
.home-boxed-slider.style-4 .slick-dots li button:hover:before {
  opacity: 1;
}
.home-boxed-slider.style-4 .slick-prev,
.home-boxed-slider.style-4 .slick-next {
  border-color: #ffffff;
}
.home-boxed-slider.style-4 .slick-prev:before,
.home-boxed-slider.style-4 .slick-next:before {
  color: #ffffff;
}
@media (max-width: 600px) {
  .home-boxed-slider.style-4 .item-image .caption-wrapper img {
    width: 140px;
  }
}
@media (max-width: 500px) {
  .home-boxed-slider.style-4 .slick-dots {
    visibility: hidden;
  }
  .home-boxed-slider.style-4 .slick-prev,
  .home-boxed-slider.style-4 .slick-next {
    visibility: hidden;
  }
}
.home-boxed-slider.style-5 .item-image {
  position: relative;
}
.home-boxed-slider.style-5 .item-image .caption-wrapper .heading-icon-1 {
  transition: all 0.4s ease-in-out;
}
.home-boxed-slider.style-5 .item-image .caption-wrapper .heading-icon-1:before {
  color: #ffffff;
  content: "\e2d0";
  display: block;
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.home-boxed-slider.style-5 .item-image .caption-wrapper p {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #ffffff;
}
.home-boxed-slider.style-5 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.5);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-boxed-slider.style-5 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-boxed-slider.style-5 .slick-dots li button:before {
  background-color: #ffffff;
  opacity: 0.3;
}
.home-boxed-slider.style-5 .slick-dots li button:hover:before {
  opacity: 1;
}
.home-boxed-slider.style-5 .slick-prev,
.home-boxed-slider.style-5 .slick-next {
  border-color: #ffffff;
}
.home-boxed-slider.style-5 .slick-prev:before,
.home-boxed-slider.style-5 .slick-next:before {
  color: #ffffff;
}
@media (max-width: 500px) {
  .home-boxed-slider.style-5 .item-image .caption-wrapper {
    padding: 0 20px;
  }
  .home-boxed-slider.style-5 .item-image .caption-wrapper .heading-icon-1 {
    display: none;
  }
  .home-boxed-slider.style-5 .slick-dots {
    visibility: hidden;
  }
  .home-boxed-slider.style-5 .slick-prev,
  .home-boxed-slider.style-5 .slick-next {
    visibility: hidden;
  }
}
.home-fw-slider .slick-dots li {
  cursor: pointer;
  height: 8px;
  padding: 0;
  width: 8px;
}
.home-fw-slider .slick-dots li button:before {
  border-radius: 50%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.4s ease-in-out;
}
.home-fw-slider .slick-next {
  right: 30px;
  text-align: right;
}
.home-fw-slider .slick-next:before {
  content: "\e07e";
  margin-right: 7px;
}
.home-fw-slider .slick-prev {
  left: 30px;
  text-align: left;
}
.home-fw-slider .slick-prev:before {
  content: "\e031";
  margin-left: 7px;
}
.home-fw-slider .slick-prev,
.home-fw-slider .slick-next {
  border: 2px solid;
  border-radius: 50%;
  height: 40px;
  margin-top: -20px;
  opacity: 0.6;
  top: 50%;
  width: 40px;
  z-index: 10;
  transition: all 0.4s ease-in-out;
}
.home-fw-slider .slick-prev:before,
.home-fw-slider .slick-next:before {
  font-size: 19px;
  font-family: "wolverine";
}
.home-fw-slider .slick-prev:hover,
.home-fw-slider .slick-next:hover {
  opacity: 1;
}
.home-fw-slider .slick-prev,
.home-fw-slider .slick-next {
  border-color: #ffffff;
}
.home-fw-slider .slick-prev:before,
.home-fw-slider .slick-next:before {
  color: #ffffff;
}
.home-fw-slider.style-1 .item-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 590px;
  position: relative;
}
.home-fw-slider.style-1 .item-image .caption-wrapper img {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}
.home-fw-slider.style-1 .item-image .caption-wrapper p {
  font-size: 21px;
  margin-bottom: 0;
  margin-top: 38px;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-1 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.3);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-fw-slider.style-1 .slick-dots {
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.home-fw-slider.style-1 .slick-dots li {
  display: block;
}
.home-fw-slider.style-1 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-fw-slider.style-1 .slick-dots li:not(:last-of-type) {
  margin-bottom: 15px;
}
.home-fw-slider.style-1 .slick-dots li button:before {
  background-color: #ffffff;
  opacity: 0.3;
}
.home-fw-slider.style-1 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 767px ) {
  .home-fw-slider.style-1 .item-image {
    min-height: 500px;
  }
  .home-fw-slider.style-1 .item-image .caption-wrapper p {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .home-fw-slider.style-1 .item-image {
    max-height: 650px;
    min-height: 470px;
  }
  .home-fw-slider.style-1 .item-image .caption-wrapper img {
    width: 250px;
  }
  .home-fw-slider.style-1 .item-image .caption-wrapper p {
    display: none;
  }
  .home-fw-slider.style-1 .slick-dots {
    visibility: hidden;
  }
}
.home-get-start {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60px;
  margin: auto;
  text-align: center;
  width: 150px;
  z-index: 514;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
}
.home-get-start a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  width: 40px;
}
.home-get-start a:before {
  font-size: 30px;
  vertical-align: middle;
}
.home-get-start a:hover:before {
  color: #333333;
  transition: all 0.4s ease-in-out;
}
.home-fw-slider.style-2 .item-image {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 87vh;
  min-height: 590px;
  position: relative;
}
.home-fw-slider.style-2 .item-image .caption-wrapper img {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}
.home-fw-slider.style-2 .item-image .caption-wrapper p {
  font-size: 18px;
  letter-spacing: 3px;
  line-height: 1.6;
  margin-bottom: 0;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-2 .item-image .heading-icon-1 {
  transition: all 0.4s ease-in-out;
}
.home-fw-slider.style-2 .item-image .heading-icon-1:before {
  color: #ffffff;
  content: "\e2d0";
  display: block;
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.home-fw-slider.style-2 .item-image .overlay {
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-fw-slider.style-2 .item-image .overlay .cell-vertical-wrapper {
  max-height: 480px;
}
.home-fw-slider.style-2 .slick-dots {
  bottom: 50px;
  width: 100%;
}
.home-fw-slider.style-2 .slick-dots li {
  display: inline-block;
}
.home-fw-slider.style-2 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-fw-slider.style-2 .slick-dots li:not(:last-of-type) {
  margin-right: 10px;
}
.home-fw-slider.style-2 .slick-dots li button:before {
  background-color: #333333;
  opacity: 0.5;
}
.home-fw-slider.style-2 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 767px ) {
  .home-fw-slider.style-2 .item-image {
    min-height: 500px;
  }
  .home-fw-slider.style-2 .item-image .caption-wrapper p {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .home-fw-slider.style-2 .item-image {
    max-height: 650px;
    min-height: 470px;
  }
  .home-fw-slider.style-2 .slick-dots {
    visibility: hidden;
  }
}
.home-fw-slider.style-3 .item-image {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 590px;
  position: relative;
}
.home-fw-slider.style-3 .item-image .caption-wrapper .heading-icon-1 {
  margin-bottom: 10px;
  transition: all 0.4s ease-in-out;
}
.home-fw-slider.style-3 .item-image .caption-wrapper .heading-icon-1:before {
  color: #ffffff;
  content: "\e2d0";
  display: block;
  font-size: 120px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.home-fw-slider.style-3 .item-image .caption-wrapper h1 {
  font-size: 72px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  color: #ffffff;
}
.home-fw-slider.style-3 .item-image .caption-wrapper p {
  font-size: 23px;
  letter-spacing: 3px;
  line-height: 1.6;
  margin-bottom: 0;
  margin-top: 35px;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-3 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.3);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-fw-slider.style-3 .slick-dots {
  bottom: 20px;
  width: 100%;
}
.home-fw-slider.style-3 .slick-dots li {
  display: inline-block;
}
.home-fw-slider.style-3 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-fw-slider.style-3 .slick-dots li:not(:last-of-type) {
  margin-right: 10px;
}
.home-fw-slider.style-3 .slick-dots li button:before {
  background-color: #333333;
  opacity: 0.5;
}
.home-fw-slider.style-3 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 991px ) {
  .home-fw-slider.style-3 .item-image .caption-wrapper h1 {
    font-size: 50px;
  }
  .home-fw-slider.style-3 .item-image .caption-wrapper p {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media (max-width: 767px ) {
  .home-fw-slider.style-3 .item-image {
    min-height: 500px;
  }
  .home-fw-slider.style-3 .item-image .caption-wrapper h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .home-fw-slider.style-3 .item-image {
    max-height: 650px;
    min-height: 470px;
  }
  .home-fw-slider.style-3 .item-image .caption-wrapper .heading-icon-1:before {
    font-size: 70px;
  }
  .home-fw-slider.style-3 .item-image .caption-wrapper h1 {
    font-size: 30px;
  }
  .home-fw-slider.style-3 .item-image .caption-wrapper p {
    display: none;
  }
  .home-fw-slider.style-3 .slick-dots {
    visibility: hidden;
  }
  .home-fw-slider.style-3 .slick-prev,
  .home-fw-slider.style-3 .slick-next {
    visibility: hidden;
  }
}
.home-fw-slider.style-4 .item-image {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 590px;
  position: relative;
}
.home-fw-slider.style-4 .item-image .caption-wrapper h1 {
  font-size: 72px;
  line-height: 1.2;
  margin-bottom: 0px;
  font-weight: 700;
  color: #ffffff;
}
.home-fw-slider.style-4 .item-image .caption-wrapper p {
  font-size: 23px;
  letter-spacing: 3px;
  line-height: 1.6;
  margin-bottom: 35px;
  margin-top: 5px;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-4 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.3);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-fw-slider.style-4 .slick-dots {
  bottom: 20px;
  width: 100%;
}
.home-fw-slider.style-4 .slick-dots li {
  display: inline-block;
}
.home-fw-slider.style-4 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-fw-slider.style-4 .slick-dots li:not(:last-of-type) {
  margin-right: 10px;
}
.home-fw-slider.style-4 .slick-dots li button:before {
  background-color: #333333;
  opacity: 0.5;
}
.home-fw-slider.style-4 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 991px ) {
  .home-fw-slider.style-4 .item-image .caption-wrapper {
    text-align: center;
  }
  .home-fw-slider.style-4 .item-image .caption-wrapper h1 {
    font-size: 50px;
  }
  .home-fw-slider.style-4 .item-image .caption-wrapper p {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media (max-width: 767px ) {
  .home-fw-slider.style-4 .item-image {
    min-height: 500px;
  }
  .home-fw-slider.style-4 .item-image .caption-wrapper h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .home-fw-slider.style-4 .item-image {
    max-height: 650px;
    min-height: 470px;
  }
  .home-fw-slider.style-4 .item-image .caption-wrapper h1 {
    font-size: 30px;
  }
  .home-fw-slider.style-4 .item-image .caption-wrapper p {
    display: none;
  }
  .home-fw-slider.style-4 .slick-dots {
    visibility: hidden;
  }
  .home-fw-slider.style-4 .slick-prev,
  .home-fw-slider.style-4 .slick-next {
    visibility: hidden;
  }
}
.home-fw-slider.style-5 .item-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 590px;
  position: relative;
}
.home-fw-slider.style-5 .item-image .caption-wrapper h1 {
  font-size: 92px;
  letter-spacing: 10px;
  line-height: 1.2;
  margin-bottom: 0;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-5 .item-image .caption-wrapper p {
  font-size: 36px;
  line-height: 1.6;
  margin-bottom: 0;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-5 .item-image .overlay {
  background-color: rgba(244, 244, 244, 0.2);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-fw-slider.style-5 .slick-dots {
  bottom: 20px;
  width: 100%;
}
.home-fw-slider.style-5 .slick-dots li {
  display: inline-block;
}
.home-fw-slider.style-5 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-fw-slider.style-5 .slick-dots li:not(:last-of-type) {
  margin-right: 10px;
}
.home-fw-slider.style-5 .slick-dots li button:before {
  background-color: #333333;
  opacity: 0.5;
}
.home-fw-slider.style-5 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 991px ) {
  .home-fw-slider.style-5 .item-image .caption-wrapper {
    text-align: center;
  }
  .home-fw-slider.style-5 .item-image .caption-wrapper h1 {
    font-size: 70px;
  }
  .home-fw-slider.style-5 .item-image .caption-wrapper p {
    font-size: 28px;
    margin-top: 20px;
  }
}
@media (max-width: 767px ) {
  .home-fw-slider.style-5 .item-image {
    min-height: 500px;
  }
  .home-fw-slider.style-5 .item-image .caption-wrapper h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .home-fw-slider.style-5 .item-image {
    max-height: 650px;
    min-height: 470px;
  }
  .home-fw-slider.style-5 .item-image .caption-wrapper h1 {
    font-size: 30px;
  }
  .home-fw-slider.style-5 .slick-dots {
    visibility: hidden;
  }
  .home-fw-slider.style-5 .slick-prev,
  .home-fw-slider.style-5 .slick-next {
    visibility: hidden;
  }
}
.home-fw-slider.style-6 .item-image {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 590px;
  position: relative;
}
.home-fw-slider.style-6 .item-image .caption-wrapper .heading-icon-1 {
  margin-bottom: 10px;
  transition: all 0.4s ease-in-out;
}
.home-fw-slider.style-6 .item-image .caption-wrapper .heading-icon-1:before {
  color: #ffffff;
  content: "\e2d5";
  display: block;
  font-size: 120px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.home-fw-slider.style-6 .item-image .caption-wrapper h1 {
  font-size: 72px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  color: #ffffff;
}
.home-fw-slider.style-6 .item-image .caption-wrapper p {
  font-size: 23px;
  letter-spacing: 3px;
  line-height: 1.6;
  margin-bottom: 45px;
  margin-top: 35px;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-6 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.5);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-fw-slider.style-6 .slick-dots {
  bottom: 20px;
  width: 100%;
}
.home-fw-slider.style-6 .slick-dots li {
  display: inline-block;
}
.home-fw-slider.style-6 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-fw-slider.style-6 .slick-dots li:not(:last-of-type) {
  margin-right: 10px;
}
.home-fw-slider.style-6 .slick-dots li button:before {
  background-color: #333333;
  opacity: 0.5;
}
.home-fw-slider.style-6 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 991px ) {
  .home-fw-slider.style-6 .item-image .caption-wrapper h1 {
    font-size: 50px;
  }
  .home-fw-slider.style-6 .item-image .caption-wrapper p {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media (max-width: 767px ) {
  .home-fw-slider.style-6 .item-image {
    min-height: 500px;
  }
  .home-fw-slider.style-6 .item-image .caption-wrapper h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .home-fw-slider.style-6 .item-image {
    max-height: 650px;
    min-height: 470px;
  }
  .home-fw-slider.style-6 .item-image .caption-wrapper .heading-icon-1:before {
    font-size: 70px;
  }
  .home-fw-slider.style-6 .item-image .caption-wrapper h1 {
    font-size: 30px;
  }
  .home-fw-slider.style-6 .item-image .caption-wrapper p {
    display: none;
  }
  .home-fw-slider.style-6 .slick-dots {
    visibility: hidden;
  }
  .home-fw-slider.style-6 .slick-prev,
  .home-fw-slider.style-6 .slick-next {
    visibility: hidden;
  }
}
.home-fw-slider.style-7 .item-image {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 590px;
  position: relative;
}
.home-fw-slider.style-7 .item-image .caption-wrapper .heading-icon-1 {
  margin-bottom: 30px;
  transition: all 0.4s ease-in-out;
}
.home-fw-slider.style-7 .item-image .caption-wrapper .heading-icon-1:before {
  color: #ffffff;
  content: "\e2da";
  display: block;
  font-size: 120px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.home-fw-slider.style-7 .item-image .caption-wrapper h1 {
  font-size: 72px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  color: #ffffff;
}
.home-fw-slider.style-7 .item-image .caption-wrapper p {
  font-size: 23px;
  letter-spacing: 3px;
  line-height: 1.6;
  margin-bottom: 45px;
  margin-top: 35px;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-7 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.65);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-fw-slider.style-7 .slick-dots {
  bottom: 20px;
  width: 100%;
}
.home-fw-slider.style-7 .slick-dots li {
  display: inline-block;
}
.home-fw-slider.style-7 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-fw-slider.style-7 .slick-dots li:not(:last-of-type) {
  margin-right: 10px;
}
.home-fw-slider.style-7 .slick-dots li button:before {
  background-color: #333333;
  opacity: 0.5;
}
.home-fw-slider.style-7 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 991px ) {
  .home-fw-slider.style-7 .item-image .caption-wrapper h1 {
    font-size: 50px;
  }
  .home-fw-slider.style-7 .item-image .caption-wrapper p {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media (max-width: 767px ) {
  .home-fw-slider.style-7 .item-image {
    min-height: 500px;
  }
  .home-fw-slider.style-7 .item-image .caption-wrapper h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .home-fw-slider.style-7 .item-image {
    max-height: 650px;
    min-height: 470px;
  }
  .home-fw-slider.style-7 .item-image .caption-wrapper .heading-icon-1:before {
    font-size: 70px;
  }
  .home-fw-slider.style-7 .item-image .caption-wrapper h1 {
    font-size: 30px;
  }
  .home-fw-slider.style-7 .item-image .caption-wrapper p {
    display: none;
  }
  .home-fw-slider.style-7 .slick-dots {
    visibility: hidden;
  }
  .home-fw-slider.style-7 .slick-prev,
  .home-fw-slider.style-7 .slick-next {
    visibility: hidden;
  }
}
.home-fw-slider.style-8 .item-image {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  min-height: 590px;
  position: relative;
}
.home-fw-slider.style-8 .item-image .caption-wrapper .heading-icon-1 {
  margin-bottom: 10px;
  transition: all 0.4s ease-in-out;
}
.home-fw-slider.style-8 .item-image .caption-wrapper .heading-icon-1:before {
  color: #ffffff;
  content: "\e2d1";
  display: block;
  font-size: 120px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.home-fw-slider.style-8 .item-image .caption-wrapper h1 {
  font-size: 72px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  color: #ffffff;
}
.home-fw-slider.style-8 .item-image .caption-wrapper p {
  font-size: 23px;
  letter-spacing: 3px;
  line-height: 1.6;
  margin-bottom: 45px;
  margin-top: 35px;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-8 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.65);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-fw-slider.style-8 .slick-dots {
  bottom: 20px;
  width: 100%;
}
.home-fw-slider.style-8 .slick-dots li {
  display: inline-block;
}
.home-fw-slider.style-8 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-fw-slider.style-8 .slick-dots li:not(:last-of-type) {
  margin-right: 10px;
}
.home-fw-slider.style-8 .slick-dots li button:before {
  background-color: #333333;
  opacity: 0.5;
}
.home-fw-slider.style-8 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 991px ) {
  .home-fw-slider.style-8 .item-image .caption-wrapper h1 {
    font-size: 50px;
  }
  .home-fw-slider.style-8 .item-image .caption-wrapper p {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media (max-width: 767px ) {
  .home-fw-slider.style-8 .item-image {
    min-height: 500px;
  }
  .home-fw-slider.style-8 .item-image .caption-wrapper h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .home-fw-slider.style-8 .item-image {
    max-height: 650px;
    min-height: 470px;
  }
  .home-fw-slider.style-8 .item-image .caption-wrapper .heading-icon-1:before {
    font-size: 70px;
  }
  .home-fw-slider.style-8 .item-image .caption-wrapper h1 {
    font-size: 30px;
  }
  .home-fw-slider.style-8 .item-image .caption-wrapper p {
    display: none;
  }
  .home-fw-slider.style-8 .slick-dots {
    visibility: hidden;
  }
  .home-fw-slider.style-8 .slick-prev,
  .home-fw-slider.style-8 .slick-next {
    visibility: hidden;
  }
}
.home-fw-slider.style-9 .item-image {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 590px;
  position: relative;
}
.home-fw-slider.style-9 .item-image .caption-wrapper .heading-icon-1 {
  margin-bottom: 10px;
  transition: all 0.4s ease-in-out;
}
.home-fw-slider.style-9 .item-image .caption-wrapper .heading-icon-1:before {
  color: #ffffff;
  content: "\e2d1";
  display: block;
  font-size: 120px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.home-fw-slider.style-9 .item-image .caption-wrapper h1 {
  font-size: 72px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0;
  color: #ffffff;
}
.home-fw-slider.style-9 .item-image .caption-wrapper p {
  font-size: 23px;
  letter-spacing: 3px;
  line-height: 1.6;
  margin-bottom: 45px;
  margin-top: 35px;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.home-fw-slider.style-9 .item-image .overlay {
  background-color: rgba(51, 51, 51, 0.5);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.home-fw-slider.style-9 .slick-dots {
  bottom: 20px;
  width: 100%;
}
.home-fw-slider.style-9 .slick-dots li {
  display: inline-block;
}
.home-fw-slider.style-9 .slick-dots li.slick-active button:before {
  opacity: 1;
}
.home-fw-slider.style-9 .slick-dots li:not(:last-of-type) {
  margin-right: 10px;
}
.home-fw-slider.style-9 .slick-dots li button:before {
  background-color: #333333;
  opacity: 0.5;
}
.home-fw-slider.style-9 .slick-dots li button:hover:before {
  opacity: 1;
}
@media (max-width: 991px ) {
  .home-fw-slider.style-9 .item-image .caption-wrapper h1 {
    font-size: 50px;
  }
  .home-fw-slider.style-9 .item-image .caption-wrapper p {
    font-size: 16px;
    margin-top: 20px;
  }
}
@media (max-width: 767px ) {
  .home-fw-slider.style-9 .item-image {
    min-height: 500px;
  }
  .home-fw-slider.style-9 .item-image .caption-wrapper h1 {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .home-fw-slider.style-9 .item-image {
    max-height: 650px;
    min-height: 470px;
  }
  .home-fw-slider.style-9 .item-image .caption-wrapper .heading-icon-1:before {
    font-size: 70px;
  }
  .home-fw-slider.style-9 .item-image .caption-wrapper h1 {
    font-size: 30px;
  }
  .home-fw-slider.style-9 .item-image .caption-wrapper p {
    display: none;
  }
  .home-fw-slider.style-9 .slick-dots {
    visibility: hidden;
  }
  .home-fw-slider.style-9 .slick-prev,
  .home-fw-slider.style-9 .slick-next {
    visibility: hidden;
  }
}
/* Navigator And Home (Combine) */
body.onepage-page .main-nav-wrapper.nav-wrapper-1-onepage {
  position: static;
  height: 0;
}
body.onepage-page .main-nav-wrapper.nav-wrapper-1-onepage nav {
  position: absolute;
}
@media (max-width: 991px ) {
  body.onepage-page header.site-header {
    padding-top: 60px;
    height: 100vh;
  }
  body.onepage-page header.site-header .home-fw-slider.style-1 {
    height: 100%;
  }
}
@media (max-width: 500px) {
  body.onepage-page header.site-header {
    max-height: 650px;
  }
}
body.corporate-page .main-nav-wrapper.nav-wrapper-1-1 {
  position: static;
  height: 0;
}
body.corporate-page .main-nav-wrapper.nav-wrapper-1-1 nav {
  position: absolute;
}
body.corporate-page header.site-header {
  padding-top: 120px;
  height: 100vh;
  max-height: 900px;
}
body.corporate-page header.site-header .home-fw-slider.style-3 {
  height: 100%;
}
@media (max-width: 991px ) {
  body.corporate-page header.site-header {
    padding-top: 60px;
  }
}
@media (max-width: 500px) {
  body.corporate-page header.site-header {
    max-height: 650px;
  }
}
body.left-menu .main-nav-wrapper.nav-wrapper-1-3 {
  position: static;
  height: 0;
}
body.left-menu .main-nav-wrapper.nav-wrapper-1-3 nav {
  position: absolute;
}
body.left-menu header.site-header {
  height: 100vh;
}
body.left-menu header.site-header .home-fw-slider.style-4 {
  height: 100%;
}
@media (max-width: 1299px) {
  body.left-menu header.site-header {
    padding-top: 90px;
  }
}
@media (max-width: 991px ) {
  body.left-menu header.site-header {
    padding-top: 60px;
  }
}
@media (max-width: 500px) {
  body.left-menu header.site-header {
    max-height: 650px;
  }
}
/* Sticky Navigator */
.main-nav-wrapper nav {
  transition: all 0.4s ease-in-out;
}
.main-nav-wrapper.sticky-nav nav {
  position: fixed !important;
}
.main-nav-wrapper.sticky-nav:not(.nav-wrapper-3) nav {
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-1-onepage.sticky-nav .main-nav-1.onepage-page {
    background-color: #f4f4f4;
    height: 60px;
  }
  .main-nav-wrapper.nav-wrapper-1-onepage.sticky-nav .main-nav-1.onepage-page .nav-logo {
    line-height: 60px;
    width: 110px;
  }
  .main-nav-wrapper.nav-wrapper-1-onepage.sticky-nav .main-nav-1.onepage-page .nav-logo:not(.logo-2) {
    display: none;
  }
  .main-nav-wrapper.nav-wrapper-1-onepage.sticky-nav .main-nav-1.onepage-page .nav-logo.logo-2 {
    display: block;
  }
  .main-nav-wrapper.nav-wrapper-1-onepage.sticky-nav .main-nav-1.onepage-page .nav-top-search {
    line-height: 60px;
    color: #333333;
  }
  .main-nav-wrapper.nav-wrapper-1-onepage.sticky-nav .main-nav-1.onepage-page .nav-main-menu > li > a {
    line-height: 60px;
    text-shadow: 0px 0 transparent;
    color: #555555;
  }
  .main-nav-wrapper.nav-wrapper-1-onepage.sticky-nav .main-nav-1.onepage-page .nav-main-menu > li:hover > a,
  .main-nav-wrapper.nav-wrapper-1-onepage.sticky-nav .main-nav-1.onepage-page .nav-main-menu > li.active > a {
    text-shadow: 0px 0 #333333;
    color: #333333;
  }
  .main-nav-wrapper.nav-wrapper-1-onepage.sticky-nav .main-nav-1.onepage-page .nav-main-menu > li.division span {
    height: 16px;
    background-color: #777;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-1-landingpage.sticky-nav .main-nav.main-nav-1.landing-page {
    background-color: #f4f4f4;
    height: 60px;
  }
  .main-nav-wrapper.nav-wrapper-1-landingpage.sticky-nav .main-nav.main-nav-1.landing-page .nav-logo {
    line-height: 60px;
    width: 110px;
  }
  .main-nav-wrapper.nav-wrapper-1-landingpage.sticky-nav .main-nav.main-nav-1.landing-page .nav-logo:not(.logo-2) {
    display: none;
  }
  .main-nav-wrapper.nav-wrapper-1-landingpage.sticky-nav .main-nav.main-nav-1.landing-page .nav-logo.logo-2 {
    display: block;
  }
  .main-nav-wrapper.nav-wrapper-1-landingpage.sticky-nav .main-nav.main-nav-1.landing-page .nav-top-search {
    line-height: 60px;
    color: #333333;
  }
  .main-nav-wrapper.nav-wrapper-1-landingpage.sticky-nav .main-nav.main-nav-1.landing-page .nav-main-menu > li > a {
    line-height: 60px;
    text-shadow: 0px 0 transparent;
    color: #555555;
  }
  .main-nav-wrapper.nav-wrapper-1-landingpage.sticky-nav .main-nav.main-nav-1.landing-page .nav-main-menu > li:hover > a,
  .main-nav-wrapper.nav-wrapper-1-landingpage.sticky-nav .main-nav.main-nav-1.landing-page .nav-main-menu > li.active > a {
    text-shadow: 0px 0 #333333;
    color: #333333;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav {
    height: 60px;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav .main-nav-1.type-1 {
    background-color: #f4f4f4;
    height: 60px;
  }
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav .main-nav-1.type-1 .nav-logo {
    line-height: 60px;
    width: 100px;
  }
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav .main-nav-1.type-1 .nav-logo:not(.logo-2) {
    display: none;
  }
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav .main-nav-1.type-1 .nav-logo.logo-2 {
    display: block;
  }
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav .main-nav-1.type-1 .nav-logo img {
    max-height: 50px;
  }
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav .main-nav-1.type-1 .nav-top-search {
    line-height: 60px;
  }
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav .main-nav-1.type-1 .nav-main-menu > li > a {
    color: #333333;
    line-height: 60px;
  }
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav .main-nav-1.type-1 .nav-main-menu > li:hover > a,
  .main-nav-wrapper.nav-wrapper-1-1.sticky-nav .main-nav-1.type-1 .nav-main-menu > li.current-menu-item > a {
    color: #9c5b57;
  }
}
@media only screen and (min-width: 992px ) {
  .main-nav-wrapper.nav-wrapper-3.sticky-nav nav .main-nav-3 .nav-content-wrapper {
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
    background-color: #f4f4f4;
  }
}
@media (max-width: 991px ) {
  .main-nav-wrapper.nav-wrapper-3.sticky-nav nav {
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
  }
}
/* Slip Page */
.slip-section .slip-wrapper {
  border-top: 1px solid #dddddd;
  padding: 20px;
  height: 100vh;
}
.slip-section .slip-bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}
.slip-section .slip-full-height {
  height: 100%;
}
@media only screen and (min-width: 992px ) {
  .slip-section .slip-wrapper {
    min-height: 760px;
  }
}
@media (max-width: 991px ) {
  .slip-section .slip-wrapper.slip-height-xs {
    height: auto;
  }
}
.slip-control-nav #fp-nav ul,
.slip-control-nav .fp-slidesNav ul {
  margin: 0;
  padding: 0;
}
.slip-control-nav #fp-nav ul li,
.slip-control-nav .fp-slidesNav ul li {
  display: block;
  width: auto;
  height: auto;
  margin: 7px;
  line-height: 1;
  position: relative;
}
.slip-control-nav #fp-nav ul li a,
.slip-control-nav .fp-slidesNav ul li a {
  display: inline-block;
  position: relative;
  z-index: 1;
  line-height: 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid transparent;
}
.slip-control-nav #fp-nav ul li a span,
.slip-control-nav .fp-slidesNav ul li a span {
  transition: all 0.4s ease-in-out;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  z-index: 1;
  height: 10px;
  width: 10px;
  line-height: 10px;
  border: 1px solid #ffffff;
  background: transparent;
}
.slip-control-nav #fp-nav ul li a.active,
.slip-control-nav .fp-slidesNav ul li a.active {
  border: 2px solid #9c5b57;
}
.slip-control-nav #fp-nav ul li a.active span,
.slip-control-nav .fp-slidesNav ul li a.active span {
  width: 8px;
  height: 8px;
  margin: auto;
  background: #9c5b57;
  border: 1px solid transparent;
}
.slip-control-nav #fp-nav ul li:hover a,
.slip-control-nav .fp-slidesNav ul li:hover a {
  border: 2px solid #9c5b57;
}
.slip-control-nav #fp-nav ul li:hover a span,
.slip-control-nav .fp-slidesNav ul li:hover a span {
  width: 8px;
  height: 8px;
  margin: auto;
  background: #9c5b57;
  border: 1px solid transparent;
}
.slip-control-nav #fp-nav.right {
  right: 47px;
}
.slip-control-nav #fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -50%;
  margin-top: 0px;
  color: #fff;
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  max-width: 220px;
  display: inline-block;
  opacity: 0;
  background-color: #9c5b57;
  padding: 10px 20px;
  overflow: visible;
}
.slip-control-nav #fp-nav ul li .fp-tooltip:after {
  content: "";
  border-style: solid;
  border-color: transparent;
  border-width: 6px 6px;
  border-left-color: #9c5b57;
  position: absolute;
  right: -12px;
  top: 50%;
  margin-top: -6px;
  display: block;
  z-index: 6991;
  width: 0;
  height: 0;
}
.slip-control-nav #fp-nav ul li .fp-tooltip.right {
  right: 40px;
}
.slip-control-nav #fp-nav ul li:hover .fp-tooltip {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.slip-home .slip-home-item-wrapper {
  position: relative;
}
.slip-home .slip-home-item-wrapper .overlay {
  max-width: 470px;
  left: 0;
  right: 0;
  margin: auto;
}
.slip-home .slip-home-item-wrapper .caption-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 52px 0;
}
.slip-home .slip-home-item-wrapper .caption-wrapper p {
  font-size: 24px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 0;
}
.slip-home .slip-home-item-wrapper .caption-wrapper .caption-icon {
  width: 66px;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media (max-width: 991px ) {
  .slip-home {
    min-height: 590px;
  }
}
@media (max-width: 767px ) {
  .slip-home {
    min-height: 350px;
    max-height: 770px;
  }
}
@media (max-width: 560px) {
  .slip-home .slip-home-item-wrapper .overlay {
    width: 240px;
  }
  .slip-home .slip-home-item-wrapper .caption-wrapper {
    padding: 20px 15px;
  }
  .slip-home .slip-home-item-wrapper .caption-wrapper p {
    font-size: 16px;
  }
}
.slip-about .item-content-wrapper {
  height: 100%;
  background-color: #eeeeee;
}
.slip-about .item-content-wrapper .item-content {
  margin-left: auto;
  margin-right: auto;
}
.slip-about .item-content-wrapper .item-content h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #777777;
  margin-bottom: 10px;
}
.slip-about .item-content-wrapper .item-content h2 {
  margin-bottom: 45px;
}
.slip-about .item-content-wrapper .item-content p {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
}
.slip-about .item-content-wrapper .item-content .paragraph {
  margin-bottom: 35px;
}
.slip-about .item-content-wrapper .item-content .name {
  color: #9c5b57;
  line-height: 1.6;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px ) and (max-width: 1229px ) {
  .slip-about .item-content-wrapper .item-content {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }
  .slip-about .item-content-wrapper .item-content h2 {
    margin-bottom: 35px;
  }
  .slip-about .item-content-wrapper .item-content p {
    font-size: 15px;
    line-height: 1.8;
  }
}
@media only screen and (min-width: 1230px ) {
  .slip-about .item-content-wrapper .item-content {
    width: 550px;
  }
}
@media (max-width: 991px ) {
  .slip-about .item-content-wrapper {
    background: url("../images/slip-bg-3.jpg") no-repeat right top;
    background-size: cover;
    position: relative;
    z-index: 0;
  }
  .slip-about .item-content-wrapper:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(238, 238, 238, 0.9);
  }
  .slip-about .item-content-wrapper .item-content {
    position: relative;
    z-index: 2;
    padding: 90px 65px;
  }
}
@media (max-width: 767px ) {
  .slip-about .item-content-wrapper {
    height: auto;
  }
  .slip-about .item-content-wrapper .item-content {
    padding: 70px 25px;
  }
  .slip-about .item-content-wrapper .item-content h5 {
    font-size: 12px;
  }
  .slip-about .item-content-wrapper .item-content .heading-icon-1:before {
    font-size: 60px;
  }
  .slip-about .item-content-wrapper .item-content h2 {
    font-size: 19px;
    margin-bottom: 25px;
  }
  .slip-about .item-content-wrapper .item-content p {
    font-size: 15px;
  }
  .slip-about .item-content-wrapper .item-content .paragraph {
    margin-bottom: 25px;
  }
}
@media (max-width: 480px) {
  .slip-about .item-content-wrapper .item-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.slip-portfolio .item-content-wrapper {
  height: 100%;
  background-color: #eeeeee;
}
.slip-portfolio .item-content-wrapper .item-content {
  margin-left: auto;
  margin-right: auto;
  text-align: right;
}
.slip-portfolio .item-content-wrapper .item-content h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #777777;
  margin-bottom: 10px;
}
.slip-portfolio .item-content-wrapper .item-content h2 {
  margin-bottom: 45px;
}
.slip-portfolio .item-content-wrapper .item-content .paragraph {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  margin-bottom: 55px;
}
@media only screen and (min-width: 992px ) and (max-width: 1229px ) {
  .slip-portfolio .item-content-wrapper .item-content {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }
  .slip-portfolio .item-content-wrapper .item-content h2 {
    margin-bottom: 35px;
  }
  .slip-portfolio .item-content-wrapper .item-content .paragraph {
    font-size: 15px;
    line-height: 1.8;
  }
}
@media only screen and (min-width: 1230px ) {
  .slip-portfolio .item-content-wrapper .item-content {
    width: 550px;
    margin-right: 30px;
  }
}
@media (min-width: 1400px) {
  .slip-portfolio .item-content-wrapper .item-content {
    width: 550px;
    margin-right: 115px;
  }
}
@media (max-width: 991px ) {
  .slip-portfolio .item-content-wrapper {
    background: url("../images/slip-bg-4.jpg") no-repeat right top;
    background-size: cover;
    position: relative;
    z-index: 0;
  }
  .slip-portfolio .item-content-wrapper:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(238, 238, 238, 0.9);
  }
  .slip-portfolio .item-content-wrapper .item-content {
    text-align: center;
    position: relative;
    z-index: 2;
    padding: 90px 65px;
  }
}
@media (max-width: 767px ) {
  .slip-portfolio .item-content-wrapper {
    height: auto;
  }
  .slip-portfolio .item-content-wrapper .item-content {
    padding: 60px 25px;
  }
  .slip-portfolio .item-content-wrapper .item-content h5 {
    font-size: 12px;
  }
  .slip-portfolio .item-content-wrapper .item-content .heading-icon-1:before {
    font-size: 60px;
  }
  .slip-portfolio .item-content-wrapper .item-content h2 {
    font-size: 19px;
    margin-bottom: 25px;
  }
  .slip-portfolio .item-content-wrapper .item-content .paragraph {
    font-size: 15px;
    margin-bottom: 25px;
  }
}
@media (max-width: 480px) {
  .slip-portfolio .item-content-wrapper .item-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.slip-service .item-content-wrapper {
  height: 100%;
  background-color: #eeeeee;
}
.slip-service .item-content-wrapper .item-content {
  margin-left: auto;
  margin-right: auto;
}
.slip-service .item-content-wrapper .item-content .content-header h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #777777;
  margin-bottom: 10px;
}
.slip-service .item-content-wrapper .item-content .content-header h2 {
  margin-bottom: 45px;
}
.slip-service .item-content-wrapper .item-content .accordion-1 .panel-group .panel .panel-heading {
  border: 1px solid #cccccc;
  color: #555555;
}
.slip-service .item-content-wrapper .item-content .accordion-1 .panel-group .panel .panel-heading .panel-title h5 i {
  color: #333333;
}
.slip-service .item-content-wrapper .item-content .accordion-1 .panel-group .panel .panel-heading .panel-title h5:after {
  color: #777777;
}
@media only screen and (min-width: 992px ) and (max-width: 1229px ) {
  .slip-service .item-content-wrapper .item-content {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }
  .slip-service .item-content-wrapper .item-content .content-header h2 {
    margin-bottom: 35px;
  }
}
@media only screen and (min-width: 1230px ) {
  .slip-service .item-content-wrapper .item-content {
    max-width: 550px;
    margin-left: 30px;
  }
}
@media (min-width: 1400px) {
  .slip-service .item-content-wrapper .item-content {
    margin-left: 115px;
  }
}
@media (max-width: 991px ) {
  .slip-service .item-content-wrapper {
    background: url("../images/slip-bg-4.jpg") no-repeat right top;
    background-size: cover;
    position: relative;
    z-index: 0;
  }
  .slip-service .item-content-wrapper:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(238, 238, 238, 0.9);
  }
  .slip-service .item-content-wrapper .item-content {
    position: relative;
    z-index: 2;
    padding: 90px 65px;
  }
  .slip-service .item-content-wrapper .item-content .content-header {
    text-align: center;
  }
}
@media (max-width: 767px ) {
  .slip-service .item-content-wrapper {
    height: auto;
  }
  .slip-service .item-content-wrapper .item-content {
    padding: 60px 25px;
  }
  .slip-service .item-content-wrapper .item-content .content-header h5 {
    font-size: 12px;
  }
  .slip-service .item-content-wrapper .item-content .content-header .heading-icon-1:before {
    font-size: 60px;
  }
  .slip-service .item-content-wrapper .item-content .content-header h2 {
    font-size: 19px;
    margin-bottom: 25px;
  }
}
@media (max-width: 480px) {
  .slip-service .item-content-wrapper .item-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.slip-blog .item-content-wrapper {
  height: 100%;
  background-color: #eeeeee;
}
.slip-blog .item-content-wrapper .item-content {
  margin-left: auto;
  margin-right: auto;
  text-align: right;
}
.slip-blog .item-content-wrapper .item-content h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #777777;
  margin-bottom: 10px;
}
.slip-blog .item-content-wrapper .item-content h2 {
  margin-bottom: 45px;
}
.slip-blog .item-content-wrapper .item-content .paragraph {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  margin-bottom: 55px;
}
@media only screen and (min-width: 992px ) and (max-width: 1229px ) {
  .slip-blog .item-content-wrapper .item-content {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
  }
  .slip-blog .item-content-wrapper .item-content h2 {
    margin-bottom: 35px;
  }
  .slip-blog .item-content-wrapper .item-content .paragraph {
    font-size: 15px;
    line-height: 1.8;
  }
}
@media only screen and (min-width: 1230px ) {
  .slip-blog .item-content-wrapper .item-content {
    width: 550px;
    margin-right: 30px;
  }
}
@media (min-width: 1400px) {
  .slip-blog .item-content-wrapper .item-content {
    width: 550px;
    margin-right: 115px;
  }
}
@media (max-width: 991px ) {
  .slip-blog .item-content-wrapper {
    background: url("../images/slip-bg-6.jpg") no-repeat right top;
    background-size: cover;
    position: relative;
    z-index: 0;
  }
  .slip-blog .item-content-wrapper:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(238, 238, 238, 0.9);
  }
  .slip-blog .item-content-wrapper .item-content {
    text-align: center;
    position: relative;
    z-index: 2;
    padding: 90px 65px;
  }
}
@media (max-width: 767px ) {
  .slip-blog .item-content-wrapper {
    height: auto;
  }
  .slip-blog .item-content-wrapper .item-content {
    padding: 70px 25px;
  }
  .slip-blog .item-content-wrapper .item-content h5 {
    font-size: 12px;
  }
  .slip-blog .item-content-wrapper .item-content .heading-icon-1:before {
    font-size: 60px;
  }
  .slip-blog .item-content-wrapper .item-content h2 {
    font-size: 19px;
    margin-bottom: 25px;
  }
  .slip-blog .item-content-wrapper .item-content .paragraph {
    font-size: 15px;
    margin-bottom: 25px;
  }
}
@media (max-width: 480px) {
  .slip-blog .item-content-wrapper .item-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.slip-contact .slip-contact-item-wrapper {
  position: relative;
}
.slip-contact .slip-contact-item-wrapper .overlay {
  transition: all 0s;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.slip-contact .slip-contact-item-wrapper .caption-wrapper .caption-logo {
  width: 170px;
  margin-bottom: 45px;
}
.slip-contact .slip-contact-item-wrapper .caption-wrapper .contact-info {
  margin-bottom: 38px;
}
.slip-contact .slip-contact-item-wrapper .caption-wrapper .contact-info p {
  font-size: 16px;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  color: #333333;
  margin-bottom: 0;
}
.slip-contact .slip-contact-item-wrapper .caption-wrapper .contact-info p span {
  margin-left: 20px;
  color: #777777;
}
.slip-contact .slip-contact-item-wrapper .caption-wrapper .social-04 > a {
  margin-left: 12px;
  margin-right: 12px;
}
.slip-contact .slip-contact-item-wrapper .caption-wrapper .social-04 > a:hover {
  color: #999999;
}
@media (min-width: 561px) {
  .slip-contact .slip-contact-item-wrapper .overlay {
    max-width: 470px;
    max-height: 470px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
  }
  .slip-contact .slip-contact-item-wrapper .overlay .caption-wrapper .contact-info p {
    padding: 0 25px;
  }
  .slip-contact .slip-contact-item-wrapper .overlay .caption-wrapper .social-04 {
    width: 290px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 991px ) {
  .slip-contact {
    min-height: 590px;
  }
}
@media (max-width: 767px ) {
  .slip-contact {
    min-height: 550px;
    max-height: 770px;
  }
}
@media (max-width: 560px) {
  .slip-contact .slip-contact-item-wrapper .caption-wrapper {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px 15px;
  }
}
/* Services */
.service-section-01 .image-container {
  text-align: center;
}
.service-section-01 .image-container img {
  max-width: 100%;
}
.service-section-01 .block-service-style-01 {
  margin: 0 auto;
  max-width: 570px;
}
.service-section-01 .block-service-style-01 a:hover h5,
.service-section-01 .block-service-style-01 a:hover i {
  color: #9c5b57;
}
.service-section-01 .block-service-style-01 h5 {
  color: #555555;
  font-size: 16px;
  line-height: 40px;
  margin: 0;
  padding: 10px 15px;
}
.service-section-01 .block-service-style-01 h5 i {
  color: #555555;
  float: left;
  font-size: 26px;
  margin-right: 10px;
  height: 40px;
  width: 40px;
}
.service-section-01 .block-service-style-01 p {
  margin-bottom: 0;
  padding: 15px 15px 30px;
}
.service-section-01 .service-button a {
  margin-left: 15px;
}
.service-section-01 .service-button a:hover {
  background-color: #9c5b57;
  color: #ffffff;
}
@media (max-width: 1229px ) {
  .service-section-01 .service-button {
    text-align: center;
  }
}
.service-section-02 .panel-group {
  margin: 0 auto;
  max-width: 570px;
}
.service-section-02 .panel-group .panel {
  margin-bottom: 20px;
}
.service-section-02 .panel-group .panel:last-of-type {
  margin-bottom: 0;
}
.service-section-02 .panel-group .panel .panel-body {
  padding: 20px 15px;
}
.service-section-02 .panel-group .panel .panel-heading {
  border: 1px solid #dddddd;
  color: #555555;
  padding: 10px 15px;
}
.service-section-02 .panel-group .panel .panel-heading .panel-title h5 {
  font-size: 16px;
  line-height: 40px;
  margin: 0;
}
.service-section-02 .panel-group .panel .panel-heading .panel-title h5:after {
  color: #999999;
  content: "\e.";
  float: right;
  font-size: 20px;
  vertical-align: middle;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.service-section-02 .panel-group .panel .panel-heading .panel-title h5 i {
  color: #9c5b57;
  float: left;
  font-size: 26px;
  margin-right: 10px;
  height: 40px;
  width: 40px;
}
.service-section-02 .panel-group .panel .panel-heading a.collapsed h5:after {
  content: "\e07d";
}
.service-section-02 img {
  margin-bottom: 25px;
  max-width: 100%;
}
.service-section-03 .block-service-style-03 i {
  transition: all 0.4s ease-in-out;
  font-size: 52px;
  display: block;
  margin-bottom: -25px;
  color: #808080;
}
.service-section-03 .block-service-style-03 h5 {
  margin-bottom: 30px;
}
.service-section-03 .block-service-style-03 .heading-hr-2:after {
  transition: all 0.4s ease-in-out;
  border-color: #808080;
  height: 2px;
  margin-top: 24px;
}
.service-section-03 .block-service-style-03 a:hover .heading-hr-2:after {
  border-color: #9c5b57;
  background-color: #9c5b57;
}
.service-section-03 .block-service-style-03 a:hover i {
  color: #9c5b57;
}
.service-section-04 .block-service-style-04 {
  display: block;
  padding: 20px 30px 35px;
  background-color: #f4f4f4;
}
.service-section-04 .block-service-style-04 i {
  display: block;
  font-size: 70px;
  margin-bottom: 5px;
}
.service-section-04 .block-service-style-04 i:before {
  display: block;
}
.service-section-04 .block-service-style-04 h5 {
  margin: 0;
  color: inherit;
}
.service-section-04 .block-service-style-04:hover {
  background-color: #9c5b57;
  color: #ffffff;
}
.service-section-05 .block-service-style-05 a:hover i:before {
  color: #9c5b57;
}
.service-section-05 .block-service-style-05 a:hover h5 {
  background-color: #555555;
  transition: all 0.4s ease-in-out;
}
.service-section-05 .block-service-style-05 h5 {
  background-color: #999999;
  margin: 20px 0 0;
  padding: 25px 0;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
}
.service-section-05 .block-service-style-05 .content {
  padding: 0 30px 50px;
  background-color: #f4f4f4;
}
.service-section-05 .block-service-style-05 .content p {
  margin-bottom: 0;
}
.service-section-05 i {
  transition: all 0.4s ease-in-out;
  font-size: 70px;
  display: block;
  margin-bottom: -35px;
  color: #808080;
}
.about-item-01 {
  margin: 0 auto;
  max-width: 375px;
}
.about-item-01:hover h4 {
  color: #9c5b57;
  transition: all 0.4s ease-in-out;
}
.about-item-01:hover h6 {
  color: #333333;
  transition: all 0.4s ease-in-out;
}
.about-item-01 h4 {
  font-size: 16px;
}
.about-item-01 h6 {
  margin: 25px 0 15px;
  font-size: 13px;
  color: #9c5b57;
  transition: all 0.4s ease-in-out;
}
.about-item-01 img {
  max-width: 100%;
  transition: all 0.4s ease-in-out;
}
/* Cover Box */
.cover-box {
  display: block;
  float: left;
  position: relative;
  transition: all 0.4s ease-in-out;
}
.cover-box.normal-style .cover-box-content {
  display: block;
}
.cover-box:not(.normal-style) .cover-box-content {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
}
.cover-box-container {
  overflow: hidden;
  position: relative;
}
.cover-box-shortcode .cover-box {
  background-color: #ffffff;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
.cover-box-shortcode .cover-box .cover-box-content {
  padding-left: 15px;
  padding-right: 15px;
}
.cover-box-shortcode .cover-box .cover-box-content .content-wrapper h5 {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 18px;
}
.cover-box-shortcode .cover-box .cover-box-content .content-wrapper h6 {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 18px;
  color: #9c5b57;
  font-family: 'Raleway', sans-serif;
}
.cover-box-shortcode .cover-box .cover-box-content .content-wrapper p {
  margin-bottom: 22px;
}
.cover-box-shortcode .cover-box img {
  width: 100%;
}
@media (max-width: 784px) {
  .cover-box-shortcode .cover-box > div {
    margin-right: auto;
    margin-left: auto;
    max-width: 370px;
  }
  .cover-box-shortcode .cover-box .content-wrapper {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}
/* Widget */
.widget {
  margin-bottom: 20px;
}
.widget h5 {
  margin-bottom: 30px;
}
/* Author */
.author-style-1 {
  transition: all 0.4s ease-in-out;
}
.author-style-1 .author-item {
  position: relative;
}
.author-style-1 .author-item img {
  width: 100%;
  padding-right: 70px;
}
.author-style-1 .author-item ul {
  display: block;
  position: absolute;
  width: 70px;
  padding: 0 25px;
  height: 100%;
  right: 0;
  top: 0;
  text-align: center;
}
.author-style-1 .author-item ul li {
  display: block;
  position: relative;
  height: 25%;
}
.author-style-1 .author-item ul li:not(:first-child) {
  border-top: 1px solid #dddddd;
}
.author-style-1 .author-item ul li a {
  width: 100%;
}
.author-style-1 .author-item ul li a:hover {
  color: #9c5b57;
}
.flexbox .author-style-1 .author-item ul li {
  display: flex;
}
.flexbox .author-style-1 .author-item ul li a {
  align-self: center;
}
.author-style-2 .author-item {
  overflow: hidden;
  position: relative;
}
.author-style-2 .author-item img {
  width: 100%;
}
.author-style-2 .author-item ul {
  transition: all 0.4s ease-in-out;
  position: absolute;
  display: block;
  width: 100%;
  height: 44px;
  left: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.5);
  transform: translateY(100%);
}
.author-style-2 .author-item ul li {
  display: inline-block;
  height: 100%;
}
.author-style-2 .author-item ul li a {
  line-height: 20px;
  padding: 12px 7px;
  color: #ffffff;
}
.author-style-2 .author-item ul li a:hover {
  color: #9c5b57;
}
.author-style-2 .author-item:hover ul {
  transform: translateY(0);
}
/* Blog Categories */
.widget-blog-categories {
  max-width: 270px;
  font-weight: 600;
  font-size: 11px;
}
.widget-blog-categories ul li {
  border-top: 1px solid #dddddd;
  color: #777777;
  padding: 8px 0;
}
.widget-blog-categories ul li a:hover {
  color: #9c5b57;
}
.widget-blog-categories ul li:first-of-type {
  border-top: 0;
}
/* Related */
.blog-related.style-02 .blog-related-item .image {
  width: 40%;
}
.blog-related.style-02 .blog-related-item .image img {
  margin-top: 8px;
}
.blog-related .blog-related-item {
  margin-bottom: 30px;
}
footer .blog-related .blog-related-item {
  max-width: 315px;
}
.blog-related .blog-related-item:after {
  clear: both;
}
.blog-related .blog-related-item:before,
.blog-related .blog-related-item:after {
  content: " ";
  display: table;
}
.blog-related .blog-related-item:after {
  clear: both;
}
.blog-related .blog-related-item:before,
.blog-related .blog-related-item:after {
  content: " ";
  display: table;
}
.blog-related .blog-related-item .content {
  display: inline-block;
  width: 60%;
  padding-left: 15px;
}
.blog-related .blog-related-item .content p {
  font-size: 12px;
  font-weight: 700;
  line-height: 2;
  margin-bottom: 0;
}
.blog-related .blog-related-item .content span {
  font-size: 11px;
  font-weight: 600;
  margin: 10px 0 25px;
  color: #777777;
  font-style: italic;
}
.blog-related .blog-related-item .image {
  float: left;
  width: 40%;
}
.blog-related .blog-related-item .image img {
  max-width: 100%;
}
/* Shop Categories */
.block-shop-categories ul {
  padding-top: 5px;
}
.block-shop-categories ul li {
  color: #777777;
  font-weight: 600;
  font-size: 11px;
  padding: 10px 0;
}
.block-shop-categories ul li:last-child {
  padding-bottom: 0;
}
.block-shop-categories ul li:not(:first-child) {
  border-top: 1px solid #dddddd;
}
.block-shop-categories ul li a:hover {
  color: #9c5b57;
}
.shop-categories > ul > li {
  margin-bottom: 30px;
}
.shop-categories > ul > li a > h5 {
  margin-bottom: 0;
  padding: 18px 25px;
  background-color: #f4f4f4;
}
.shop-categories > ul > li .block-shop-categories {
  padding: 0 15px;
}
/* Search */
.widget-search .search-box {
  position: relative;
  width: 100%;
}
.widget-search .search-box input {
  width: 100%;
}
.widget-search .search-box input:focus {
  border-color: #555555;
}
.widget-search .search-box button {
  background-color: #555555;
  display: table;
  height: 100%;
  width: 44px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  color: #ffffff;
  border: 0;
  font-size: 20px;
}
.widget-search .search-box button i:before {
  display: block;
}
/* Instagram */
.instagram {
  max-width: 370px;
}
.instagram:not(.widget) {
  max-width: 315px;
}
.instagram a {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  color: #9c5b57;
}
.instagram .instagram-wrapper {
  margin-right: -5px;
}
.instagram .instagram-wrapper:after {
  clear: both;
}
.instagram .instagram-wrapper:before,
.instagram .instagram-wrapper:after {
  content: " ";
  display: table;
}
.instagram .instagram-wrapper:after {
  clear: both;
}
.instagram .instagram-wrapper:before,
.instagram .instagram-wrapper:after {
  content: " ";
  display: table;
}
.instagram.style-2 .instagram-item {
  margin: 0 3px 3px 0;
}
.instagram .instagram-item {
  display: block;
  float: left;
  padding-bottom: 5px;
  padding-right: 5px;
  width: 33.333%;
}
.instagram .instagram-item .image {
  display: inline-block;
}
.instagram .instagram-item .image img {
  width: 100%;
}
.instagram a {
  color: #9c5b57;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.category-product h6 {
  margin: 0;
}
.category-product h6 > a {
  color: #777777;
}
.category-product h6 > a:hover {
  color: #333333;
}
.category-product li {
  border-bottom: 1px solid #dddddd;
  padding: 15px 0;
}
.category-product li:last-of-type {
  border-bottom: none;
}
/* Recently Viewed */
.shop-recently-viewed-item .image {
  float: left;
  width: 100px;
  position: relative;
}
.shop-recently-viewed-item .image:after {
  background-color: rgba(51, 51, 51, 0.5);
  content: "";
  display: block;
  position: absolute;
  z-index: 512;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.shop-recently-viewed-item .image:after {
  opacity: 0;
}
.shop-recently-viewed-item .image:hover:after {
  opacity: 1;
}
.shop-recently-viewed-item .image img {
  width: 100%;
}
.shop-recently-viewed-item .price {
  display: block;
  padding-left: 115px;
}
.shop-recently-viewed-item .price p {
  font-size: 13px;
  margin-bottom: 0;
}
.shop-recently-viewed-item .price p > a:hover {
  color: #9c5b57;
}
.shop-recently-viewed-item .price span {
  font-size: 14px;
  margin: 10px 0 25px;
  color: #9c5b57;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.shop-recently-viewed h5 {
  margin-bottom: 55px;
}
.shop-recently-viewed .shop-recently-viewed-item:not(:last-of-type) {
  margin-bottom: 30px;
}
/* Filter Price */
.price-filter > h5 {
  margin-bottom: 40px;
}
.price-filter .price-slider-range {
  background-color: #dddddd;
  height: 6px;
  position: relative;
  width: 100%;
  margin-bottom: 25px;
}
.price-filter .price-slider-range .ui-widget-header {
  background-color: #777777;
  height: 6px;
  position: absolute;
  top: 0;
  width: 50%;
}
.price-filter .price-slider-range span {
  border-radius: 50%;
  content: "";
  cursor: pointer;
  display: block;
  height: 10px;
  outline: none;
  position: absolute;
  top: -2px;
  width: 10px;
  background-color: #9c5b57;
}
.price-filter .price-filter-detail {
  width: 100%;
}
.price-filter .price-filter-detail:after {
  clear: both;
}
.price-filter .price-filter-detail:before,
.price-filter .price-filter-detail:after {
  content: " ";
  display: table;
}
.price-filter .price-filter-detail:after {
  clear: both;
}
.price-filter .price-filter-detail:before,
.price-filter .price-filter-detail:after {
  content: " ";
  display: table;
}
.price-filter .price-filter-detail .button {
  float: left;
}
.price-filter .price-filter-detail .button a.normal-btn {
  line-height: 16px;
  font-size: 11px;
  letter-spacing: 3px;
  padding-left: 32px;
  padding-right: 32px;
}
.price-filter .price-filter-detail .price {
  float: right;
}
.price-filter .price-filter-detail .price > p {
  margin-bottom: 0;
  line-height: 44px;
}
.price-filter .price-filter-detail .price > p span {
  color: #9c5b57;
  line-height: 16px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
/* Contact */
.contact-us :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #777777;
  opacity: 1;
}
.contact-us :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #777777;
}
.contact-us ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #777777;
  opacity: 1;
}
.contact-us ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #777777;
}
.contact-us .msg {
  background: 0;
  color: #999999;
  height: 164px;
  outline-color: #9c5b57;
  padding-left: 12px;
  resize: none;
  width: 100%;
}
.contact-us a {
  float: right;
  margin-top: 25px;
}
.contact-us input,
.contact-us textarea {
  color: #777777;
  display: block;
  margin-bottom: 25px;
  width: 100%;
  background: transparent;
}
/* Team */
.team-style-01 .image {
  display: inline-block;
  margin-bottom: 35px;
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}
.team-style-01 .image img {
  max-width: 100%;
}
.team-style-01 .image:hover {
  transition: all 0.4s ease-in-out;
}
.team-style-01 .image:hover .overlay {
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.team-style-01 .image:hover .social-01 li {
  transform: translateY(0);
}
.team-style-01 .job {
  color: #9c5b57;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.team-style-01 .name {
  margin-bottom: 0;
}
.team-style-01 .overlay {
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  top: 0;
  transition: all 0.4s ease-in-out;
}
.team-style-01 .social-01 a {
  border-radius: 50%;
  font-size: 15px;
  height: 30px;
  width: 30px;
}
.team-style-01 .social-01 a:hover {
  background-color: #9c5b57;
}
.team-style-01 .social-01 a:hover i:before {
  color: #ffffff;
}
.team-style-01 .social-01 a i {
  line-height: 30px;
}
.team-style-01 .social-01 a i:before {
  color: #333333;
}
.team-style-01 .social-01 li {
  transform: translateY(-40px);
  transition: all 0.4s ease-in-out;
}
.team-style-01 .social-01 li:nth-child(1) {
  transition-delay: 0s;
}
.team-style-01 .social-01 li:nth-child(2) {
  transition-delay: 0.1s;
}
.team-style-01 .social-01 li:nth-child(3) {
  transition-delay: 0.2s;
}
.team-style-01 .social-01 li:nth-child(4) {
  transition-delay: 0.3s;
}
/* TEAM STYLE 2 */
.team-style-02 {
  margin-bottom: 40px;
}
.team-style-02 .image {
  display: inline-block;
  margin-bottom: 35px;
  position: relative;
  transition: all 0.4s ease-in-out;
}
.team-style-02 .image img {
  max-width: 100%;
}
.team-style-02 .image:hover {
  transition: all 0.4s ease-in-out;
}
.team-style-02 .image:hover .overlay {
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.team-style-02 .image:hover .social-01 li {
  transform: translateY(0);
}
.team-style-02 .job {
  color: #9c5b57;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.team-style-02 .name {
  margin-bottom: 0;
}
.team-style-02 .overlay {
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.team-style-02 .social-01 a {
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 15px;
  height: 30px;
  width: 30px;
}
.team-style-02 .social-01 a:hover {
  background-color: #9c5b57;
}
.team-style-02 .social-01 a:hover i:before {
  color: #ffffff;
}
.team-style-02 .social-01 a i {
  line-height: 30px;
}
.team-style-02 .social-01 a i:before {
  color: #333333;
}
.team-style-02 .social-01 li {
  padding: 0 10px;
  transform: translateY(-40px);
  transition: all 0.4s ease-in-out;
}
.team-style-02 .social-01 li:nth-child(1) {
  transition-delay: 0s;
}
.team-style-02 .social-01 li:nth-child(2) {
  transition-delay: 0.1s;
}
.team-style-02 .social-01 li:nth-child(3) {
  transition-delay: 0.2s;
}
.team-style-02 .social-01 li:nth-child(4) {
  transition-delay: 0.3s;
}
/* TEAM STYLE 3 */
.team-section-03 .image {
  display: inline-block;
  transition: all 0.4s ease-in-out;
}
.team-section-03 .image:hover {
  transition: all 0.4s ease-in-out;
}
.team-section-03 .image:hover .overlay {
  opacity: 1;
  transition: all 0.4s ease-in-out;
}
.team-section-03 .image:hover .overlay .social-01 li {
  transform: translateX(0);
}
.team-section-03 .image .overlay {
  background-color: rgba(51, 51, 51, 0.9);
  left: 50%;
  line-height: 0;
  max-height: 210px;
  max-width: 310px;
  opacity: 0;
  padding: 40px 70px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}
.team-section-03 .image .overlay .icon {
  font-size: 70px;
  color: #518f37;
}
.team-section-03 .image .overlay .job {
  margin: 0;
  font-style: italic;
}
.team-section-03 .image .overlay .name {
  margin-bottom: 15px;
  color: #ffffff;
}
.team-section-03 .image .overlay .social-01 a {
  margin: 0 10px;
  transition: all 0.4s ease-in-out;
}
.team-section-03 .image .overlay .social-01 a:hover i:before {
  color: #ffffff;
  transition: all 0.4s ease-in-out;
}
.team-section-03 .image .overlay .social-01 li {
  transform: translateX(-200px);
  transition: all 0.4s ease-in-out;
}
.team-section-03 .image .overlay .social-01 li:nth-child(1) {
  transition-delay: 0s;
}
.team-section-03 .image .overlay .social-01 li:nth-child(2) {
  transition-delay: 0.1s;
}
.team-section-03 .image .overlay .social-01 li:nth-child(3) {
  transition-delay: 0.2s;
}
.team-section-03 .image .overlay .social-01 li:nth-child(4) {
  transition-delay: 0.3s;
}
.team-section-03 .image img {
  max-width: 100%;
  position: relative;
}
/* Blog */
.blog-lastest-post {
  text-align: center;
}
.blog-lastest-post .container {
  max-width: 100%;
}
.blog-lastest-post .blog-item {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}
.blog-lastest-post .blog-item > p {
  letter-spacing: 4px;
  line-height: 1.2;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-size: 10px;
}
.blog-lastest-post .blog-item > p a {
  color: #9c5b57;
}
.blog-lastest-post .blog-item > p a:hover {
  color: #777777;
}
.blog-lastest-post .blog-item > p span {
  color: #777777;
}
.blog-lastest-post .blog-item a:hover .layout-after:after {
  opacity: 1;
}
.blog-lastest-post .blog-item a .item-image {
  position: relative;
  margin-bottom: 33px;
  overflow: hidden;
}
.blog-lastest-post .blog-item a .item-image:after {
  background-color: rgba(244, 244, 244, 0.9);
  content: "";
  display: block;
  position: absolute;
  z-index: 512;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.blog-lastest-post .blog-item a .item-image img {
  width: 100%;
}
.blog-lastest-post .blog-item a .layout-after:after {
  opacity: 0;
}
.blog-lastest-post .blog-item h5 {
  font-size: 16px;
  margin-top: 10px;
  transition: all 0.4s ease-in-out;
}
.blog-lastest-post .blog-item h5:hover {
  color: #9c5b57;
}
.blog-index-corporate-item {
  transition: all 0.4s ease-in-out;
  position: relative;
  float: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.blog-index-corporate-item:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border: 1px solid #eeeeee;
  border-right: 0;
}
.blog-index-corporate-item > * {
  flex: 1 1 375px;
}
.blog-index-corporate-item img {
  width: 100%;
}
.blog-index-corporate-item .item-content {
  position: static;
  padding: 60px 20px;
}
.blog-index-corporate-item .item-content h5 {
  font-size: 16px;
  margin-bottom: 5px;
}
.blog-index-corporate-item .item-content .heading-icon-2 {
  transition: all 0.4s ease-in-out;
}
.blog-index-corporate-item .item-content .heading-icon-2:after {
  color: #9c5b57;
  content: "\e2d1";
  display: block;
  font-size: 50px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.blog-index-corporate-item .item-content .heading-icon-2:after {
  margin-top: 10px;
}
.blog-index-corporate-item .item-content h6 {
  margin-bottom: 10px;
  color: #999999;
}
.blog-index-corporate-item .item-content p {
  margin-bottom: 0;
}
.blog-index-corporate-item:hover {
  background-color: #333333;
  color: #ffffff;
}
.blog-index-corporate-item:hover a,
.blog-index-corporate-item:hover .heading-icon-2:after {
  color: #ffffff;
}
@media only screen and (min-width: 768px ) and (max-width: 991px ) {
  .blog-index-corporate-item:nth-child(2n) .item-image {
    order: 2;
  }
}
@media only screen and (min-width: 992px ) {
  .blog-index-corporate-item {
    width: 50%;
  }
  .blog-index-corporate-item .item-content {
    padding: 60px 30px;
  }
}
@media (min-width: 1530px) {
  .blog-index-corporate-item .item-content {
    padding: 20px 30px;
  }
  .blog-index-corporate-item:nth-child(n+3) .item-image {
    order: 2;
  }
}
@media (min-width: 1400px) {
  html:not(.flexbox) .blog-index-corporate-item {
    width: 25%;
  }
  html:not(.flexbox) .blog-index-corporate-item .item-content {
    padding: 60px 30px;
  }
}
.blog-post-01 .blog-item {
  float: left;
  height: 100%;
  position: relative;
  width: 50%;
}
.blog-post-01 .blog-item .item-content-wrapper {
  border: 1px solid #eee;
  height: 100%;
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
}
.blog-post-01 .blog-item .item-image {
  width: 50%;
  display: block;
}
.blog-post-01 .blog-item .item-image img {
  width: 100%;
}
.blog-post-01 .blog-item.style-2 .item-content-wrapper {
  left: 0;
}
.blog-post-01 .blog-item.style-2 .item-image {
  float: right;
}
.blog-post-01 .item-content-wrapper {
  transition: all 0.4s ease-in-out;
  background-color: #ffffff;
}
.blog-post-01 .item-content-wrapper .item-content {
  margin-left: auto;
  margin-right: auto;
  width: 350px;
}
.blog-post-01 .item-content-wrapper .item-content .heading-icon-2 {
  transition: all 0.4s ease-in-out;
}
.blog-post-01 .item-content-wrapper .item-content .heading-icon-2:after {
  color: #9c5b57;
  content: "\e2d1";
  display: block;
  font-size: 50px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.blog-post-01 .item-content-wrapper .item-content .heading-icon-2:after {
  margin-top: 10px;
}
.blog-post-01 .item-content-wrapper .item-content h5 {
  font-size: 16px;
  margin-bottom: 5px;
}
.blog-post-01 .item-content-wrapper .item-content h6 {
  margin-bottom: 10px;
  color: #999999;
}
.blog-post-01 .item-content-wrapper .item-content p {
  transition: all 0.4s ease-in-out;
  margin-bottom: 0;
}
.blog-post-01 .blog-item:hover .item-content-wrapper {
  border: 1px solid #333333;
  background-color: #333333;
}
.blog-post-01 .blog-item:hover .item-content-wrapper .item-content h5 a {
  color: #ffffff;
}
.blog-post-01 .blog-item:hover .item-content-wrapper .item-content p {
  color: #999999;
}
.blog-post-02 .blog-content {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 40px 70px;
}
.blog-post-02 .blog-image {
  display: inline-block;
  float: left;
  max-width: 527px;
  position: relative;
}
.blog-post-02 .blog-image:hover .overlay {
  opacity: 1;
}
.blog-post-02 .blog-image .normal-btn.normal-btn-darkgray:hover,
.blog-post-02 .blog-image .normal-btn.normal-btn-darkgray:focus {
  background-color: #518f37;
  color: #ffffff;
}
.blog-post-02 .blog-image h2 {
  font-size: 24px;
}
.blog-post-02 .blog-image h6 {
  margin-bottom: 10px;
  color: #999999;
}
.blog-post-02 .blog-image img {
  width: 100%;
}
.blog-post-02 .overlay {
  opacity: 0;
  padding: 30px 60px;
}
@media (max-width: 1070px) and (min-width: 527px) {
  .blog-post-02 .blog-image {
    float: none;
  }
}
@media (max-width: 527px) {
  .blog-post-02 .overlay {
    padding: 0;
  }
  .blog-post-02 .overlay .blog-content {
    padding: 0;
  }
  .blog-post-02 .overlay .normal-btn.btn-size-5 {
    padding: 10px 30px;
  }
  .blog-post-02 .overlay h2 {
    font-size: 20px;
  }
}
/* Testimonials */
.testimonial-heading-01 {
  color: #ffffff;
}
.testimonial-heading-01:after {
  border-bottom: 2px solid #9c5b57;
  content: "";
  display: block;
  position: relative;
  margin-top: 20px;
  width: 40px;
}
.testimonial-heading-01:after {
  margin-left: auto;
  margin-right: auto;
}
.testimonial-heading-01.hr-light:after {
  border-color: #ffffff;
}
.testimonial-01 .testimonial-item .item-content {
  padding: 0 15px;
}
.testimonial-01 .testimonial-item .item-content .name {
  line-height: 1.6;
}
.testimonial-01 .testimonial-item .item-content p {
  margin-bottom: 0;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
}
@media (max-width: 767px ) {
  .testimonial-01 .testimonial-item .item-content .name {
    margin-top: 15px;
  }
  .testimonial-01 .testimonial-item .item-content p {
    font-size: 13px;
  }
}
.testimonial-02 {
  margin-bottom: 40px;
}
.testimonial-02:after {
  clear: both;
}
.testimonial-02:before,
.testimonial-02:after {
  content: " ";
  display: table;
}
.testimonial-02:after {
  clear: both;
}
.testimonial-02:before,
.testimonial-02:after {
  content: " ";
  display: table;
}
.testimonial-02 .slick-next {
  right: 50%;
  transform: translateX(36px);
}
.testimonial-02 .slick-next:before {
  content: "\e07f";
}
.testimonial-02 .slick-prev {
  left: 50%;
  transform: translateX(-36px);
}
.testimonial-02 .slick-prev:before {
  content: "\e032";
}
.testimonial-02 .slick-prev,
.testimonial-02 .slick-next {
  bottom: -41px;
  height: 25px;
  width: 25px;
}
.testimonial-02 .slick-prev:before,
.testimonial-02 .slick-next:before {
  font-size: 25px;
  color: #999999;
  font-family: "wolverine";
}
.testimonial-02 .slick-prev:hover:before,
.testimonial-02 .slick-next:hover:before {
  color: #333333;
}
.testimonial-02 .testimonial-item .item-content {
  padding-left: 15px;
  padding-right: 15px;
}
.testimonial-02 .testimonial-item .item-content .comment {
  font-size: 16px;
}
.testimonial-02 .testimonial-item .item-content .name {
  font-size: 18px;
  color: #9c5b57;
}
.testimonial-02 .testimonial-item .item-content p {
  margin-bottom: 0;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.testimonial-02 .testimonial-item .item-image {
  border-radius: 50%;
  height: 170px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  width: 170px;
}
.testimonial-02 .testimonial-item .item-image img {
  width: 100%;
}
@media only screen and (min-width: 992px ) {
  .testimonial-02.style-2 .testimonial-item .item-content {
    padding-left: 0;
    padding-right: 200px;
    padding-top: 30px;
  }
  .testimonial-02.style-2 .testimonial-item .item-image {
    float: right;
    margin-left: auto;
    margin-right: 15px;
  }
  .testimonial-02 .testimonial-item .item-content {
    padding-left: 200px;
    padding-top: 30px;
  }
  .testimonial-02 .testimonial-item .item-image {
    float: left;
    margin-left: 15px;
  }
}
@media (max-width: 991px ) {
  .testimonial-02 .testimonial-item .item-content {
    margin-top: 30px;
    text-align: center;
  }
}
@media (max-width: 767px ) {
  .testimonial-02 {
    margin-bottom: 80px;
    margin-top: 40px;
  }
  .testimonial-02 .testimonial-item .item-content .comment {
    font-size: 13px;
  }
  .testimonial-02 .testimonial-item .item-content .name {
    margin-top: 15px;
    font-size: 16px;
  }
}
/* Call To Action */
/* MIXIN */
.call-to-action {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 50px 60px;
  width: 100%;
}
.call-to-action:after {
  clear: both;
}
.call-to-action:before,
.call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action:after {
  clear: both;
}
.call-to-action:before,
.call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action a {
  margin: 0 auto;
}
.call-to-action p {
  display: inline-block;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
}
@media only screen and (min-width: 992px ) {
  .call-to-action p {
    float: left;
    margin-bottom: 0;
  }
  .call-to-action a {
    float: right;
  }
}
.call-to-action-wrapper.style-2 .call-to-action {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  padding: 50px 60px;
  width: 100%;
}
.call-to-action-wrapper.style-2 .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper.style-2 .call-to-action:before,
.call-to-action-wrapper.style-2 .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper.style-2 .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper.style-2 .call-to-action:before,
.call-to-action-wrapper.style-2 .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper.style-3 .call-to-action {
  background-color: #333333;
  border: 1px solid #333333;
  padding: 50px 60px;
  width: 100%;
}
.call-to-action-wrapper.style-3 .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper.style-3 .call-to-action:before,
.call-to-action-wrapper.style-3 .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper.style-3 .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper.style-3 .call-to-action:before,
.call-to-action-wrapper.style-3 .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper.style-3 p {
  color: #ffffff;
}
.call-to-action-wrapper.style-4 .border-btn.border-btn-light:hover,
.call-to-action-wrapper.style-4 .border-btn.border-btn-light:focus {
  background-color: #ffffff;
  color: #9c5b57;
}
.call-to-action-wrapper.style-4 .call-to-action {
  background-color: #9c5b57;
  border: 1px solid #9c5b57;
  padding: 50px 60px;
  width: 100%;
}
.call-to-action-wrapper.style-4 .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper.style-4 .call-to-action:before,
.call-to-action-wrapper.style-4 .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper.style-4 .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper.style-4 .call-to-action:before,
.call-to-action-wrapper.style-4 .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper.style-4 p {
  color: #ffffff;
}
.call-to-action-wrapper .call-to-action {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 50px 60px;
  width: 100%;
}
.call-to-action-wrapper .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper .call-to-action:before,
.call-to-action-wrapper .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper .call-to-action:before,
.call-to-action-wrapper .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper .call-to-action:before,
.call-to-action-wrapper .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper .call-to-action:after {
  clear: both;
}
.call-to-action-wrapper .call-to-action:before,
.call-to-action-wrapper .call-to-action:after {
  content: " ";
  display: table;
}
.call-to-action-wrapper .call-to-action a {
  margin: 0 auto;
}
.call-to-action-wrapper .call-to-action p {
  display: inline-block;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
}
@media only screen and (min-width: 992px ) {
  .call-to-action-wrapper .call-to-action p {
    float: left;
    margin-bottom: 0;
  }
  .call-to-action-wrapper .call-to-action a {
    float: right;
  }
}
@media (max-width: 1229px ) {
  .call-to-action-wrapper .call-to-action {
    text-align: center;
  }
  .call-to-action-wrapper .call-to-action .normal-button-1 {
    float: none;
    margin: 0 auto;
  }
  .call-to-action-wrapper .call-to-action p {
    display: block;
  }
}
.call-to-action-2 {
  text-align: center;
}
.call-to-action-2 * {
  color: #ffffff;
}
.call-to-action-2 .container {
  position: relative;
}
.call-to-action-2 img {
  width: 100%;
}
.call-to-action-2 .content {
  padding-top: 146px;
  padding-bottom: 100px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.call-to-action-2 .content a {
  display: inline-block;
  margin-bottom: 18px;
}
.call-to-action-2 .content h3 {
  display: block;
  width: 237px;
  margin-bottom: 0;
  padding: 15px 50px;
  border: 5px solid #ffffff;
}
.call-to-action-2 .content p {
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .call-to-action-2 .content {
    padding-top: 50px;
    padding-bottom: 25px;
  }
}
/* Accordion */
.accordion-1.style-2 .panel-heading {
  border: 1px solid #9c5b57;
  background-color: #9c5b57;
  color: #ffffff;
}
.accordion-1.style-2 .panel-heading h5 {
  color: #ffffff;
}
.accordion-1.style-2 .panel-heading h5 i:before {
  color: #ffffff;
}
.accordion-1.style-2 .panel-heading h5:after {
  color: #ffffff;
}
.accordion-1 .panel {
  margin-bottom: 20px;
}
.accordion-1 .panel:last-of-type {
  margin-bottom: 0;
}
.accordion-1 .panel-body {
  padding: 20px 15px;
}
.accordion-1 .panel-group {
  margin: 0 auto;
  width: 100%;
}
.accordion-1 .panel-heading {
  border: 1px solid #dddddd;
  padding: 12px 15px;
  color: #555555;
}
.accordion-1 .panel-heading a.collapsed h5:after {
  content: "\e07f";
}
.accordion-1 .panel-heading h5 {
  display: block;
  margin: 0;
  padding-right: 29px;
  position: relative;
}
.accordion-1 .panel-heading h5:after {
  content: ":";
  display: block;
  font-size: 24px;
  height: 24px;
  line-height: 1;
  margin: auto;
  position: absolute;
  width: 24px;
  color: #999999;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
  bottom: 0;
  right: 5px;
  top: 0;
}
.accordion-1 .panel-heading i {
  display: inline-block;
  line-height: 26px;
  margin-right: 25px;
  color: #9c5b57;
}
.accordion-1 .panel-heading i:before {
  display: inline-block;
  font-size: 26px;
  vertical-align: middle;
}
@media (max-width: 470px) {
  .accordion-1 .panel-heading h5 {
    font-size: 12px;
    padding-right: 19px;
  }
  .accordion-1 .panel-heading h5:after {
    right: -5px;
  }
  .accordion-1 .panel-heading h5 i {
    display: none;
  }
}
.accordion-3 .panel {
  margin-bottom: 20px;
}
.accordion-3 .panel:last-of-type {
  margin-bottom: 0;
}
.accordion-3 .panel-body {
  padding: 20px 15px;
}
.accordion-3 .panel-group {
  margin: 0 auto;
  width: 100%;
}
.accordion-3 .panel-heading {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  padding: 10px 15px;
  background-color: #f4f4f4;
}
.accordion-3 .panel-heading a.collapsed h5:after {
  content: "\e0ae";
}
.accordion-3 .panel-heading h5 {
  margin: 0;
  font-size: 11px;
}
.accordion-3 .panel-heading h5:after {
  content: "/";
  float: right;
  font-size: 9px;
  color: #999999;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.accordion-3.style-2 .panel-heading {
  background-color: #ffffff;
  padding: 10px 0px;
}
/* Portfolio */
.isotope-container .iso-grid {
  position: relative;
}
.isotope-container .iso-grid:after {
  clear: both;
}
.isotope-container .iso-grid:before,
.isotope-container .iso-grid:after {
  content: " ";
  display: table;
}
.isotope-container .iso-grid:after {
  clear: both;
}
.isotope-container .iso-grid:before,
.isotope-container .iso-grid:after {
  content: " ";
  display: table;
}
.isotope-container .iso-grid .grid-item {
  height: auto;
  padding-bottom: 30px;
}
.isotope-container .remove-col-padding .iso-grid .grid-item {
  padding-bottom: 0;
  padding-top: 0;
}
section.portfolio-section .isotope-container .remove-col-padding {
  margin-bottom: 30px;
}
.isotope-container .container-fluid:not(.remove-col-padding) {
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (min-width: 1230px ) {
  .isotope-container .w20p {
    width: 20%;
  }
}
.portfolio-filter-group {
  padding: 20px 0;
  text-align: center;
}
.portfolio-filter-group .iso-button {
  color: #888888;
  display: inline-block;
  padding: 0 23px;
  font-weight: 600;
  line-height: 1.2;
  transition: all 0.4s ease-in-out;
}
.portfolio-filter-group .iso-button.is-checked,
.portfolio-filter-group .iso-button:hover {
  color: #333333;
}
.portfolio-filter-group .iso-button:not(:first-of-type) {
  border-left: 1px solid #dddddd;
}
.portfolio-group {
  padding-top: 30px;
}
.portfolio-show-more {
  padding: 30px 0 60px;
}
.portfolio-show-more a {
  display: inline-block;
  border-bottom: 1px solid #555555;
  font-weight: 600;
}
.portfolio-more h5 {
  transition: all 0.4s ease-in-out;
  margin-bottom: 10px;
}
.portfolio-more i {
  transition: all 0.4s ease-in-out;
  color: #333333;
}
.portfolio-more:hover {
  background-color: #333333;
}
.portfolio-more:hover i,
.portfolio-more:hover h5 {
  color: #ffffff;
}
.portfolio-item {
  position: relative;
  display: block;
  overflow: hidden;
}
.portfolio-item img {
  width: 100%;
}
.portfolio-item .overlay {
  transition: all 0.4s ease-in-out;
  background-color: rgba(244, 244, 244, 0.9);
  opacity: 0;
}
.dark-overlay .portfolio-item .overlay,
.portfolio-item.dark-overlay .overlay {
  background-color: rgba(51, 51, 51, 0.9);
}
.dark-overlay .portfolio-item,
.portfolio-item.dark-overlay,
.dark-overlay .portfolio-item h1,
.portfolio-item.dark-overlay h1,
.dark-overlay .portfolio-item h2,
.portfolio-item.dark-overlay h2,
.dark-overlay .portfolio-item h3,
.portfolio-item.dark-overlay h3,
.dark-overlay .portfolio-item h4,
.portfolio-item.dark-overlay h4,
.dark-overlay .portfolio-item h5,
.portfolio-item.dark-overlay h5,
.dark-overlay .portfolio-item h6,
.portfolio-item.dark-overlay h6,
.dark-overlay .portfolio-item .heading-icon-1:before,
.portfolio-item.dark-overlay .heading-icon-1:before {
  color: #ffffff;
}
.portfolio-item .overlay-content {
  padding: 20px;
  overflow: auto;
}
.portfolio-item .overlay-content h1,
.portfolio-item .overlay-content h2,
.portfolio-item .overlay-content h3,
.portfolio-item .overlay-content h4,
.portfolio-item .overlay-content h5,
.portfolio-item .overlay-content h6,
.portfolio-item .overlay-content p {
  margin-bottom: 0;
}
.portfolio-item .overlay-content .group-link {
  padding-top: 10px;
}
.portfolio-item .overlay-content .group-link a {
  display: inline-block;
  height: 34px;
  width: 34px;
  border-radius: 17px;
  color: #666666;
  border: 1px solid #666666;
  margin: 5px;
}
.portfolio-item .overlay-content .group-link a:before {
  font-size: 17px;
  line-height: 32px;
  display: block;
}
.portfolio-item .overlay-content .group-link a:hover {
  border-color: #9c5b57;
  color: #ffffff;
  background-color: #9c5b57;
}
.portfolio-item .overlay-content .heading-icon-1:before {
  font-size: 60px;
}
.portfolio-item:hover .overlay {
  opacity: 1;
}
.portfolio-blog .overlay-content i {
  font-size: 120px;
}
.portfolio-blog .overlay-content i:before {
  display: block;
}
.portfolio-blog .overlay-content h4 {
  margin-bottom: 0px;
}
.portfolio-blog .overlay-content .date {
  font-style: normal;
}
.portfolio-item-1 .overlay-content .caption,
.portfolio-item-2 .overlay-content .caption {
  font-size: 14px;
}
.portfolio-item-3 .overlay-content {
  padding: 0 50px;
}
.portfolio-item-3 .overlay-content .heading-icon-1 {
  color: #9c5b57;
  margin-bottom: 20px;
}
.portfolio-item-3 .overlay-content .heading-icon-1:before {
  color: #999999;
  margin-bottom: 10px;
}
.portfolio-item-3 .overlay-content .caption {
  margin-bottom: 10px;
}
.portfolio-item-3 .overlay-content .group-link {
  margin-left: -5px;
}
.portfolio-item-3 .overlay-content .group-link a {
  text-align: center;
}
.portfolio-icon-item .overlay .group-function {
  font-size: 26px;
}
.portfolio-icon-item .overlay .group-function a:first-child {
  padding-right: 20px;
  margin-right: 20px;
  display: inline-block;
  border-right: 1px solid #dddddd;
}
.portfolio-icon-item .overlay .group-function a:first-child:before {
  display: block;
}
.portfolio-footer-title footer {
  transition: all 0.4s ease-in-out;
  border: 1px solid #dddddd;
  padding: 35px 30px;
}
.portfolio-footer-title footer h5 {
  transition: all 0.4s ease-in-out;
  margin-bottom: 0;
}
.portfolio-footer-title footer h5 a {
  transition: all 0s;
}
.portfolio-footer-title footer .subtitle {
  transition: all 0.4s ease-in-out;
  font-size: 14px;
}
.portfolio-footer-title:hover footer {
  border-color: #9c5b57;
  background-color: #9c5b57;
}
.portfolio-footer-title:hover footer h5,
.portfolio-footer-title:hover footer .subtitle {
  color: #ffffff;
}
.portfolio-text .overlay {
  background-color: transparent;
  padding: 30px;
}
.portfolio-text .overlay .overlay-content {
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);
}
.portfolio-text .overlay .overlay-content h5 {
  margin-bottom: 15px;
  font-size: 16px;
}
@media (max-width: 1229px ) {
  .portfolio-index-1 {
    padding-top: 90px;
  }
}
@media only screen and (min-width: 1230px ) {
  .portfolio-index-1 .header-wrapper {
    position: relative;
    display: block;
    height: 0;
    padding-top: 50%;
  }
  .portfolio-index-1 .header-wrapper > *:only-child {
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    max-height: 100%;
    overflow: auto;
  }
  .portfolio-index-1 .section-header.header-type-2.style-1 {
    padding: 0 80px;
    margin-bottom: 0;
  }
  .portfolio-index-1 .section-header.header-type-2.style-1 q {
    margin: 0;
  }
  .portfolio-index-1 .section-header.header-type-2.style-1 .container {
    width: 100% !important;
  }
}
@media (max-width: 767px ) {
  .homepage-portfolio-special-group .container {
    max-width: 400px;
  }
}
.homepage-portfolio-external-filter .label,
.homepage-portfolio-external-filter .go-to-home {
  padding: 20px 0;
}
.homepage-portfolio-external-filter .label {
  margin-right: 20px;
}
.homepage-portfolio-external-filter .go-to-home a:hover {
  color: #9c5b57;
}
.homepage-portfolio-external-filter .go-to-home i:before {
  display: inline-block;
  transform: translateY(3px);
}
.homepage-portfolio-external-filter .portfolio-filter-group {
  float: left;
}
@media (max-width: 991px ) {
  .homepage-portfolio-external-filter .portfolio-filter-group {
    float: none;
  }
}
.homepage-portfolio-external-filter .portfolio-filter-group a {
  font-weight: 400;
  color: #333333;
}
.homepage-portfolio-external-filter .portfolio-filter-group a.is-checked {
  font-weight: 600;
}
@media (max-width: 480px) {
  .homepage-classic-portfolio .container {
    max-width: 270px;
  }
  .homepage-classic-portfolio .container .col-xs-6 {
    width: 100%;
  }
}
/* Blog Homepage */
.post-index {
  margin-bottom: 60px;
}
.post-index:after {
  clear: both;
}
.post-index:before,
.post-index:after {
  content: " ";
  display: table;
}
.post-index:after {
  clear: both;
}
.post-index:before,
.post-index:after {
  content: " ";
  display: table;
}
.post-index .entry-header .post-meta {
  margin-bottom: 10px;
}
.post-index .post-thumbnail,
.post-index .video-container {
  margin-bottom: 40px;
}
.post-index .post-thumbnail a {
  display: block;
  position: relative;
}
.post-index .post-thumbnail img {
  width: 100%;
}
.post-index .entry-content {
  margin-bottom: 45px;
}
.post-index .entry-footer:after {
  clear: both;
}
.post-index .entry-footer:before,
.post-index .entry-footer:after {
  content: " ";
  display: table;
}
.post-index .entry-footer:after {
  clear: both;
}
.post-index .entry-footer:before,
.post-index .entry-footer:after {
  content: " ";
  display: table;
}
.post-index .entry-footer .readmore {
  margin-bottom: 50px;
}
.post-index .entry-footer .author {
  float: left;
}
.post-index .entry-footer .social-01 {
  float: right;
  line-height: 17px;
  margin-right: -15px;
  font-size: 16px;
}
.post-index .entry-footer .social-01 a {
  margin-right: 15px;
}
.post-index.quote {
  padding: 40px 20px;
}
.post-index.quote .entry-header {
  position: relative;
}
.post-index.quote .icon-quote {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 70px;
  width: 70px;
  background-color: #333333;
  color: #ffffff;
  font-size: 45px;
  text-align: center;
  float: left;
}
.post-index.quote .icon-quote:before {
  line-height: 70px;
  display: block;
}
.post-index.quote .entry-content {
  margin-bottom: 20px;
}
.post-index.quote .entry-content blockquote {
  quotes: '"' '"';
  margin: 0;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
}
.post-index.quote .entry-footer .author {
  float: right;
  text-transform: uppercase;
  font-size: 16px;
}
@media only screen and (min-width: 480px) {
  .post-index.quote {
    padding: 60px 60px 60px 30px;
  }
  .post-index.quote .entry-header,
  .post-index.quote .entry-content {
    padding-left: 95px;
  }
  .post-index.quote .icon-quote {
    display: block;
  }
}
.post-index-style-1 {
  padding-bottom: 15px;
}
.post-index-style-1:not(.quote) {
  border-bottom: 1px solid #dddddd;
}
.post-index-style-1:not(.quote) .entry-header {
  text-align: center;
}
.post-index-style-1 .post-title {
  font-size: 24px;
}
.post-index-style-1.quote {
  background-color: #f4f4f4;
}
.post-index-style-2 {
  background-color: #ffffff;
  padding: 0 20px 40px;
}
.post-index-style-2 .post-thumbnail,
.post-index-style-2 .video-container {
  margin: 0 -20px 40px;
}
@media only screen and (min-width: 480px) {
  .post-index-style-2 {
    padding: 0 30px 50px;
  }
  .post-index-style-2 .post-thumbnail,
  .post-index-style-2 .video-container {
    margin: 0 -30px 50px;
  }
}
@media only screen and (min-width: 992px ) {
  .post-index-style-2 {
    padding: 0 70px 50px;
  }
  .post-index-style-2 .post-thumbnail,
  .post-index-style-2 .video-container {
    margin: 0 -70px 50px;
  }
}
.masonry-post-index {
  margin-bottom: 50px;
}
.masonry-post-index:after {
  clear: both;
}
.masonry-post-index:before,
.masonry-post-index:after {
  content: " ";
  display: table;
}
.masonry-post-index:after {
  clear: both;
}
.masonry-post-index:before,
.masonry-post-index:after {
  content: " ";
  display: table;
}
.masonry-post-index .entry-header {
  text-align: center;
}
.masonry-post-index .entry-header img {
  margin-left: auto;
  margin-right: auto;
}
.masonry-post-index .entry-content {
  margin-bottom: 20px;
}
.masonry-post-index .entry-footer {
  margin-bottom: 10px;
}
.masonry-post-index.quote {
  padding: 30px;
  border: 0;
  background-color: #f4f4f4;
}
.masonry-post-index.quote .entry-header {
  position: relative;
}
.masonry-post-index.quote .entry-header .post-title {
  padding-top: 10px;
}
.masonry-post-index.quote .entry-content {
  margin-bottom: 20px;
}
.masonry-post-index.quote .entry-content blockquote {
  quotes: '"' '"';
  margin: 0;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
}
.masonry-post-index.quote .entry-footer .author {
  float: right;
  text-transform: uppercase;
  font-size: 16px;
}
.masonry-post-index-style-1 {
  border-bottom: 1px solid #dddddd;
}
.masonry-post-index-style-1 .post-title {
  padding-top: 40px;
}
.masonry-post-index-style-2:not(.quote) .entry-content {
  padding: 30px 30px 10px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #dddddd;
  text-align: center;
}
/* Post Detail*/
.post-detail-01.style-02 img {
  margin-bottom: 40px;
}
.post-detail-01 .title h2 {
  margin-bottom: 0;
}
.post-detail-01 .title span {
  color: #9c5b57;
}
.post-detail-01 .title p {
  color: #999999;
  margin-bottom: 34px;
}
.post-detail-01 .content {
  margin-bottom: 55px;
}
.post-detail-01 .content img {
  float: left;
  margin-right: 30px;
  margin-bottom: 20px;
}
.post-detail-01 .content blockquote {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  line-height: 2.2;
  margin-bottom: 30px;
  font-size: 14px;
}
.post-detail-01 .tag-share:after {
  clear: both;
}
.post-detail-01 .tag-share:before,
.post-detail-01 .tag-share:after {
  content: " ";
  display: table;
}
.post-detail-01 .tag-share:after {
  clear: both;
}
.post-detail-01 .tag-share:before,
.post-detail-01 .tag-share:after {
  content: " ";
  display: table;
}
.post-detail-01 .tag-share p {
  display: inline-block;
  color: #333333;
  font-weight: 600;
}
.post-detail-01 .tag-share .tag {
  display: inline-block;
}
.post-detail-01 .tag-share .tag span {
  display: inline-block;
  margin-left: 20px;
  color: #9c5b57;
}
.post-detail-01 .tag-share .share {
  float: right;
  display: inline-block;
}
@media (max-width: 580px) {
  .post-detail-01 .tag-share .share {
    float: none;
  }
}
.post-detail-01 .tag-share .share p {
  margin-right: 34px;
  color: #9c5b57;
}
.post-detail-01 .tag-share .share .social-01 {
  display: inline-block;
}
.post-detail-01 .tag-share .share .social-01 li:not(:last-of-type) {
  margin-right: 15px;
}
.post-detail-01 .tag-share .share .social-01 li:hover i:before {
  color: #9c5b57;
}
.post-author {
  padding-top: 50px;
  padding-bottom: 50px;
}
.post-author:after {
  clear: both;
}
.post-author:before,
.post-author:after {
  content: " ";
  display: table;
}
.post-author:after {
  clear: both;
}
.post-author:before,
.post-author:after {
  content: " ";
  display: table;
}
.post-author .avatar {
  float: left;
  width: 200px;
  margin-right: 30px;
}
@media (max-width: 767px ) {
  .post-author .avatar {
    float: none;
    margin: 0 auto 20px auto;
  }
}
.post-author .content {
  position: relative;
}
.post-author .content h4 {
  display: inline-block;
  border-right: 1px solid #dddddd;
  padding-right: 20px;
  line-height: 1;
  margin-right: 20px;
}
.post-author .content .social-01 {
  display: inline-block;
}
.post-author .content .social-01 li {
  margin-right: 20px;
}
.post-author .content .social-01 li:hover i:before {
  color: #9c5b57;
}
.post-comments .big-comment {
  border-bottom: 1px solid #dddddd;
}
.post-comments-style {
  padding-top: 50px;
}
.post-comments-style.style-02 {
  border-top: 1px solid #dddddd;
  margin-left: 100px;
  padding-top: 35px;
}
.post-comments-style.style-02 .image {
  width: 70px;
}
.post-comments-style.style-02 .image img {
  width: 100%;
}
.post-comments-style .image {
  float: left;
  display: inline-block;
  width: 100px;
  margin-right: 30px;
}
.post-comments-style .content {
  position: relative;
  overflow: hidden;
}
.post-comments-style .content p.comment {
  margin-bottom: 10px;
}
.post-comments-style .content .date {
  color: #9c5b57;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
.post-comments-style .content .name {
  display: inline-block;
  font-style: italic;
  line-height: 1;
  padding-right: 30px;
  border-right: 1px solid #dddddd;
  margin-right: 20px;
  font-weight: 600;
  font-family: 'Playfair Display', serif;
}
.post-comments-style .content .like-reply .reply {
  color: #9c5b57;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  display: inline-block;
}
.post-comments-style .content .like-reply .like {
  line-height: 1;
  padding-right: 30px;
  border-right: 1px solid #dddddd;
  margin-right: 20px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  display: inline-block;
}
.post-comments-style .content .like-reply a {
  margin-bottom: 15px;
}
@media (max-width: 480px) {
  .post-comments-style.style-02 {
    margin-left: 50px;
  }
  .post-comments-style .content > .name {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
    display: block;
  }
  .post-comments-style .content > .like-reply > .like {
    padding-right: 15px;
    margin-right: 10px;
  }
  .post-comments-style .content {
    overflow: visible;
  }
}
.post-comments-form {
  padding-top: 50px;
}
.post-comments-form .image {
  float: left;
  width: 100px;
}
.post-comments-form .widget.contact-us {
  margin-left: 130px;
}
.post-comments-form .widget.contact-us .normal-btn.btn-size-5 {
  padding: 15px 60px;
}
@media (max-width: 767px ) {
  .post-comments-form .image {
    float: none;
    margin: 0 auto 30px auto;
  }
  .post-comments-form .widget.contact-us {
    margin-left: 0;
  }
}
/* Portfolio Special */
.portfolio-special-style-01 .image {
  display: inline-block;
  position: relative;
}
.portfolio-special-style-01 .image .overlay {
  background-color: rgba(244, 244, 244, 0.9);
  opacity: 0;
}
.portfolio-special-style-01 .image .overlay:hover {
  opacity: 1;
}
.portfolio-special-style-01 .image .overlay a {
  border: 1px solid #777777;
  border-radius: 50%;
  display: inline-block;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  height: 28px;
  width: 28px;
}
.portfolio-special-style-01 .image .overlay a:first-of-type {
  margin-right: 15px;
}
.portfolio-special-style-01 .image .overlay a:hover {
  border-color: #9c5b57;
  background-color: #9c5b57;
  color: #ffffff;
}
.portfolio-special-style-01 .image .overlay h5 {
  color: #9c5b57;
}
.portfolio-special-style-01 .image img {
  width: 100%;
}
.portfolio-special-style-01 .portfolio-special-item-01 .content {
  bottom: 0;
  padding: 70px;
  position: absolute;
}
.portfolio-special-style-01 .portfolio-special-item-01.style-02 .content {
  padding: 0 70px;
  position: inherit;
}
@media (max-width: 767px ) {
  .portfolio-special-style-01 .portfolio-special-item-01 .overlay {
    text-align: center;
  }
  .portfolio-special-style-01 .portfolio-special-item-01 .overlay .content {
    padding: 0;
    position: relative;
  }
  .portfolio-special-style-01 .portfolio-special-item-01 .overlay .content p {
    display: none;
  }
}
.portfolio-special-style-02 .image {
  display: inline-block;
  position: relative;
}
.portfolio-special-style-02 .image .overlay {
  opacity: 0;
}
.portfolio-special-style-02 .image .overlay:hover {
  opacity: 1;
}
.portfolio-special-style-02 .image .overlay .content {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 35px;
}
.portfolio-special-style-02 .image .overlay h5 {
  color: #9c5b57;
}
.portfolio-special-style-02 .image img {
  width: 100%;
}
.portfolio-special-style-02 .portfolio-special-item-03 .image .overlay {
  bottom: 0;
  padding: 45px;
}
.portfolio-special-style-02 .portfolio-special-item-03 .image .overlay .content {
  bottom: 0;
  position: absolute;
}
@media (min-width: 992px) and (max-width: 1230px) {
  .portfolio-special-style-02 .portfolio-special-item-03 .image .overlay {
    padding: 0;
  }
  .portfolio-special-style-02 .portfolio-special-item-03 .image .overlay .content {
    position: relative;
    bottom: auto;
  }
}
.portfolio-special-style-02 .portfolio-special-item-03 .image .overlay .content-wrapper {
  position: relative;
}
.portfolio-special-style-02 .portfolio-special-item-04 .content {
  height: 100%;
  width: 100%;
}
/* Tab */
.tab-section-01 .content > .tab-pane {
  display: none;
}
.tab-section-01 .content > .active {
  display: block;
}
.tab-section-01 .content {
  padding: 35px 35px 35px 0;
}
.tab-section-01 .content p:last-of-type {
  margin-bottom: 0;
}
.tab-section-01 .tabs li {
  display: inline-block;
  border: 1px solid #dddddd;
}
.tab-section-01 .tabs li a {
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-size: 11px;
}
.tab-section-01.style-1 .tabs li {
  margin-right: 5px;
}
.tab-section-01.style-1 .tabs li a {
  background-color: #eeeeee;
  font-size: 11px;
  font-weight: 700;
  outline: none;
  padding: 10px 40px;
}
.tab-section-01.style-1 .tabs li.active a,
.tab-section-01.style-1 .tabs li.active a:focus,
.tab-section-01.style-1 .tabs li.active a:hover {
  color: #ffffff;
  background-color: #9c5b57;
  border-color: #9c5b57;
  cursor: default;
}
.tab-section-01.style-1 .tabs li:hover a {
  background-color: #ffffff;
}
.tab-section-01.style-2 .content {
  padding: 35px;
  border: 1px solid #dddddd;
  border-top: none;
}
.tab-section-01.style-2 .tabs {
  position: relative;
  letter-spacing: -4px;
  text-align: left;
  border-bottom: 1px solid #dddddd;
}
.tab-section-01.style-2 .tabs > li {
  letter-spacing: 0;
  margin-right: -1px;
  margin-bottom: -1px;
}
.tab-section-01.style-2 .tabs > li:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: transparent;
}
.tab-section-01.style-2 .tabs > li a {
  padding: 10px 30px;
}
.tab-section-01.style-2 .tabs > li a:hover {
  background-color: #eeeeee;
}
.tab-section-01.style-2 .tabs > li.active {
  border-bottom-color: white;
  border-top: 1px solid #9c5b57;
}
.tab-section-01.style-2 .tabs > li.active:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #9c5b57;
}
.tab-section-01.style-2 .tabs > li.active a,
.tab-section-01.style-2 .tabs > li.active a:hover,
.tab-section-01.style-2 .tabs > li.active a:focus {
  cursor: default;
  background-color: #ffffff;
}
.tab-section-01.style-3 .content {
  padding: 35px;
  border: 1px solid #dddddd;
  border-top: none;
}
.tab-section-01.style-3 .tabs {
  position: relative;
  letter-spacing: -4px;
  text-align: right;
  border-bottom: 1px solid #dddddd;
}
.tab-section-01.style-3 .tabs > li {
  letter-spacing: 0;
  margin-right: -1px;
  margin-bottom: -1px;
}
.tab-section-01.style-3 .tabs > li:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: transparent;
}
.tab-section-01.style-3 .tabs > li a {
  padding: 10px 30px;
}
.tab-section-01.style-3 .tabs > li a:hover {
  background-color: #eeeeee;
}
.tab-section-01.style-3 .tabs > li.active {
  border-bottom-color: white;
  border-top: 1px solid #9c5b57;
}
.tab-section-01.style-3 .tabs > li.active:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #9c5b57;
}
.tab-section-01.style-3 .tabs > li.active a,
.tab-section-01.style-3 .tabs > li.active a:hover,
.tab-section-01.style-3 .tabs > li.active a:focus {
  cursor: default;
  background-color: #ffffff;
}
@media (max-width: 767px ) {
  .tab-section-01 .tabs li {
    display: block;
    margin-bottom: 20px;
  }
  .tab-section-01 .tabs li a {
    width: 100%;
    padding: 10px 35px;
  }
}
/* Count */
.count-section-01 {
  letter-spacing: -4px;
  text-align: center;
}
.count-section-01.style-2 {
  background-color: #eeeeee;
}
.count-section-01.style-3 .timer {
  color: #9c5b57;
}
.count-section-01.style-3 .counter > p {
  color: #777777;
}
.count-section-01.style-4 {
  position: relative;
  z-index: 0;
}
.count-section-01.style-4:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  z-index: -1;
}
.count-section-01.style-4 .timer {
  color: #ffffff;
}
.count-section-01.style-4 .counter {
  border: 1px solid #999999;
  border-bottom: none;
  border-top: none;
}
.count-section-01.style-4 .counter > p {
  color: #ffffff;
}
.count-section-01.style-4 .counter-group {
  z-index: 500;
}
@media only screen and (min-width: 1230px ) {
  .count-section-01 .count-wrapper {
    float: left;
    width: 20%;
    margin-right: -1px;
  }
}
.count-section-01 .counter {
  border: 1px solid #eeeeee;
  border-bottom: none;
  border-top: none;
  letter-spacing: -4px;
  display: inline-block;
  text-align: center;
  width: 100%;
  max-width: 235px;
}
.count-section-01 .counter .timer {
  display: block;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
}
@media (max-width: 1229px ) {
  .count-section-01 .counter .timer {
    font-size: 50px;
  }
}
.count-section-01 .counter p {
  display: block;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
  font-style: italic;
  color: #9c5b57;
  font-family: 'Playfair Display', serif;
}
/* Page */
.back-to-home {
  color: #9c5b57;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  margin-bottom: 15px;
  font-size: 10px;
}
.back-to-home:after {
  display: block;
  margin: 0 auto;
  width: 72px;
  border-top: 1px solid #9c5b57;
  content: "";
}
.page-contact .map-iframe {
  margin-bottom: 15px;
  width: 100%;
  height: 457px;
  border: #eeeeee;
}
.page-contact .social-03 {
  line-height: 1;
  text-align: center;
}
.page-contact .social-03 li {
  display: inline-block;
}
.page-contact .social-03 li a {
  display: inline-block;
  color: #999999;
}
.page-contact .social-03 li a i:before {
  font-size: 13px;
  margin-right: 10px;
  vertical-align: middle;
  font-family: FontAwesome;
}
.page-contact .social-03 li a i {
  color: #9c5b57;
  font-size: 13px;
}
.page-contact .social-03 li:last-of-type {
  border-right: none;
}
.page-contact .social-03 li {
  padding: 0 10px;
  border-right: 1px solid #dddddd;
}
.page-contact h3 {
  margin-bottom: 50px;
}
.page-contact h5 {
  color: #9c5b57;
  margin-bottom: 10px;
}
/* Page-About */
.page-about-header {
  padding: 80px 0 55px 0;
}
.page-about-header h5 {
  margin-bottom: 10px;
}
.page-about {
  background-color: #f4f4f4;
  padding: 90px 0;
}
.page-about .quote h5 {
  color: #9c5b57;
  float: right;
}
.page-about .quote p {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  margin-bottom: 17px;
  font-size: 16px;
}
.page-about h4 {
  margin: 0;
  padding-bottom: 35px;
}
/* Page-Services */
.page-services .content:after {
  clear: both;
}
.page-services .content:before,
.page-services .content:after {
  content: " ";
  display: table;
}
.page-services .content:after {
  clear: both;
}
.page-services .content:before,
.page-services .content:after {
  content: " ";
  display: table;
}
.page-services .content a {
  float: right;
}
.page-services .content h4 {
  margin: 0;
}
.page-services .content h4:after {
  margin-top: 30px;
}
.page-services .content p {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  margin: 46px 0;
  font-size: 16px;
}
.page-services .logo li {
  display: inline-block;
  padding-right: 20px;
  padding-bottom: 50px;
}
@media only screen and (min-width: 992px ) {
  .page-services .logo li:nth-of-type(3n+3) {
    padding-right: 0;
  }
}
@media (max-width: 1229px ) {
  .page-services .logo li {
    padding-right: 10px;
  }
}
@media (max-width: 991px ) {
  .page-services .logo li {
    padding-right: 60px;
  }
  .page-services .logo li:nth-of-type(3n+3) {
    padding-right: 60px;
  }
}
.page-services .logo ul {
  display: inline-block;
  padding-left: 25px;
  text-align: center;
}
/* Page 404Page */
.page-error {
  padding-top: 50px;
  padding-bottom: 50px;
}
.page-error h1 {
  color: #555555;
  margin-bottom: 20px;
}
.page-error h4 {
  color: #999999;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  margin-bottom: 60px;
}
.page-error .footer-foot {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
/* Page  Under Construction*/
.page-under-construction .page-under {
  background-color: #f4f4f4;
  padding: 140px 0;
}
.page-under-construction .page-under:after {
  clear: both;
}
.page-under-construction .page-under:before,
.page-under-construction .page-under:after {
  content: " ";
  display: table;
}
.page-under-construction .page-under:after {
  clear: both;
}
.page-under-construction .page-under:before,
.page-under-construction .page-under:after {
  content: " ";
  display: table;
}
.page-under-construction .page-under .content {
  display: inline-block;
  padding-left: 30px;
}
@media (max-width: 991px ) {
  .page-under-construction .page-under .content > form > a {
    padding: 10px;
    line-height: 24px;
  }
  .page-under-construction .page-under .content > h1 {
    font-size: 25px;
  }
}
.page-under-construction .page-under .content form {
  position: relative;
  padding-bottom: 35px;
  max-width: 426px;
}
.page-under-construction .page-under .content form a {
  position: absolute;
  right: 0;
}
.page-under-construction .page-under .content form input {
  width: 100%;
  border: 1px solid #dddddd;
}
.page-under-construction .page-under .content h1 {
  font-family: 'Playfair Display', serif;
}
.page-under-construction .page-under .content h4 {
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.page-under-construction .page-under .image {
  display: inline-block;
  float: right;
}
@media (max-width: 991px ) {
  .page-under-construction .page-under .image {
    padding-bottom: 70px;
  }
}
.page-under-construction .page-under .image img {
  width: 100%;
}
.page-under-construction .page-under .social-01 a {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #999999;
  text-align: center;
  font-size: 15px;
}
.page-under-construction .page-under .social-01 a:hover {
  background-color: #9c5b57;
}
.page-under-construction .page-under .social-01 a i {
  line-height: 30px;
}
.page-under-construction .page-under .social-01 a i:before {
  color: #ffffff;
}
.page-under-construction .page-under .social-01 li {
  padding: 0 4px;
}
.page-under-construction .footer-foot {
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
/* Page Login */
.page-login-wrapper .page-login {
  text-align: center;
}
.page-login-wrapper .page-login .back-to-home {
  color: #ffffff;
}
.page-login-wrapper .page-login .back-to-home:after {
  border-color: #ffffff;
}
.page-login-wrapper .page-login .button {
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
}
.page-login-wrapper .page-login .button:nth-of-type(4) {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #cccccc;
}
.page-login-wrapper .page-login .button:nth-of-type(4):after {
  position: absolute;
  bottom: -20px;
  left: 50%;
  display: block;
  padding: 5px;
  background-color: #ffffff;
  content: "OR";
  transform: translateX(-50%);
}
.page-login-wrapper .page-login .button a {
  width: 100%;
}
.page-login-wrapper .page-login .remember {
  margin-bottom: 25px;
  text-align: right;
}
.page-login-wrapper .page-login .remember .checkbox {
  display: inline-block;
}
.page-login-wrapper .page-login .remember .checkbox [type=checkbox]:checked + span {
  position: relative;
}
.page-login-wrapper .page-login .remember .checkbox [type=checkbox]:checked + span:after {
  color: #9c5b57;
  font-family: "wolverine";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "W";
  line-height: 14px;
}
.page-login-wrapper .page-login .remember .checkbox input {
  display: none;
}
.page-login-wrapper .page-login .remember .checkbox span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #dddddd;
  vertical-align: middle;
}
.page-login-wrapper .page-login .remember p {
  display: inline-block;
  margin: 0;
  color: #777777;
}
.page-login-wrapper .page-login form {
  background-color: #ffffff;
  position: relative;
  margin: 0 auto 75px auto;
  padding: 50px;
  max-width: 400px;
}
.page-login-wrapper .page-login form h3 {
  margin-bottom: 0;
}
.page-login-wrapper .page-login form p {
  margin-bottom: 30px;
  font-family: 'Playfair Display', serif;
}
.page-login-wrapper .page-login form .infor {
  position: relative;
  float: right;
  margin-bottom: 20px;
  width: 100%;
}
.page-login-wrapper .page-login form .infor .icon {
  color: #999999;
  position: absolute;
  top: 50%;
  left: 15px;
  z-index: 1;
  transform: translateY(-50%);
}
.page-login-wrapper .page-login form .infor .icon i:before {
  vertical-align: middle;
  font-size: 19px;
}
.page-login-wrapper .page-login form .infor input {
  display: block;
  margin-bottom: 0;
  padding-left: 50px;
  width: 100%;
  border: 1px solid #dddddd;
  background: white;
}
.page-login-wrapper .page-login form a.forgotpass {
  color: #ffffff;
  position: absolute;
  top: 100%;
  left: 0;
}
.page-login-wrapper .page-login form p {
  color: #9c5b57;
}
.page-login-wrapper .footer-foot {
  background-color: #f4f4f4;
}
/* REGSITER */
.page-rigsiter-wrapper .footer-foot {
  background-color: #f4f4f4;
}
.page-rigsiter form {
  background-color: #ffffff;
  position: relative;
  margin: 0 auto;
  padding: 50px;
  max-width: 570px;
}
.page-rigsiter form .button {
  text-align: center;
}
.page-rigsiter form .name {
  position: relative;
}
.page-rigsiter form .name:after {
  clear: both;
}
.page-rigsiter form .name:before,
.page-rigsiter form .name:after {
  content: " ";
  display: table;
}
.page-rigsiter form .name:after {
  clear: both;
}
.page-rigsiter form .name:before,
.page-rigsiter form .name:after {
  content: " ";
  display: table;
}
.page-rigsiter form .name input {
  float: left;
  display: block;
  width: calc(50% - 15px);
}
.page-rigsiter form .name input:first-of-type {
  margin-right: 30px;
}
.page-rigsiter form h3 {
  margin-bottom: 50px;
  text-align: center;
}
.page-rigsiter form input {
  margin-bottom: 20px;
  width: 100%;
  border-color: #dddddd;
}
.page-rigsiter form p {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  margin-bottom: 5px;
  font-size: 11px;
}
.page-rigsiter form span {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  color: #999999;
  display: block;
  margin-bottom: 55px;
}
.page-rigsiter form span a {
  color: #9c5b57;
  text-decoration: underline;
}
/* Page  Under Construction */
.page-under-construction-2-wrapper .parallax-bg {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.page-under-construction-2-wrapper .parallax-bg:after {
  background-color: transparent;
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.page-under-construction-2-wrapper .parallax-bg:after {
  background-color: transparent;
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.page-under-construction-2 {
  padding: 150px 0 100px;
  width: 100%;
}
.page-under-construction-2 .clock-canvas {
  height: 0;
  border-radius: 50%;
}
.page-under-construction-2 .clock-canvas:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border: 6px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  content: " ";
}
.page-under-construction-2 .clock-item {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  width: 150px;
  height: 150px;
}
.page-under-construction-2 .text {
  position: absolute;
  top: 50%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  transform: translateY(-50%);
}
.page-under-construction-2 .text .type-time {
  margin: 0;
  font-weight: 600;
}
.page-under-construction-2 .text .val {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  font-size: 55px;
  line-height: 55px;
}
.page-under-construction-2 h1 {
  margin: 0;
}
.page-under-construction-2 h1,
.page-under-construction-2 h4,
.page-under-construction-2 h1:before {
  color: #ffffff;
}
.page-under-construction-2 h4 {
  margin: 30px 0 55px;
}
.subcribe {
  background-color: #f4f4f4;
  padding: 80px 0;
}
.subcribe p {
  margin-bottom: 40px;
}
.subcribe h4.heading-icon-1 {
  margin-bottom: 10px;
}
.subcribe .social-03 {
  line-height: 1;
  text-align: center;
}
.subcribe .social-03 li {
  display: inline-block;
}
.subcribe .social-03 li a {
  display: inline-block;
  color: #9c5b57;
}
.subcribe .social-03 li a i:before {
  font-size: 13px;
  margin-right: 10px;
  vertical-align: middle;
  font-family: FontAwesome;
}
.subcribe .social-03 li a i {
  color: #999999;
  font-size: 13px;
}
.subcribe .social-03 li:last-of-type {
  border-right: none;
}
.subcribe .social-03 li {
  padding: 0 10px;
  border-right: 1px solid #dddddd;
}
.subcribe form {
  position: relative;
  top: 0;
  right: 0;
  margin: 0 auto;
  padding-bottom: 35px;
  max-width: 426px;
  text-align: center;
}
.subcribe form a {
  position: absolute;
  right: 0;
  height: 44px;
}
.subcribe form input {
  width: 100%;
  border: 1px solid #dddddd;
}
/* Undercontruction 3 */
.underconstruction-3-header {
  padding-top: 30px;
  padding-bottom: 40px;
}
.underconstruction-3-header img {
  height: 50px;
}
.underconstruction-3-hr {
  border-top: 1px solid rgba(221, 221, 221, 0.2);
}
.page-under-construction-3 {
  padding-top: 90px;
}
.page-under-construction-3 .underconstruction-container h1,
.page-under-construction-3 .underconstruction-container h4 {
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.page-under-construction-3 .underconstruction-container h4 {
  margin-bottom: 15px;
}
.page-under-construction-3 .underconstruction-container h1 {
  font-style: normal;
  margin-bottom: 60px;
}
.page-under-construction-3 .clock-canvas {
  height: 0;
  border-radius: 50%;
}
.page-under-construction-3 .clock-canvas:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border: 6px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  content: " ";
}
.page-under-construction-3 .clock-item {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  width: 150px;
  height: 150px;
}
.page-under-construction-3 .countdown {
  text-align: center;
  margin-bottom: 90px;
}
.page-under-construction-3 .subcribe {
  padding: 80px 0;
  background-color: transparent;
}
.page-under-construction-3 .subcribe .social-03 {
  line-height: 1;
  text-align: center;
}
.page-under-construction-3 .subcribe .social-03 li {
  display: inline-block;
}
.page-under-construction-3 .subcribe .social-03 li a {
  display: inline-block;
  color: #dddddd;
}
.page-under-construction-3 .subcribe .social-03 li a i:before {
  font-size: 13px;
  margin-right: 10px;
  vertical-align: middle;
  font-family: FontAwesome;
}
.page-under-construction-3 .subcribe .social-03 li a i {
  color: #dddddd;
  font-size: 13px;
}
.page-under-construction-3 .subcribe .social-03 li:last-of-type {
  border-right: none;
}
.page-under-construction-3 .subcribe .social-03 li {
  padding: 0 10px;
  border-right: 1px solid #dddddd;
}
.page-under-construction-3 .subcribe form {
  position: relative;
  top: 0;
  right: 0;
  margin: 0 auto;
  padding-bottom: 35px;
  max-width: 426px;
  text-align: center;
}
.page-under-construction-3 .subcribe form a {
  position: absolute;
  right: 0;
  height: 44px;
}
.page-under-construction-3 .subcribe form input {
  width: 100%;
  border: 1px solid #dddddd;
  background-color: rgba(255, 255, 255, 0.8);
}
.page-under-construction-3 .subcribe p,
.page-under-construction-3 .subcribe h4,
.page-under-construction-3 .subcribe h4:before {
  color: #ffffff;
}
.page-under-construction-3 .text {
  position: absolute;
  top: 58%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  transform: translateY(-50%);
}
.page-under-construction-3 .text .type-time {
  margin-top: 20px;
  margin-bottom: 0;
  font-family: 'Playfair Display', serif;
}
.page-under-construction-3 .text .val {
  margin-bottom: 0;
  font-size: 55px;
  line-height: 15px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
.page-under-construction-3 .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(85, 85, 85, 0.5);
}
.page-under-construction-3 h1,
.page-under-construction-3 h4,
.page-under-construction-3 h1:before {
  color: #ffffff;
}
.page-under-construction-3 h4 {
  margin: 0;
}
/* Footer */
footer:after {
  clear: both;
}
footer:before,
footer:after {
  content: " ";
  display: table;
}
footer:after {
  clear: both;
}
footer:before,
footer:after {
  content: " ";
  display: table;
}
.footer-quote .content {
  padding-top: 50px;
  margin-bottom: 15px;
}
.footer-quote .content a {
  color: #9c5b57;
}
.footer-quote .icon {
  display: inline-block;
  width: 100%;
}
.footer-quote .icon .social-02 {
  display: inline-block;
  padding-left: 15px;
}
.footer-quote .icon .social-02 a:hover i:before {
  color: #9c5b57;
}
.footer-quote .icon .social-02 li {
  padding-right: 15px;
}
.footer-quote .icon p {
  color: #9c5b57;
  font-style: italic;
  font-family: 'Playfair Display', serif;
  display: inline-block;
  float: left;
  margin: 0;
}
.footer-quote-01 .content {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 25px;
}
.footer-quote-01 .content a {
  text-align: center;
  font-weight: 800;
  font-size: 10px;
}
.footer-quote-01 .content a:before {
  background-color: #9c5b57;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  color: #ffffff;
  content: "\e07f";
  vertical-align: middle;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
}
.footer-quote-02 .content {
  border-bottom: 1px solid #555555;
}
.footer-quote-02 .content a {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  display: inline-block;
}
.footer-quote-02 .content a:first-of-type {
  color: #dddddd;
}
.footer-quote-02 .content a:last-of-type {
  float: right;
}
.footer-quote-03 .content {
  border-bottom: 1px solid #cccccc;
}
.footer-quote-03 .content a {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  display: inline-block;
}
.footer-quote-03 .content a:first-of-type {
  color: #999999;
}
.footer-quote-03 .content a:last-of-type {
  float: right;
}
.footer-preset-wrapper {
  padding-top: 80px;
  padding-bottom: 50px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.footer-preset-wrapper:after {
  clear: both;
}
.footer-preset-wrapper:before,
.footer-preset-wrapper:after {
  content: " ";
  display: table;
}
.footer-preset-wrapper:after {
  clear: both;
}
.footer-preset-wrapper:before,
.footer-preset-wrapper:after {
  content: " ";
  display: table;
}
.footer-preset-wrapper h5 {
  margin-bottom: 0;
  padding-bottom: 50px;
}
.footer-preset-02 {
  background-color: #f4f4f4;
}
.footer-preset-02 .footer-preset-wrapper {
  border-color: #dddddd;
}
.footer-preset-03 {
  background-color: #333333;
}
.footer-preset-03 .footer-foot {
  background-color: transparent;
}
.footer-preset-03 .footer-preset-wrapper {
  border-color: #555555;
}
.footer-preset-03 .footer-preset-wrapper h5 {
  color: #ffffff;
}
.footer-preset-03 p {
  color: #999999;
}
.footer-preset-04 {
  padding: 60px 0;
  text-align: center;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.footer-preset-04 h1 {
  letter-spacing: 3px;
  font-family: 'Playfair Display', serif;
}
.footer-preset-04 .social-04 {
  padding-bottom: 50px;
}
.footer-preset-04 .social-04 a {
  margin-right: 12px;
  margin-left: 12px;
}
.footer-preset-04 .social-04 a:hover {
  color: #999999;
}
.footer-preset-04 p {
  color: #999999;
  margin-bottom: 0px;
}
.footer-preset-05 .footer-preset-wrapper {
  border-color: #dddddd;
}
.footer-preset-05 .footer-foot {
  background-color: #ffffff;
  border-bottom-color: #dddddd;
}
.footer-preset-06 {
  background-color: #f4f4f4;
}
.footer-preset-06 .footer-preset-wrapper {
  border-color: #dddddd;
}
.footer-foot {
  padding: 20px 0;
  background-color: #f4f4f4;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
.footer-foot p {
  font-size: 11px;
  font-weight: 500;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
}
.footer-foot-border {
  padding: 20px 0;
  border-top: 1px solid #999999;
  border: 1px solid #dddddd;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  background-color: #f4f4f4;
}
.footer-foot-border p {
  font-size: 11px;
  font-weight: 500;
  margin: 0;
}
.sticky-button {
  position: fixed;
  bottom: 0px;
  z-index: 9999;
}
.purchase-bottom {
  right: 80px;
  position: fixed;
  bottom: 0px;
  z-index: 9999;
}
.referrals-bottom {
  right: 20px;
  position: fixed;
  bottom: 0px;
  z-index: 9999;
}
/* Shop Slider */
.shop-gallery-carousel {
  margin-bottom: 75px;
}
.shop-gallery-carousel .shop-item .item-image .layout-after {
  display: inline-block;
}
.shop-gallery-carousel .shop-item .item-image .layout-after:after {
  opacity: 0;
}
.shop-gallery-carousel .shop-item .item-image .layout-after:hover:after {
  opacity: 1;
}
.shop-gallery-carousel .shop-item .item-image .name {
  margin-top: 5px;
}
.shop-gallery-carousel .shop-item .item-image .name a {
  display: inline-block;
  max-width: 170px;
}
.shop-gallery-carousel .shop-item .item-image .name a:hover {
  color: #9c5b57;
}
.shop-gallery-carousel .shop-item .item-image img {
  margin-left: auto;
  margin-right: auto;
}
.shop-gallery-carousel .slick-next {
  right: -30px;
}
.shop-gallery-carousel .slick-next:before {
  content: "\e07e";
}
.shop-gallery-carousel .slick-prev {
  left: -30px;
}
.shop-gallery-carousel .slick-prev:before {
  content: "\e031";
}
.shop-gallery-carousel .slick-prev,
.shop-gallery-carousel .slick-next {
  height: 25px;
  top: 85px;
  width: 25px;
}
.shop-gallery-carousel .slick-prev:before,
.shop-gallery-carousel .slick-next:before {
  font-size: 25px;
  color: #999999;
  font-family: "wolverine";
}
.shop-gallery-carousel .slick-prev:hover:before,
.shop-gallery-carousel .slick-next:hover:before {
  color: #333333;
}
@media (max-width: 1300px) {
  .shop-gallery-carousel .slick-next {
    right: 50%;
    transform: translateX(36px);
  }
  .shop-gallery-carousel .slick-next:before {
    content: "\e07f";
  }
  .shop-gallery-carousel .slick-prev {
    left: 50%;
    transform: translateX(-36px);
  }
  .shop-gallery-carousel .slick-prev:before {
    content: "\e032";
  }
  .shop-gallery-carousel .slick-prev,
  .shop-gallery-carousel .slick-next {
    bottom: -35px;
    margin-top: 0;
    top: auto;
  }
}
/* Shop */
.section-shop-01 .image {
  display: inline-block;
  position: relative;
}
.section-shop-01 .image .overlay {
  opacity: 0;
  text-align: center;
  background-color: rgba(119, 119, 119, 0.5);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.section-shop-01 .image .overlay a.zoom {
  background-color: #555555;
  border-radius: 50%;
  display: table;
  margin: 0 auto;
  height: 44px;
  width: 44px;
}
.section-shop-01 .image .overlay h5 {
  display: none;
}
.section-shop-01 .image .overlay i {
  display: table-cell;
  font-size: 15px;
  vertical-align: middle;
  color: #ffffff;
}
.section-shop-01 .image .overlay i:before {
  vertical-align: middle;
}
.section-shop-01 .image img {
  width: 100%;
}
.section-shop-01 .info {
  padding-top: 25px;
}
.section-shop-01 .info .price {
  font-weight: 600;
  padding-top: 20px;
}
.section-shop-01 .info .price del {
  margin-right: 15px;
}
.section-shop-01 .info a {
  float: right;
  line-height: 20px;
  position: relative;
}
.section-shop-01 .info a:before {
  content: "\e064";
  display: block;
  font-size: 13px;
  position: absolute;
  right: 100%;
  color: #9c5b57;
  font-family: "wolverine";
}
.section-shop-01 .info a i {
  font-size: 20px;
}
.section-shop-01 .info p {
  color: #777777;
  display: inline-block;
  font-weight: 600;
  margin: 0;
}
.section-shop-01 .shop-product-01 {
  max-width: 370px;
  padding-bottom: 75px;
}
.section-shop-01 .shop-product-01:hover .info i {
  color: #9c5b57;
}
.section-shop-01 .shop-product-01:hover .overlay {
  opacity: 1;
}
.section-shop-01 .shop-product-wrapper:last-of-type .image .overlay {
  opacity: 1;
  background-color: #f4f4f4;
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.section-shop-01 .shop-product-wrapper:last-of-type .image .overlay .title {
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.section-shop-01 .shop-product-wrapper:last-of-type .image .overlay .title h5 {
  display: table-cell;
  vertical-align: middle;
}
.section-shop-01 .shop-product-wrapper:last-of-type .image .overlay .zoom {
  display: none;
}
.section-shop-01 .shop-product-wrapper:last-of-type .image .overlay h5 {
  display: block;
}
.section-shop-01 .shop-product-wrapper:last-of-type .image .overlay h5:after {
  content: "\e278";
  display: block;
  font-size: 20px;
  color: #999999;
  font-family: "wolverine";
}
.section-shop-01 .shop-product-wrapper:last-of-type .info {
  display: none;
}
.section-shop-01 .shop-product-wrapper:nth-of-type(3n+1) span {
  color: #9c5b57;
}
.product-listing-2 {
  float: left;
}
.product-listing-2.caro .shop-product-2-wrapper:nth-of-type(2n+1) .shop-product-2 .image {
  background-color: #ffffff;
}
.product-listing-2.caro .shop-product-2-wrapper:nth-of-type(2n+1) .shop-product-2 .info {
  background-color: #f4f4f4;
}
.shop-product-2 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}
.shop-product-2:hover .overlay {
  opacity: 1;
}
.shop-product-2 .image {
  background-color: #EBEBEB;
  display: inline-block;
  width: 100%;
  position: relative;
}
.shop-product-2 .image img {
  width: 100%;
}
.shop-product-2 .image .overlay {
  opacity: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 512;
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.shop-product-2 .image ul {
  display: block;
  position: absolute;
  height: 44px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}
.shop-product-2 .image ul:after {
  clear: both;
}
.shop-product-2 .image ul:before,
.shop-product-2 .image ul:after {
  content: " ";
  display: table;
}
.shop-product-2 .image ul:after {
  clear: both;
}
.shop-product-2 .image ul:before,
.shop-product-2 .image ul:after {
  content: " ";
  display: table;
}
.shop-product-2 .image ul li {
  display: inline-block;
}
.shop-product-2 .image ul li > a {
  color: #ffffff;
  width: 44px;
  height: 44px;
  line-height: 44px;
  display: inline-block;
  position: relative;
  border-radius: 50%;
  margin-left: 8px;
  margin-right: 8px;
}
.shop-product-2 .image ul li > a i {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  display: block;
  line-height: 1;
}
.shop-product-2 .image a.add-to-cart {
  background-color: #9c5b57;
  transform: translateY(-30px);
}
.shop-product-2 .image a.add-to-cart i {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.shop-product-2 .image a.add-to-cart:hover {
  color: #ffffff;
  background-color: #333333;
}
.shop-product-2 .image a.quick-view {
  background-color: #333333;
  transform: translateY(30px);
}
.shop-product-2 .image a.quick-view i {
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.shop-product-2 .image a.quick-view:hover {
  background-color: #9c5b57;
}
.shop-product-2 .image:hover .overlay {
  opacity: 1;
}
.shop-product-2 .image:hover a.add-to-cart {
  transform: translateY(0);
}
.shop-product-2 .image:hover a.quick-view {
  transform: translateY(0);
}
.shop-product-2 .info {
  padding: 30px 0;
}
.shop-product-2 .info > a {
  color: #777777;
  display: inline-block;
  margin: 0;
}
.shop-product-2 .info > a:hover {
  color: #9c5b57;
}
.shop-product-2 .info .price {
  line-height: 1.6;
}
.shop-product-2 .info .price del,
.shop-product-2 .info .price span {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  font-size: 16px;
}
.shop-product-2 .info .price del {
  margin-right: 20px;
  color: #999999;
}
.shop-product-2 .info .price span {
  color: #9c5b57;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.shop-product-2 .info .star-ratings[data-rating="0"] {
  visibility: hidden;
}
.shop-product-2 .info .star-ratings span {
  font-size: 18px;
}
.shop-product-2 .saleoff {
  font-weight: 600;
  margin: 20px 15px 0 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 514;
  color: #9c5b57;
}
/* Product */
.product-image-slider .product-image-item .item-image img {
  cursor: zoom-in;
  display: block;
  width: 100%;
}
.product-image-slider .slick-dots {
  padding-bottom: 20px;
  padding-top: 20px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
}
.product-image-slider .slick-dots li {
  cursor: pointer;
  display: block;
  height: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 10px;
}
.product-image-slider .slick-dots li.slick-active button:before {
  background-color: #999999;
}
.product-image-slider .slick-dots li:not(:last-of-type) {
  margin-bottom: 15px;
}
.product-image-slider .slick-dots li button:before {
  border: 1px solid #999999;
  border-radius: 50%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transition: all 0.4s ease-in-out;
}
.product-image-slider .slick-dots li button:hover:before {
  background-color: #999999;
}
.product-option > div {
  margin-left: -15px;
  margin-right: -15px;
}
.product-option > div > div {
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  position: relative;
}
.product-option > div > div:after {
  clear: both;
}
.product-option > div > div:before,
.product-option > div > div:after {
  content: " ";
  display: table;
}
.product-option > div > div:after {
  clear: both;
}
.product-option > div > div:before,
.product-option > div > div:after {
  content: " ";
  display: table;
}
.product-option > div:nth-of-type(1) {
  margin-bottom: 25px;
}
.product-option > div:nth-of-type(1) .colour {
  width: 40%;
  max-width: 200px;
}
.product-option > div:nth-of-type(1) .colour > div {
  float: left;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(1) {
  width: 40%;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(1) p {
  margin-bottom: 0;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) {
  width: 60%;
  text-align: center;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label {
  cursor: pointer;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label > span i.radio-button {
  border: 1px solid #555555;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label > span i.radio-button:after {
  transition: all 0.4s ease-in-out;
  position: absolute;
  bottom: -5px;
  left: -1px;
  content: "";
  width: 10px;
  height: 1px;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label.color-option-1 > span i.radio-button {
  border-color: #9c5b57;
  background-color: #9c5b57;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label.color-option-2 > span i.radio-button {
  border-color: #111;
  background-color: #111;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label input[type="radio"] {
  display: none;
}
.product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label input[type="radio"]:checked + span i.radio-button:after {
  background-color: #999999;
}
.product-option > div:nth-of-type(1) .size {
  width: 60%;
}
.product-option > div:nth-of-type(1) .size > div {
  float: left;
}
.product-option > div:nth-of-type(1) .size > div:nth-of-type(1) {
  margin-right: 15px;
}
.product-option > div:nth-of-type(1) .size > div:nth-of-type(1) p {
  margin-bottom: 0;
}
.product-option > div:nth-of-type(1) .size > div:nth-of-type(2) select {
  border: 0;
  font-weight: 700;
}
.product-option > div:nth-of-type(2) .quantity-wrapper {
  width: 40%;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 210px;
}
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity {
  padding: 6px 15px;
  width: 100%;
  border: 1px solid #dddddd;
}
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity:after {
  clear: both;
}
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity:before,
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity:after {
  content: " ";
  display: table;
}
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity:after {
  clear: both;
}
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity:before,
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity:after {
  content: " ";
  display: table;
}
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity p {
  width: 40%;
  margin-bottom: 0;
  float: left;
  line-height: 30px;
}
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity input[type='number']::-webkit-inner-spin-button,
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
.product-option > div:nth-of-type(2) .quantity-wrapper .quantity input[type='number'] {
  display: inline-block;
  padding: 0;
  width: 60%;
  float: left;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-size: 14px;
  line-height: 2.2;
  border: 0;
}
.product-option > div:nth-of-type(2) .cart-button {
  width: 60%;
}
.product-option > div:nth-of-type(2) .cart-button a.normal-btn {
  width: 100%;
  max-width: 310px;
  padding: 0 15px;
  text-align: center;
  letter-spacing: 3px;
  line-height: 44px;
  font-size: 11px;
}
.product-option > div:nth-of-type(2) .cart-button a.normal-btn i {
  font-size: 18px;
  line-height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.product-option > div:nth-of-type(2) .cart-button a.normal-btn i:before {
  display: inline-block;
}
@media only screen and (min-width: 992px ) {
  .product-option > div:nth-of-type(2) .quantity-wrapper {
    left: 60%;
  }
  .product-option > div:nth-of-type(2) .cart-button {
    right: 40%;
  }
}
@media (max-width: 991px ) {
  .product-option > div:nth-of-type(1) {
    margin-bottom: 25px;
  }
  .product-option > div:nth-of-type(1) .colour {
    width: 60%;
  }
  .product-option > div:nth-of-type(1) .colour > div {
    float: left;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(1) {
    width: 40%;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(1) p {
    margin-bottom: 0;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) {
    width: 60%;
    text-align: center;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label {
    cursor: pointer;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label > span i.radio-button {
    border: 1px solid #555555;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label > span i.radio-button:after {
    transition: all 0.4s ease-in-out;
    position: absolute;
    bottom: -5px;
    left: -1px;
    content: "";
    width: 10px;
    height: 1px;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label.color-option-1 > span i.radio-button {
    border-color: #9c5b57;
    background-color: #9c5b57;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label.color-option-2 > span i.radio-button {
    border-color: #111;
    background-color: #111;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label input[type="radio"] {
    display: none;
  }
  .product-option > div:nth-of-type(1) .colour > div:nth-of-type(2) label input[type="radio"]:checked + span i.radio-button:after {
    background-color: #999999;
  }
  .product-option > div:nth-of-type(1) .size {
    width: 40%;
  }
  .product-option > div:nth-of-type(1) .size > div {
    float: left;
  }
  .product-option > div:nth-of-type(1) .size > div:nth-of-type(1) {
    margin-right: 15px;
  }
  .product-option > div:nth-of-type(1) .size > div:nth-of-type(1) p {
    margin-bottom: 0;
  }
  .product-option > div:nth-of-type(1) .size > div:nth-of-type(2) select {
    border: 0;
    font-weight: 700;
  }
  .product-option > div:nth-of-type(2) .quantity-wrapper {
    width: 100%;
    margin-bottom: 30px;
  }
  .product-option > div:nth-of-type(2) .cart-button {
    width: 100%;
  }
}
.product-detail-1 h3 {
  font-size: 24px;
  margin-bottom: 12px;
}
.product-detail-1 h1 {
  font-family: 'Playfair Display', serif;
  margin-bottom: 10px;
}
.product-detail-1 .product-option {
  margin-bottom: 30px;
}
/* Shop detail */
.shop-detail-orther-link {
  text-align: center;
}
.shop-detail-orther-link br {
  display: none;
}
.shop-detail-orther-link a.border-btn,
.shop-detail-orther-link a.normal-btn {
  width: 100%;
  max-width: 270px;
  padding-left: 15px;
  padding-right: 15px;
}
.shop-detail-orther-link a.border-btn {
  margin-right: 30px;
}
@media (max-width: 767px ) {
  .shop-detail-orther-link br {
    display: initial;
  }
  .shop-detail-orther-link a.border-btn {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}
.shop-detail-1-page .product-image-slider-wrapper {
  padding-top: 85px;
}
.shop-detail-1-page .product-detail-1 {
  padding-top: 85px;
}
.shop-detail-1-page .shop-detail-orther-link {
  padding-top: 75px;
  padding-bottom: 75px;
}
.shop-detail-1-page .shop-detail-relate {
  padding-top: 40px;
}
.shop-detail-1-page .shop-detail-relate h5 {
  margin-bottom: 55px;
}
/* Shop category */
.category-sorting {
  padding: 10px 0;
}
.category-sorting > p {
  float: left;
  margin-bottom: 0;
  line-height: 44px;
  margin-right: 30px;
}
.category-sorting select {
  float: left;
  font-weight: 600;
  height: 44px;
  border: 1px solid #ddd;
  max-width: 100%;
  padding: 0 15px;
}
.shop-category-side-bar .category-product-wrapper {
  margin-bottom: 50px;
}
.shop-category-side-bar .category-product-wrapper h5 {
  margin-bottom: 40px;
}
.shop-category-side-bar .category-product-wrapper .widget.category-product {
  margin-bottom: 0;
}
.shop-category-side-bar .price-filter {
  margin-bottom: 75px;
}
.shop-category-page .pagination-2 {
  padding-top: 25px;
  padding-bottom: 105px;
}
.shop-category-caro-page .pagination-2 {
  padding-top: 70px;
  padding-bottom: 65px;
}
/* Shop Cart*/
.shop-cart-list {
  width: 100%;
  margin-bottom: 60px;
}
.shop-cart-list table * {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-size: 14px;
  color: #333333;
}
.shop-cart-list table thead th {
  border-bottom: 2px solid #9c5b57;
  padding-top: 15px;
  padding-bottom: 15px;
}
.shop-cart-list table tbody .cart-item {
  border-bottom: 1px solid #DDD;
}
.shop-cart-list table tbody .cart-item td.product-img {
  padding-top: 25px;
  padding-bottom: 25px;
}
.shop-cart-list table tbody .cart-item td.product-img > a {
  display: inline-block;
}
.shop-cart-list table tbody .cart-item td.product-img .image {
  position: relative;
}
.shop-cart-list table tbody .cart-item td.product-img .image:after {
  background-color: rgba(51, 51, 51, 0.5);
  content: "";
  display: block;
  position: absolute;
  z-index: 512;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.shop-cart-list table tbody .cart-item td.product-img .image:after {
  opacity: 0;
}
.shop-cart-list table tbody .cart-item td.product-img .image:hover:after {
  opacity: 1;
}
.shop-cart-list table tbody .cart-item td.product-img .image img {
  width: 100%;
}
.shop-cart-list table tbody .cart-item td.product-remove > a:before {
  content: "\e261";
  font-family: "wolverine";
}
.shop-cart-list table tbody .cart-item td.product-remove > a:hover {
  color: #9c5b57;
}
.shop-cart-list table tbody .cart-item td.product-quantity input[type='number']::-webkit-inner-spin-button,
.shop-cart-list table tbody .cart-item td.product-quantity input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
.shop-cart-list table tbody .cart-item td.product-quantity input[type='number'] {
  width: 60px;
  padding: 0;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-size: 14px;
  line-height: 2.2;
  border: 0;
}
.shop-cart-list table .product-img {
  text-align: left;
  padding-right: 5px;
}
.shop-cart-list table .product-name {
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.shop-cart-list table .product-size {
  text-align: center;
  width: 50px;
}
.shop-cart-list table .product-price {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
.shop-cart-list table .product-quantity {
  text-align: center;
  width: 100px;
}
.shop-cart-list table .product-total {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
.shop-cart-list table .product-remove {
  text-align: center;
  width: 30px;
}
@media only screen and (min-width: 992px ) {
  .shop-cart-list table {
    width: 100%;
  }
  .shop-cart-list table tbody .cart-item td.product-img .image {
    width: 100px;
  }
  .shop-cart-list .product-img {
    width: 150px;
  }
  .shop-cart-list .product-price {
    width: 150px;
  }
  .shop-cart-list .product-total {
    width: 150px;
  }
}
@media only screen and (min-width: 1230px ) {
  .shop-cart-list table tbody .cart-item td.product-img .image {
    width: 120px;
  }
  .shop-cart-list .product-img {
    width: 190px;
  }
}
@media (max-width: 991px ) {
  .shop-cart-list {
    overflow-x: auto;
  }
  .shop-cart-list table {
    width: 720px;
  }
  .shop-cart-list table tbody .cart-item td.product-img .image {
    width: 80px;
  }
  .shop-cart-list table .product-img {
    width: 100px;
  }
  .shop-cart-list table .product-price {
    width: 120px;
  }
  .shop-cart-list table .product-total {
    width: 120px;
  }
}
.shop-cart-coupon {
  margin-bottom: 40px;
}
.shop-cart-coupon h5 {
  margin-bottom: 20px;
}
.shop-cart-coupon p {
  margin-bottom: 20px;
}
.shop-cart-coupon br {
  display: none;
}
.shop-cart-coupon input {
  max-width: 270px;
  width: 100%;
  margin-right: 30px;
}
@media (max-width: 550px) {
  .shop-cart-coupon input {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .shop-cart-coupon br {
    display: initial;
  }
}
.shop-cart-total span.price {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  text-align: right;
}
.shop-cart-total h3.title {
  margin-bottom: 40px;
  font-size: 24px;
  text-align: center;
}
.shop-cart-total .sub-total {
  margin-bottom: 20px;
}
.shop-cart-total .sub-total:after {
  clear: both;
}
.shop-cart-total .sub-total:before,
.shop-cart-total .sub-total:after {
  content: " ";
  display: table;
}
.shop-cart-total .sub-total:after {
  clear: both;
}
.shop-cart-total .sub-total:before,
.shop-cart-total .sub-total:after {
  content: " ";
  display: table;
}
.shop-cart-total .sub-total * {
  line-height: 30px;
}
.shop-cart-total .sub-total h5 {
  display: block;
  float: left;
  margin-bottom: 0;
  width: 40%;
}
.shop-cart-total .sub-total span.price {
  display: block;
  float: left;
  width: 60%;
  font-size: 14px;
}
.shop-cart-total .shipping {
  border-bottom: 1px solid #DDD;
  padding-bottom: 18px;
}
.shop-cart-total .shipping:after {
  clear: both;
}
.shop-cart-total .shipping:before,
.shop-cart-total .shipping:after {
  content: " ";
  display: table;
}
.shop-cart-total .shipping:after {
  clear: both;
}
.shop-cart-total .shipping:before,
.shop-cart-total .shipping:after {
  content: " ";
  display: table;
}
.shop-cart-total .shipping * {
  line-height: 30px;
}
.shop-cart-total .shipping span.price {
  font-size: 14px;
}
.shop-cart-total .shipping h5 {
  margin-bottom: 0;
}
.shop-cart-total .shipping .content label {
  cursor: pointer;
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.shop-cart-total .shipping .content label input[type="radio"] {
  display: none;
}
.shop-cart-total .shipping .content label input[type="radio"] + span i.radio-button {
  transition: all 0.4s ease-in-out;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #999999;
}
.shop-cart-total .shipping .content label input[type="radio"]:checked + span i.radio-button {
  border: 3px solid #9c5b57;
}
.shop-cart-total .shipping .content label > span {
  display: block;
  position: relative;
  padding-left: 20px;
}
.shop-cart-total .shipping .content label > span:after {
  clear: both;
}
.shop-cart-total .shipping .content label > span:before,
.shop-cart-total .shipping .content label > span:after {
  content: " ";
  display: table;
}
.shop-cart-total .shipping .content label > span:after {
  clear: both;
}
.shop-cart-total .shipping .content label > span:before,
.shop-cart-total .shipping .content label > span:after {
  content: " ";
  display: table;
}
.shop-cart-total .shipping .content label > span i.radio-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.shop-cart-total .shipping .content label > span span {
  display: block;
  float: left;
}
.shop-cart-total .shipping .content label > span span.name {
  width: 60%;
}
.shop-cart-total .shipping .content label > span span.price {
  width: 40%;
}
.shop-cart-total .shipping .content > p {
  margin-bottom: 0;
}
.shop-cart-total .total {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.shop-cart-total .total:after {
  clear: both;
}
.shop-cart-total .total:before,
.shop-cart-total .total:after {
  content: " ";
  display: table;
}
.shop-cart-total .total:after {
  clear: both;
}
.shop-cart-total .total:before,
.shop-cart-total .total:after {
  content: " ";
  display: table;
}
.shop-cart-total .total * {
  line-height: 46px;
}
.shop-cart-total .total h4 {
  display: block;
  float: left;
  margin-bottom: 0;
  width: 40%;
}
.shop-cart-total .total span.price {
  display: block;
  float: left;
  width: 60%;
  font-size: 21px;
}
.shop-cart-total .button-wrapper {
  text-align: center;
}
.shop-cart-total .button-wrapper a.border-btn {
  letter-spacing: 3px;
  font-size: 11px;
}
@media only screen and (min-width: 768px ) {
  .shop-cart-total .shipping h5 {
    float: left;
    display: block;
    width: 20%;
  }
  .shop-cart-total .shipping .content {
    float: left;
    width: 80%;
  }
}
@media only screen and (min-width: 992px ) {
  .shop-cart-total h3.title {
    text-align: right;
  }
  .shop-cart-total .shipping h5 {
    width: 25%;
  }
  .shop-cart-total .shipping .content {
    width: 75%;
  }
  .shop-cart-total .button-wrapper {
    text-align: right;
  }
}
@media (max-width: 767px ) {
  .shop-cart-total .button-wrapper a.border-btn {
    padding-left: 50px;
    padding-right: 50px;
  }
}
form.cart-page-form {
  padding-top: 55px;
  padding-bottom: 95px;
}
form.cart-page-form .list-button {
  padding-top: 10px;
  margin-bottom: 60px;
}
form.cart-page-form .list-button a.normal-btn,
form.cart-page-form .list-button a.border-btn {
  font-size: 11px;
  letter-spacing: 3px;
}
form.cart-page-form .list-button a.normal-btn {
  margin-right: 30px;
}
form.cart-page-form .list-button br {
  display: none;
}
@media (max-width: 767px ) {
  form.cart-page-form .list-button {
    text-align: center;
  }
  form.cart-page-form .list-button br {
    display: initial;
  }
  form.cart-page-form .list-button a.normal-btn,
  form.cart-page-form .list-button a.border-btn {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    max-width: 300px;
  }
  form.cart-page-form .list-button a.normal-btn {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}
/* Check Out */
.checkout-page-form {
  padding-top: 55px;
}
.checkout-account > h5 {
  margin-bottom: 5px;
}
.checkout-account p {
  font-weight: 600;
  color: #333333;
  font-size: 14px;
}
.checkout-account .log-in {
  margin-bottom: 15px;
}
.checkout-account .log-in > a {
  color: #9c5b57;
  font-weight: 600;
  margin-left: 10px;
}
.checkout-account .log-in > a:after {
  font-family: "wolverine";
  font-weight: 400;
  content: "\e07f";
  vertical-align: middle;
  font-size: 20px;
  margin-left: 30px;
}
.checkout-account .log-in > a:hover {
  color: #999999;
}
.checkout-account .sign-up p {
  margin-bottom: 10px;
}
.checkout-account .sign-up .input-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.checkout-account .sign-up .input-wrapper:after {
  clear: both;
}
.checkout-account .sign-up .input-wrapper:before,
.checkout-account .sign-up .input-wrapper:after {
  content: " ";
  display: table;
}
.checkout-account .sign-up .input-wrapper:after {
  clear: both;
}
.checkout-account .sign-up .input-wrapper:before,
.checkout-account .sign-up .input-wrapper:after {
  content: " ";
  display: table;
}
.checkout-account .sign-up .input-wrapper > div {
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
  float: left;
  margin-bottom: 25px;
}
.checkout-account .sign-up .input-wrapper input {
  width: 100%;
}
.checkout-account .question {
  margin-bottom: 45px;
}
.checkout-account .question p {
  margin-bottom: 5px;
}
.checkout-account .question label {
  cursor: pointer;
}
.checkout-account .question label input[type="radio"] {
  display: none;
}
.checkout-account .question label input[type="radio"] + span i.radio-button {
  transition: all 0.4s ease-in-out;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #999999;
}
.checkout-account .question label input[type="radio"]:checked + span i.radio-button {
  border: 3px solid #9c5b57;
}
.checkout-account .question label > span span {
  margin-right: 10px;
}
@media (max-width: 767px ) {
  .checkout-account .sign-up .input-wrapper > div {
    width: 100%;
    float: left;
  }
}
.checkout-shipping-address {
  padding-top: 40px;
  padding-bottom: 25px;
}
.checkout-shipping-address h5 {
  margin-bottom: 35px;
}
.checkout-shipping-address .content > div {
  margin-bottom: 20px;
}
.checkout-shipping-address .content > div input {
  width: 100%;
}
@media only screen and (min-width: 768px ) {
  .checkout-shipping-address .content > div:after {
    clear: both;
  }
  .checkout-shipping-address .content > div:before,
  .checkout-shipping-address .content > div:after {
    content: " ";
    display: table;
  }
  .checkout-shipping-address .content > div:after {
    clear: both;
  }
  .checkout-shipping-address .content > div:before,
  .checkout-shipping-address .content > div:after {
    content: " ";
    display: table;
  }
  .checkout-shipping-address .content > div > span,
  .checkout-shipping-address .content > div input {
    display: inline-block;
    float: left;
  }
  .checkout-shipping-address .content > div > span {
    width: 30%;
    line-height: 44px;
    padding-right: 15px;
  }
  .checkout-shipping-address .content > div input {
    width: 70%;
  }
}
@media only screen and (min-width: 992px ) {
  .checkout-shipping-address .content > div > span {
    width: 35%;
  }
  .checkout-shipping-address .content > div input {
    width: 65%;
  }
}
@media only screen and (min-width: 1230px ) {
  .checkout-shipping-address .content > div > span {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.checkout-payment {
  padding-bottom: 70px;
}
.checkout-payment:after {
  clear: both;
}
.checkout-payment:before,
.checkout-payment:after {
  content: " ";
  display: table;
}
.checkout-payment:after {
  clear: both;
}
.checkout-payment:before,
.checkout-payment:after {
  content: " ";
  display: table;
}
.checkout-payment label {
  cursor: pointer;
}
.checkout-payment label input[type="radio"] {
  display: none;
}
.checkout-payment label input[type="radio"] + span i.radio-button {
  transition: all 0.4s ease-in-out;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 18px;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 15px;
  border: 1px solid #CCC;
  color: #ffffff;
}
.checkout-payment label input[type="radio"]:checked + span i.radio-button {
  border: 1px solid #9c5b57;
  background-color: #9c5b57;
  text-align: center;
}
.checkout-payment label input[type="radio"]:checked + span i.radio-button:before {
  content: "W";
  font-family: "wolverine";
  font-style: normal;
  font-size: 16px;
  line-height: 1;
}
.checkout-payment label span {
  display: inline-block;
  line-height: 30px;
}
.checkout-payment .payment-credit-card .item-head:after {
  clear: both;
}
.checkout-payment .payment-credit-card .item-head:before,
.checkout-payment .payment-credit-card .item-head:after {
  content: " ";
  display: table;
}
.checkout-payment .payment-credit-card .item-head:after {
  clear: both;
}
.checkout-payment .payment-credit-card .item-head:before,
.checkout-payment .payment-credit-card .item-head:after {
  content: " ";
  display: table;
}
.checkout-payment .payment-credit-card .item-head label {
  float: left;
}
.checkout-payment .payment-credit-card .item-head span.logo {
  float: right;
  line-height: 30px;
  display: inline-block;
}
.checkout-payment .payment-credit-card .item-body {
  background-color: #f4f4f4;
  padding: 20px 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.checkout-payment .payment-credit-card .item-body > div:after {
  clear: both;
}
.checkout-payment .payment-credit-card .item-body > div:before,
.checkout-payment .payment-credit-card .item-body > div:after {
  content: " ";
  display: table;
}
.checkout-payment .payment-credit-card .item-body > div:after {
  clear: both;
}
.checkout-payment .payment-credit-card .item-body > div:before,
.checkout-payment .payment-credit-card .item-body > div:after {
  content: " ";
  display: table;
}
.checkout-payment .payment-credit-card .item-body > div > p {
  display: inline-block;
  float: left;
  width: 25%;
  margin-bottom: 0;
}
.checkout-payment .payment-credit-card .item-body > div > p:after {
  clear: both;
}
.checkout-payment .payment-credit-card .item-body > div > p:before,
.checkout-payment .payment-credit-card .item-body > div > p:after {
  content: " ";
  display: table;
}
.checkout-payment .payment-credit-card .item-body > div > p:after {
  clear: both;
}
.checkout-payment .payment-credit-card .item-body > div > p:before,
.checkout-payment .payment-credit-card .item-body > div > p:after {
  content: " ";
  display: table;
}
.checkout-payment .payment-credit-card .item-body > div > p span {
  clear: both;
  float: right;
}
.checkout-payment .payment-credit-card .item-body > div h5 {
  margin-bottom: 0;
  float: left;
  width: 75%;
  padding-left: 120px;
}
.checkout-payment .payment-credit-card .item-body > div:not(:last-of-type) {
  margin-bottom: 20px;
}
.checkout-payment .payment-paypal {
  margin-bottom: 65px;
}
.checkout-payment .payment-paypal:after {
  clear: both;
}
.checkout-payment .payment-paypal:before,
.checkout-payment .payment-paypal:after {
  content: " ";
  display: table;
}
.checkout-payment .payment-paypal:after {
  clear: both;
}
.checkout-payment .payment-paypal:before,
.checkout-payment .payment-paypal:after {
  content: " ";
  display: table;
}
.checkout-payment .payment-paypal label {
  float: left;
}
.checkout-payment .payment-paypal span.logo {
  float: right;
  line-height: 30px;
  display: inline-block;
}
.checkout-payment .button-wrapper {
  width: 100%;
}
.checkout-payment .button-wrapper > a.border-btn {
  float: right;
}
@media (max-width: 991px ) {
  .checkout-payment .payment-credit-card .item-body > div > p {
    width: 40%;
  }
  .checkout-payment .payment-credit-card .item-body > div h5 {
    width: 60%;
    padding-left: 0;
    text-align: right;
  }
  .checkout-payment .button-wrapper {
    text-align: center;
  }
  .checkout-payment .button-wrapper > a.border-btn {
    float: none;
  }
}
@media (max-width: 767px ) {
  .checkout-payment .payment-credit-card .item-head .ro-image img {
    width: 100px;
  }
  .checkout-payment .payment-credit-card .item-body {
    padding: 15px;
  }
  .checkout-payment .payment-credit-card .item-body > div > p {
    width: 50%;
  }
  .checkout-payment .payment-credit-card .item-body > div h5 {
    width: 50%;
  }
  .checkout-payment .payment-paypal label span img {
    width: 80px;
  }
  .checkout-payment .payment-paypal .logo img {
    width: 130px;
  }
}
/* Shop Successfully */
.shop-successfully {
  padding-top: 60px;
  padding-bottom: 165px;
}
.shop-successfully h3 {
  font-size: 24px;
  margin-bottom: 30px;
}
.shop-successfully p:not(:last-of-type) {
  margin-bottom: 0;
}
.shop-successfully p:last-of-type {
  margin-bottom: 35px;
}
.shop-successfully p.infor span {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-size: 14px;
  color: #333333;
}
@media (max-width: 767px ) {
  .shop-successfully h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
/* Quote */
.quote-slider-01 .quote-item .item-content {
  padding: 0 15px;
}
.quote-slider-01 .quote-item .item-content .heading-icon-2 {
  transition: all 0.4s ease-in-out;
}
.quote-slider-01 .quote-item .item-content .heading-icon-2:after {
  color: #ffffff;
  content: "\e2d1";
  display: block;
  font-size: 68px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.quote-slider-01 .quote-item .item-content .heading-icon-2:after {
  font-style: normal;
  margin-top: 10px;
}
.quote-slider-01 .quote-item .item-content p {
  font-size: 28px;
  margin-bottom: 0;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
@media (max-width: 767px ) {
  .quote-slider-01 .quote-item .item-content p {
    font-size: 16px;
  }
}
.quote-slider-02 {
  margin-bottom: -15px;
}
.quote-slider-02:after {
  clear: both;
}
.quote-slider-02:before,
.quote-slider-02:after {
  content: " ";
  display: table;
}
.quote-slider-02:after {
  clear: both;
}
.quote-slider-02:before,
.quote-slider-02:after {
  content: " ";
  display: table;
}
.quote-slider-02.color-light .quote-item .item-content .name p {
  color: #ffffff;
}
.quote-slider-02.color-light .quote-item .item-content .name p:before,
.quote-slider-02.color-light .quote-item .item-content .name p:after {
  border-color: #ffffff;
}
.quote-slider-02.color-light .quote-item .item-icon i {
  border-color: #ffffff;
}
.quote-slider-02.color-light .quote-item .item-icon i:before {
  color: #ffffff;
}
.quote-slider-02 .quote-item .item-content {
  padding-left: 15px;
  padding-right: 15px;
}
.quote-slider-02 .quote-item .item-content .comment {
  font-size: 18px;
}
.quote-slider-02 .quote-item .item-content .name p {
  display: inline-block;
  font-size: 21px;
  color: #9c5b57;
}
.quote-slider-02 .quote-item .item-content .name p:after {
  margin-left: 10px;
}
.quote-slider-02 .quote-item .item-content .name p:before {
  margin-right: 10px;
}
.quote-slider-02 .quote-item .item-content .name p:before,
.quote-slider-02 .quote-item .item-content .name p:after {
  border: 1px solid #9c5b57;
  content: "";
  display: inline-block;
  width: 50px;
}
.quote-slider-02 .quote-item .item-content p {
  margin-bottom: 0;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
.quote-slider-02 .quote-item .item-icon {
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
}
.quote-slider-02 .quote-item .item-icon i {
  border: 4px solid #555555;
  display: block;
  line-height: 142px;
  text-align: center;
  height: 100%;
  width: 100%;
}
.quote-slider-02 .quote-item .item-icon i:before {
  font-size: 94px;
  vertical-align: middle;
  color: #555555;
}
.quote-slider-02 .slick-next {
  right: -85px;
}
.quote-slider-02 .slick-next:before {
  content: "\e07f";
}
.quote-slider-02 .slick-prev {
  left: -85px;
}
.quote-slider-02 .slick-prev:before {
  content: "\e032";
}
.quote-slider-02 .slick-prev,
.quote-slider-02 .slick-next {
  height: 25px;
  margin-top: -12px;
  top: 50%;
  width: 25px;
}
.quote-slider-02 .slick-prev:before,
.quote-slider-02 .slick-next:before {
  font-size: 25px;
  color: #999999;
  font-family: "wolverine";
}
.quote-slider-02 .slick-prev:hover:before,
.quote-slider-02 .slick-next:hover:before {
  color: #333333;
}
@media only screen and (min-width: 992px ) {
  .quote-slider-02 .quote-item .item-content {
    padding-left: 215px;
  }
  .quote-slider-02 .quote-item .item-icon {
    float: left;
    margin-left: 15px;
  }
}
@media (max-width: 1229px ) {
  .quote-slider-02 .slick-next {
    right: 50%;
    transform: translateX(36px);
  }
  .quote-slider-02 .slick-next:before {
    content: "\e07f";
  }
  .quote-slider-02 .slick-prev {
    left: 50%;
    transform: translateX(-36px);
  }
  .quote-slider-02 .slick-prev:before {
    content: "\e032";
  }
  .quote-slider-02 .slick-prev,
  .quote-slider-02 .slick-next {
    bottom: -35px;
    margin-top: 0;
    top: auto;
  }
}
@media (max-width: 991px ) {
  .quote-slider-02 .quote-item .item-content {
    margin-top: 30px;
    text-align: center;
  }
}
@media (max-width: 767px ) {
  .quote-slider-02 {
    margin-bottom: 60px;
    margin-top: 40px;
  }
  .quote-slider-02 .quote-item .item-content .comment {
    font-size: 16px;
  }
  .quote-slider-02 .quote-item .item-content .name {
    text-align: center;
  }
  .quote-slider-02 .quote-item .item-content .name p {
    margin-top: 15px;
    font-size: 16px;
  }
  .quote-slider-02 .quote-item .item-content .name p:before,
  .quote-slider-02 .quote-item .item-content .name p:after {
    width: 25px;
  }
  .quote-slider-02 .quote-item .item-icon {
    height: 120px;
    width: 120px;
  }
  .quote-slider-02 .quote-item .item-icon i {
    line-height: 112px;
  }
  .quote-slider-02 .quote-item .item-icon i:before {
    font-size: 75px;
    vertical-align: middle;
  }
}
.parallax-bg.quote-slider-parallax {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.parallax-bg.quote-slider-parallax:after {
  background-color: rgba(51, 51, 51, 0.7);
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
/* Brand Slider */
.brand-carousel-wrapper {
  background-color: #939e98;
}
.brand-carousel .brand-item .item-image {
  text-align: center;
}
.brand-carousel .brand-item .item-image > a {
  display: inline-block;
  opacity: 1;
}
.brand-carousel .brand-item .item-image > a:hover {
  opacity: 0.7;
}
/* Portfolio Slider */
.portfolio-box-slider:hover .slick-prev,
.portfolio-box-slider:hover .slick-next {
  opacity: 1;
}
.portfolio-box-slider .portfolio-slider-item .item-image img {
  display: block;
  width: 100%;
}
.portfolio-box-slider .slick-next {
  right: 0;
  text-align: right;
}
.portfolio-box-slider .slick-next:before {
  content: '\e2c4';
  margin-right: 10px;
}
.portfolio-box-slider .slick-prev {
  left: 0;
  text-align: left;
}
.portfolio-box-slider .slick-prev:before {
  content: '\e2c5';
  margin-left: 10px;
}
.portfolio-box-slider .slick-prev,
.portfolio-box-slider .slick-next {
  background-color: #555555;
  height: 70px;
  margin-top: -35px;
  opacity: 0;
  top: 50%;
  width: 40px;
  z-index: 10;
  transition: all 0.4s ease-in-out;
}
.portfolio-box-slider .slick-prev:before,
.portfolio-box-slider .slick-next:before {
  font-size: 15px;
  color: #ffffff;
  font-family: "wolverine";
}
.portfolio-box-slider .slick-prev:hover,
.portfolio-box-slider .slick-next:hover {
  background-color: #999999;
}
@media (min-width: 1100px) {
  .portfolio-box-slider:hover .slick-prev,
  .portfolio-box-slider:hover .slick-next {
    transform: translateX(0px);
  }
  .portfolio-box-slider .slick-next {
    transform: translateX(40px);
  }
  .portfolio-box-slider .slick-prev {
    transform: translateX(-40px);
  }
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2-wrapper {
  margin-top: 54px;
  background-color: #f4f4f4;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-1:hover .slick-prev,
.portfolio-thumbnail-slider .portfolio-syn-slider-1:hover .slick-next {
  opacity: 1;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .portfolio-slider-item .item-image img {
  display: block;
  width: 100%;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-next {
  right: 0;
  text-align: right;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-next:before {
  content: '\e2c4';
  margin-right: 10px;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-prev {
  left: 0;
  text-align: left;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-prev:before {
  content: '\e2c5';
  margin-left: 10px;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-prev,
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-next {
  background-color: #555555;
  height: 70px;
  margin-top: -35px;
  opacity: 0;
  top: 50%;
  width: 40px;
  z-index: 10;
  transition: all 0.4s ease-in-out;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-prev:before,
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-next:before {
  font-size: 15px;
  color: #ffffff;
  font-family: "wolverine";
}
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-prev:hover,
.portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-next:hover {
  background-color: #999999;
}
@media (min-width: 1100px) {
  .portfolio-thumbnail-slider .portfolio-syn-slider-1:hover .slick-prev,
  .portfolio-thumbnail-slider .portfolio-syn-slider-1:hover .slick-next {
    transform: translateX(0px);
  }
  .portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-next {
    transform: translateX(40px);
  }
  .portfolio-thumbnail-slider .portfolio-syn-slider-1 .slick-prev {
    transform: translateX(-40px);
  }
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 {
  margin-bottom: 50px;
  margin-top: 50px;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .portfolio-slider-item.slick-center .item-image {
  transform: scale(1.07843137);
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .portfolio-slider-item.slick-center .layout-after:after {
  background-color: transparent;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .portfolio-slider-item .item-image {
  margin: 15px;
  transition: all 0.4s ease-in-out;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .portfolio-slider-item .item-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .portfolio-slider-item .layout-after {
  position: relative;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .portfolio-slider-item .layout-after:after {
  background-color: rgba(51, 51, 51, 0.3);
  content: "";
  display: block;
  position: absolute;
  z-index: 512;
  transition: all 0.4s ease-in-out;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .portfolio-slider-item .layout-after:hover:after {
  background-color: transparent;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-next {
  right: -30px;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-next:before {
  content: '\e07e';
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-prev {
  left: -30px;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-prev:before {
  content: '\e031';
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-prev,
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-next {
  height: 25px;
  top: 85px;
  width: 25px;
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-prev:before,
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-next:before {
  font-size: 25px;
  color: #999999;
  font-family: "wolverine";
}
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-prev:hover:before,
.portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-next:hover:before {
  color: #333333;
}
@media (max-width: 1300px) {
  .portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-next {
    right: 50%;
    transform: translateX(36px);
  }
  .portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-next:before {
    content: '\e07f';
  }
  .portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-prev {
    left: 50%;
    transform: translateX(-36px);
  }
  .portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-prev:before {
    content: '\e032';
  }
  .portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-prev,
  .portfolio-thumbnail-slider .portfolio-syn-slider-2 .slick-next {
    bottom: -35px;
    margin-top: 0;
    top: auto;
  }
}
@media (max-width: 767px ) {
  .portfolio-thumbnail-slider .portfolio-syn-slider-2 {
    display: none;
  }
}
/* Highlights */
.highlights span {
  background-color: #9c5b57;
  color: #ffffff;
  padding: 4px;
}
.highlights.style-2 span {
  background-color: #cccccc;
  color: #333333;
}
/* Message Box */
.message p {
  position: relative;
  padding: 5px 20px 5px 50px;
  font-weight: 600;
  margin-bottom: 0;
}
.message p:before {
  font-family: "wolverine";
  position: absolute;
  top: 50%;
  left: 0px;
  display: inline-block;
  width: 50px;
  text-align: center;
  font-size: 19px;
  transform: translateY(-50%);
}
.message p a {
  position: absolute;
  top: 50%;
  right: 12px;
  font-weight: 800;
  font-size: 17px;
  transform: translateY(-50%);
}
.message.notice p {
  border: 1px solid #eeeeee;
}
.message.notice p:before {
  content: "\e048";
}
.message.error p {
  color: #ffffff;
  background-color: #9c5b57;
}
.message.error p:before {
  font-family: FontAwesome;
  content: "\f06a";
}
.message.warning p {
  color: #9c5b57;
  border: 1px solid #cccccc;
  background-color: #eeeeee;
}
.message.warning p:before {
  content: "\i";
}
.message.success p {
  border: 1px solid #cccccc;
  background-color: #eeeeee;
}
.message.success p:before {
  content: "\W";
}
.message.info p {
  color: #9c5b57;
  border: 1px solid #9c5b57;
}
.message.info p:before {
  content: "\e020";
}
.messagefull .content {
  position: relative;
  padding: 20px 25px 20px 108px;
  font-weight: 600;
}
.messagefull .content p,
.messagefull .content h5,
.messagefull .content a,
.messagefull .content:before {
  margin-bottom: 0;
}
.messagefull .content a {
  position: absolute;
  top: 0px;
  right: 15px;
  font-weight: 800;
  font-size: 17px;
}
.messagefull .content:before {
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  left: 0px;
  display: inline-block;
  width: 108px;
  content: "\f06a";
  text-align: center;
  font-size: 35px;
  transform: translateY(-50%);
}
.messagefull.error .content {
  background-color: #9c5b57;
}
.messagefull.error .content p,
.messagefull.error .content h5,
.messagefull.error .content a,
.messagefull.error .content:before {
  color: #ffffff;
}
.messagefull.error .content:before {
  content: "\f06a";
}
.messagefull.warning .content {
  border: 1px solid #cccccc;
  background-color: #eeeeee;
}
.messagefull.warning .content p,
.messagefull.warning .content h5,
.messagefull.warning .content a,
.messagefull.warning .content:before {
  color: #9c5b57;
}
.messagefull.warning .content:before {
  font-family: "wolverine";
  content: "\i";
}
.messagefull.success .content {
  border: 1px solid #cccccc;
  background-color: #eeeeee;
}
.messagefull.success .content:before {
  font-family: "wolverine";
  content: "\W";
}
.messagefull.info .content {
  border: 1px solid #9c5b57;
}
.messagefull.info .content p,
.messagefull.info .content h5,
.messagefull.info .content a,
.messagefull.info .content:before {
  color: #9c5b57;
}
.messagefull.info .content:before {
  font-family: "wolverine";
  content: "\e020";
}
/* Pricing Table */
.price-table {
  padding-left: 1px;
  text-align: center;
  transition: all .3s;
}
.price-table:before,
.price-table:after {
  display: table;
  content: " ";
  transition: all .3s;
}
.price-table:after {
  clear: both;
  transition: all .3s;
}
.price-table-content {
  margin-left: -1px;
  padding: 0 25px;
  padding-bottom: 60px;
  border: 1px solid #dddddd;
  transition: all .3s;
}
.price-table-content.default {
  background-color: #f4f4f4;
}
.price-table-content.default h5:first-of-type {
  background-color: #9c5b57;
}
.price-table-content h5 {
  margin-bottom: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #dddddd;
  color: #666666;
  transition: all .3s;
}
.price-table-content h5:first-of-type {
  background-color: #333333;
  color: #ffffff;
  margin: -1px -26px 0 -26px;
  padding: 32px 0px;
}
.price-table-content h1 {
  margin-bottom: 0;
  padding: 60px 0;
  font-size: 80px;
  transition: all .3s;
}
.price-table-content span {
  color: #444444;
}
/* Block Quotes */
.blockquotes-wrapper {
  margin: 90px 0;
}
.blockquotes-wrapper p,
.blockquotes-wrapper h4 {
  margin-bottom: 0;
}
.blockquotes-wrapper .blockquotes {
  padding: 40px 0 40px 35px;
}
.blockquotes-wrapper .blockquotes h4 {
  font-style: italic;
  font-family: 'Playfair Display', serif;
  display: inline-block;
  line-height: 40px;
}
.blockquotes-wrapper .blockquotes span {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  color: #9c5b57;
  display: inline-block;
  float: left;
  margin-top: 10px;
  font-size: 70px;
  line-height: 40px;
}
.blockquotes-wrapper .blockquotes.style-2 span {
  color: #444444;
}
/* Dropcaps*/
.dropcap-1 span.first-letter {
  border: 1px solid;
  font-size: 38px;
  display: inline-block;
  width: 56px;
  height: 56px;
  float: left;
  font-weight: 700;
  line-height: 56px;
  margin-right: 15px;
  text-align: center;
}
.dropcap-1.style-1 span.first-letter {
  background-color: #9c5b57;
  color: #ffffff;
  border-color: #9c5b57;
}
.dropcap-1.style-2 span.first-letter {
  color: #ffffff;
  border-color: #555555;
  background-color: #555555;
}
.dropcap-1.style-3 span.first-letter {
  border-color: #777777;
  color: #777777;
}
.dropcap-2 span.first-letter {
  border: 1px solid;
  font-size: 38px;
  display: inline-block;
  width: 56px;
  height: 56px;
  float: left;
  font-weight: 700;
  line-height: 56px;
  margin-right: 15px;
  text-align: center;
  border-radius: 50%;
}
.dropcap-2.style-1 span.first-letter {
  background-color: #9c5b57;
  color: #ffffff;
  border-color: #9c5b57;
}
.dropcap-2.style-2 span.first-letter {
  color: #ffffff;
  border-color: #555555;
  background-color: #555555;
}
.dropcap-2.style-3 span.first-letter {
  border-color: #777777;
  color: #777777;
}
.dropcap-3 span.first-letter {
  float: left;
  font-size: 55px;
  line-height: 55px;
  font-weight: 700;
  margin-right: 15px;
}
.dropcap-3.style-1 span.first-letter {
  color: #9c5b57;
}
.dropcap-3.style-2 span.first-letter {
  color: #555555;
}
.dropcap-3.style-3 span.first-letter {
  color: #777777;
}
/* Progressbar*/
.progressbar-container {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.progressbar-container .progressbar-title {
  margin-bottom: 0;
  line-height: 2.2;
}
.progressbar-container .progressbar {
  transition: all 0.4s ease-in-out;
  background-color: #9c5b57;
  width: 0;
}
.progressbar-container .progressbar-back-text,
.progressbar-container .progressbar-front-text {
  display: none;
}
.progressbar-container.basic,
.progressbar-container.style-tooltip {
  position: relative;
}
.progressbar-container.basic .progressbar,
.progressbar-container.style-tooltip .progressbar {
  background-color: #9c5b57;
  height: 20px;
  width: 0;
}
.progressbar-container.basic .progressbar:before,
.progressbar-container.style-tooltip .progressbar:before {
  content: '';
  bottom: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 20px;
  background-color: #eeeeee;
  z-index: -1;
}
.progressbar-container.basic .progressbar {
  position: static;
}
.progressbar-container.basic .progressbar-back-text {
  position: absolute;
  display: block;
  right: 0;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}
.progressbar-container.style-tooltip {
  margin-bottom: 40px;
  position: relative;
}
.progressbar-container.style-tooltip .progressbar {
  position: absolute;
}
.progressbar-container.style-tooltip .progressbar-back-text {
  position: absolute;
  display: block;
  width: 100%;
  height: 20px;
  bottom: -20px;
  font-size: 0;
  background-color: #eeeeee;
  z-index: -1;
}
.progressbar-container.style-tooltip .progressbar-front-text {
  position: absolute;
  display: block;
  right: -25px;
  bottom: 28px;
  line-height: 1;
  height: 23px;
  font-weight: 600;
  text-align: center;
  max-width: 50px;
  padding: 5px;
  border-radius: 3px;
  color: #ffffff;
  background-color: #555555;
}
.progressbar-container.style-tooltip .progressbar-front-text:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 20px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-width: 7px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #555555;
  display: block;
  width: 0;
  height: 0;
}
.progressbar-container.fill-text {
  height: 40px;
  margin-bottom: 35px;
  background-color: #eeeeee;
}
.progressbar-container.fill-text .progressbar-title {
  position: absolute;
  line-height: 40px;
  padding-left: 15px;
  top: 0;
  left: 0;
}
.progressbar-container.fill-text .progressbar {
  position: absolute;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.progressbar-container.fill-text .progressbar:before {
  height: 100%;
}
.progressbar-container.fill-text .progressbar .progressbar-title {
  color: #ffffff;
}
.progressbar-container.fill-text .progressbar-back-text,
.progressbar-container.fill-text .progressbar-front-text {
  position: absolute;
  display: block;
  right: 0;
  font-size: 14px;
  line-height: 40px;
  padding-right: 15px;
  font-weight: 600;
  text-align: right;
}
.progressbar-container.fill-text .progressbar-front-text {
  left: 0;
  top: 0;
  color: #ffffff;
}
.progressbar-container.vertical {
  height: 120px;
  float: left;
  margin-right: 50px;
  margin-bottom: 80px;
  background-color: #ffffff;
}
.progressbar-container.vertical.gray {
  background-color: #f4f4f4;
}
.progressbar-container.vertical .progressbar-title {
  display: block;
  position: absolute;
  bottom: -40px;
}
.progressbar-container.vertical .progressbar {
  height: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.progressbar-container.vertical .progressbar-back-text {
  display: block;
  position: absolute;
  max-height: 20px;
  bottom: -50px;
  line-height: 30px !important;
  font-weight: 600;
  color: #9c5b57;
}
/* Piechart*/
.block-piechart {
  text-align: center;
  font-size: 14px;
}
.block-piechart .piechart {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35px;
}
.block-piechart .piechart .progressbar-text {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.05em;
  font-size: 30px;
}
.block-piechart .piechart path:first-child {
  stroke: #f4f4f4;
}
.block-piechart .piechart path:last-child {
  stroke: #9c5b57;
}
.block-piechart.trail-light .piechart path:first-child {
  stroke: #ffffff;
}
.block-piechart.stroke-dark .piechart path:last-child {
  stroke: #555555;
}
.block-piechart.center-color-main .piechart .icon,
.block-piechart.center-color-main .piechart .progressbar-text {
  color: #9c5b57 !important;
}
.block-piechart .piechart-icon {
  position: relative;
}
.block-piechart .piechart-icon .icon {
  font-size: 52px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.block-piechart .piechart-icon .icon:before {
  display: block;
}
.block-piechart h5 {
  margin-bottom: 0;
}
/* Shortcode Button */
@media (max-width: 480px) {
  .shortcode-button .normal-btn.btn-size-1 {
    padding: 14px 88px;
  }
  .shortcode-button .normal-btn.btn-size-2 {
    padding: 14px 75px;
  }
  .shortcode-button .normal-btn.btn-size-3 {
    padding: 14px 65px;
  }
  .shortcode-button .border-btn.btn-size-1 {
    padding: 13px 87px;
  }
  .shortcode-button .border-btn.btn-size-2 {
    padding: 13px 74px;
  }
  .shortcode-button .border-btn.btn-size-3 {
    padding: 13px 64px;
  }
}
/* Shortcode Parallax */
.shortcode-parallax-1 {
  padding-top: 210px;
  padding-bottom: 210px;
}
.shortcode-parallax-1 .content img {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  margin-bottom: 38px;
}
.shortcode-parallax-1 .content p {
  font-size: 21px;
  margin-bottom: 0;
  color: #ffffff;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
@media (max-width: 767px ) {
  .shortcode-parallax-1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .shortcode-parallax-1 .content img {
    width: 250px;
  }
  .shortcode-parallax-1 .content p {
    font-size: 16px;
  }
}
.shortcode-parallax-2 {
  padding-top: 210px;
  padding-bottom: 210px;
}
.shortcode-parallax-2 .content .heading-icon-2 {
  transition: all 0.4s ease-in-out;
}
.shortcode-parallax-2 .content .heading-icon-2:after {
  color: #ffffff;
  content: "\e2d1";
  display: block;
  font-size: 68px;
  font-weight: 400;
  line-height: 1;
  transition: all 0.4s ease-in-out;
  font-family: "wolverine";
}
.shortcode-parallax-2 .content .heading-icon-2:after {
  font-style: normal;
  margin-top: 10px;
}
.shortcode-parallax-2 .content p {
  font-size: 28px;
  margin-bottom: 0;
  font-style: italic;
  font-family: 'Playfair Display', serif;
}
@media (max-width: 767px ) {
  .shortcode-parallax-2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .shortcode-parallax-2 .content p {
    font-size: 16px;
  }
}
/* Index Demo Page */
.index-header,
.index-footer {
  text-align: center;
  position: relative;
}
.index-header h1,
.index-footer h1,
.index-header h2,
.index-footer h2,
.index-header p,
.index-footer p,
.index-header .icon,
.index-footer .icon {
  color: #ffffff;
  margin-bottom: 10px;
}
.index-header h1,
.index-footer h1,
.index-header h2,
.index-footer h2 {
  font-weight: 700;
}
.index-header .footer-foot p,
.index-footer .footer-foot p {
  color: #333333;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px ) {
  .index-header h1,
  .index-footer h1 {
    font-size: 60px;
  }
}
.index-header {
  padding-top: 80px;
  padding-bottom: 30px;
}
.index-header .icon {
  font-size: 120px;
  display: block;
}
.index-header .icon:before {
  display: block;
}
@media only screen and (min-width: 992px ) {
  .index-header {
    padding-top: 230px;
    padding-bottom: 180px;
  }
}
.index-footer .page-common-background {
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: repeat-x;
  background-size: cover;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.index-footer .page-common-background:after {
  background-color: rgba(0, 0, 0, 0.45);
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.index-footer .footer-content {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media only screen and (min-width: 992px ) {
  .index-footer .footer-content {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
.index-footer .footer-content p.font-serif {
  margin-bottom: 60px;
}
.index-demo-item {
  text-align: center;
  max-width: 428px;
  margin-left: auto;
  margin-right: auto;
}
.index-demo-item:after {
  clear: both;
}
.index-demo-item:before,
.index-demo-item:after {
  content: " ";
  display: table;
}
.index-demo-item:after {
  clear: both;
}
.index-demo-item:before,
.index-demo-item:after {
  content: " ";
  display: table;
}
.index-demo-item .image-frame {
  position: relative;
  margin-bottom: 25px;
}
.index-demo-item .image-frame .overlay {
  transition: all 0.4s ease-in-out;
  opacity: 0;
  background-color: rgba(51, 51, 51, 0.5);
}
.index-demo-item .image-frame:hover .overlay {
  opacity: 1;
}
